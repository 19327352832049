import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppProvider from "./Providers/AppProvider";
import Sidebar from "./Component/Sidebar/Sidebar";
import Header from "./Component/Header/Header";
import HomeConfiguration from "./Component/Configuraciones/HomeConfiguration";
import HomeAddContainer from "./Component/AddContainer/HomeAddContainer";
import HomeHome from "./Component/Home/HomeHome";
import HomeAddModule from "./Component/AddModule/HomeAddModule";
import ViewDirectContainer from "./Component/AddContainer/Container/ViewDirectContainer";
import Login from "./auth/Login/Login";
import NotFound from "./Component/NotFound/NotFound";
import OperationsContainers from "./Component/OperationsContainers/WrapperOperationsContainers";
import "./App.css";
import "./Component/stye/styles.css";
import ViewDirectModule from "./Component/AddModule/Module/ViewDirectModule";
import { PrimeReactProvider } from "primereact/api";

function App() {
  const AppBoard = ({ children }) => {
    return (
      <PrimeReactProvider
      // value={{ unstyled: true }}
      >
        <div className="App">
          <div className="container">
            <div className="boxleft">
              <Sidebar />
            </div>
            <div className="boxrigth">
              <Header />
              {children}
            </div>
          </div>
        </div>
      </PrimeReactProvider>
    );
  };

  return (
    <Router>
      <AppProvider>
        <Routes>
          <Route
            path="/"
            element={
              <AppBoard>
                <HomeHome />
              </AppBoard>
            }
          />
          <Route
            path="/configuraciones"
            element={
              <AppBoard>
                <HomeConfiguration />
              </AppBoard>
            }
          />
          <Route
            path="/contenedor/view/:id"
            element={
              <AppBoard>
                <ViewDirectContainer />
              </AppBoard>
            }
          />
          <Route
            path="/contenedores"
            element={
              <AppBoard>
                <HomeAddContainer />
              </AppBoard>
            }
          />
          <Route
            path="/modulos"
            element={
              <AppBoard>
                <HomeAddModule />
              </AppBoard>
            }
          />
          <Route
            path="/modulo/view/:id"
            element={
              <AppBoard>
                <ViewDirectModule />
              </AppBoard>
            }
          />
          <Route
            path="/operations-containers"
            element={
              <AppBoard>
                <OperationsContainers />
              </AppBoard>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} /> {/* sustituir 404 */}
        </Routes>
      </AppProvider>
    </Router>
  );
}

export default App;
