import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  Divider,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AppContext } from "../../../Providers/AppProvider";
import { useContext } from "react";
import { serviContainersApiCXN } from "../../../auth/axios.Container";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import { MuiFileInput } from "mui-file-input";
import { isFloat } from "validator";
import { getUserId } from "../../../auth/security.ServiContainers";

const iniPdfValidInvoice = {
  error: false,
  text: "Añadir pdf asociado a factura (.pdf)",
};

const iniPdfValidDI = {
  error: false,
  text: "Añadir pdf asociado a DI (.pdf)",
};

const iniPhotosValid = {
  error: false,
  text: "Añadir Imágenes asociadas al contenedor(.jpg/.png)",
};
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(window.btoa(reader.result));
    reader.onerror = reject;
  });

// const pdfToBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => {
//       const base64String = reader.result.split(",")[1];
//       resolve(base64String);
//     };
//     reader.onerror = reject;
//   });

const iniData1 = (data) => {
  console.log( 'Data completa', data);
    console.log('RolesApp', data.rolesApp);


  const ret = { obj: data };   

  return {
    rolesApp: ret.obj.rolesApp,
    country: ret.obj.areaCountry.name
  };  
};

const iniData = {
  id: "",
  codeExt: "",
  codeInt: "",
  model: "",
  provider: "",
  place_countries: "",
  profitAsoc: "",
  state: "",
  owner: "",
  weight: 1,
  propertyContainerAsocs: [],
  factoryYear: dayjs().add(1, "d"),
  description: "",
  tacCode: "",
  // createAt: dayjs().add(1, "d"),

  createAt: dayjs(),
  // createAt: dayjs().add(1, "d").format("YYYY-MM"),
  invoice: "",
  di: "",
  priceSaleEsp: 0,
  saleEspAt: dayjs(),
  outputAt: dayjs(),
};

const iniDataValid = {
  codeExt: { error: false, errorText: "" },
  codeInt: { error: false, errorText: "" },
  profitAsoc: { error: false, errorText: "" },
  lastUpdateStateAt: { error: false, errorText: "" },
  transformModule: { error: false, errorText: "" },
  owner: { error: false, errorText: "" },
  weight: { error: false, errorText: "" },
  tacCode: { error: false, errorText: "" },
  invoice: { error: false, errorText: "" },
  di: { error: false, errorText: "" },
};

const adapterListPropertiesCheck = (
  propertyContainerAsocs,
  listProperties = []
) => {
  let data = [];
  propertyContainerAsocs.forEach((element) => {
    if (
      listProperties.some((ele) => {
        return ele.propertyContainer["@id"] === element["@id"];
      })
    ) {
      data.push({ id: element["@id"], checked: true, name: element.name });
    } else {
      data.push({ id: element["@id"], checked: false, name: element.name });
    }
  });
  return data;
};

export default function FormElements({ open, onClose, create, editData }) {
  const [dataSt, setDataSt] = React.useState(iniData);
  const [dataValid, setDataValid] = React.useState(iniDataValid);
  const [loading, setLoading] = React.useState(true);
  const [Model, setModel] = React.useState([]);
  const [userId, setUserId] = React.useState();
  const [userData, setUserData] = React.useState();
  const [place_countries, setPlace_countries] = React.useState([]);
  const [Provider, setProvider] = React.useState([]);
  const [State, setStateContainer] = React.useState([]);
  const [Owner, setOwner] = React.useState([]);
  const [PropertyContainerAsocs, setPropertyContainerAsocs] = React.useState(
    []
  );
  const [pdfInvoice, setPdfInvoice] = React.useState(null);
  const [pdfValidInvoice, setPdfValidInvoice] =
    React.useState(iniPdfValidInvoice);

  const [pdfDI, setPdfDI] = React.useState(null);
  const [pdfValidDI, setPdfValidDI] = React.useState(iniPdfValidDI);
  const [photos, setPhotos] = React.useState(null);
  const [photosValid, setPhotosValid] = React.useState(iniPhotosValid);
  const { showNotificationApp } = useContext(AppContext);

  const handlesaleEspAt = (e) => {
    setDataSt({
      ...dataSt,
      saleEspAt: e.target.value,
    });
  };

  const handleoutputAt = (e) => {
    setDataSt({
      ...dataSt,
      outputAt: e.target.value,
    });
  };

  const handlePdfInvoice = (value) => {
    console.log(value);
    let valid;
    if (!valid) setPdfValidInvoice(iniPdfValidInvoice);
    else setPdfValidInvoice({ ...valid });
    setPdfInvoice(value);
  };
  const handlePhotos = (value) => {
    let valid;
    if (!valid) setPhotosValid(iniPhotosValid);
    else setPhotosValid({ ...valid });
    setPhotos(value);
  };
  const handlePdfDI = (value) => {
    let valid;
    if (!valid) setPdfValidDI(iniPdfValidDI);
    else setPdfValidDI({ ...valid });
    setPdfDI(value);
  };

  const handleClose = () => {
    setDataSt(iniData);
    setDataValid(iniDataValid);
    setLoading(false);
    onClose();
  };
  const handleCodeExt = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        codeExt: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        codeExt: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      codeExt: event.target.value,
    });
  };
  const handleCodeInt = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        codeInt: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        codeInt: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      codeInt: event.target.value,
    });
  };
  const handlePlace_countries = (e) => {
    setDataSt({
      ...dataSt,
      place_countries: e.target.value,
    });
  };
  const handleModel = (e) => {
    setDataSt({
      ...dataSt,
      model: e.target.value,
    });
  };
  const handleWeight = (e) => {
    setDataSt({
      ...dataSt,
      weight: e.target.value,
    });
  };
  const handlepriceSaleEsp = (e) => {
    setDataSt({
      ...dataSt,
      priceSaleEsp: e.target.value,
    });
  };
  const handleState = (e) => {
    setDataSt({
      ...dataSt,
      state: e.target.value
    });
  };
  const handleProvider = (e) => {
    setDataSt({
      ...dataSt,
      provider: e.target.value,
    });
  };
  const handleOwner = (e) => {
    setDataSt({
      ...dataSt,
      owner: e.target.value,
    });
  };
  const handlePropertiesContainers = (event, key) => {
    let newList = [...PropertyContainerAsocs];
    newList[key] = {
      ...newList[key],
      checked: event.target.checked,
    };
    setPropertyContainerAsocs([...newList]);
  };
  const handleinvoice = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        invoice: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        invoice: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      invoice: event.target.value,
    });
  };

  const handledi = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        di: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        di: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      di: event.target.value,
    });
  };

  const getListProperties = () => {
    const tempo = [];
    PropertyContainerAsocs.forEach((ele) => {
      if (ele.checked) {
        tempo.push({
          propertyContainer: ele.id,
        });
      }
    });
    return tempo;
  };

  const handleProfitAsoc = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        profitAsoc: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        profitAsoc: { error: false, errorText: "" },
      });
    }
    if (parseFloat(event.target.value) >= 100) {
      setDataSt({
        ...dataSt,
        profitAsoc: 100,
      });
    } else if (parseFloat(event.target.value) <= 0) {
      setDataSt({
        ...dataSt,
        profitAsoc: 0,
      });
    } else {
      setDataSt({
        ...dataSt,
        profitAsoc: event.target.value,
      });
    }
  };

  const handleDescription = (event) => {
    setDataSt({
      ...dataSt,
      description: event.target.value,
    });
  };

  const handleTacCode = (event) => {
    const tacCode = event.target.value;

    if (tacCode === "") {
      setDataValid({
        ...dataValid,
        tacCode: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        tacCode: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      tacCode: tacCode,
    });
  };

  const handleSubmit = async () => {
    let valid = { ...dataValid };
    let fail = false;
    setLoading(true);

    let invoicePdfIn = "";
    if (pdfInvoice !== null) {
      const file = pdfInvoice;
      await toBase64(file).then((base64) => (invoicePdfIn = base64));
    }
    let diPdfIn = "";
    if (pdfDI !== null) {
      const file = pdfDI;
      await toBase64(file).then((base64) => (diPdfIn = base64));
    }

    let list = [];
    if (photos !== null) {
      await Promise.all(photos.map((file) => toBase64(file))).then(
        (listPhotos) => (list = listPhotos)
      );
    }
    if (dataValid.codeExt.error) fail = true;
    if (dataValid.codeInt.error) fail = true;
    if (dataValid.profitAsoc.error) fail = true;
    if (dataValid.weight.error) fail = true;
    if (dataValid.tacCode.error) fail = true;
    if (dataValid.invoice.error) fail = true;
    if (dataValid.di.error) fail = true;

    if (dataSt.codeInt === "") {
      fail = true;
      valid = {
        ...valid,
        codeInt: { error: true, errorText: "Este campo es obligatorio." },
      };
    } else if (dataSt.email === "") {
      fail = true;
      valid = {
        ...valid,
        codeExt: { error: true, errorText: "Este campo es obligatorio." },
      };
    } else if (dataSt.plainPassword === "") {
      fail = true;
      valid = {
        ...valid,
        profitAsoc: { error: true, errorText: "Este campo es obligatorio" },
      };
    } else if (dataSt.weight === "") {
      fail = true;
      valid = {
        ...valid,
        weight: { error: true, errorText: "Este campo es obligatorio" },
      };
    } else if (dataSt.tacCode === "") {
      fail = true;
      valid = {
        ...valid,
        tacCode: { error: true, errorText: "Este campo es obligatorio" },
      };
    } else if (dataSt.invoice === "") {
      fail = true;
      valid = {
        ...valid,
        invoice: { error: true, errorText: "Este campo es obligatorio" },
      };
    } else if (dataSt.di === "") {
      fail = true;
      valid = {
        ...valid,
        di: { error: true, errorText: "Este campo es obligatorio" },
      };
    }

    if (!fail) {
      if (create) {
        let newData = new Object();
        newData.codeExt = dataSt.codeExt;
        newData.codeInt = dataSt.codeInt;
        newData.typeModeCatch = dataSt.typeModeCatch;
        // newData.createAt = dayjs().year(dataSt.createAt["$y"]);
        newData.createAt = dataSt.createAt;
        newData.placeCountry = dataSt.place_countries;
        newData.model = dataSt.model;
        newData.profitAsoc = parseFloat(dataSt.profitAsoc) / 100;
        newData.provider = dataSt.provider;
        newData.state = dataSt.state;
        newData.owner = dataSt.owner;
        newData.factoryYear = parseInt(dataSt.factoryYear["$y"]);
        newData.propertyContainerAsocs = getListProperties();
        newData.weight = parseFloat(dataSt.weight);
        newData.tacCode = dataSt.tacCode;
        newData.description = dataSt.description;
        newData.invoice = dataSt.invoice;
        newData.di = dataSt.di;
        // newData.invoicePdfIn = invoicePdfIn;
        newData.invoicePdfIn =
          invoicePdfIn === null || invoicePdfIn === "" ? null : invoicePdfIn;
        // newData.diPdfIn = diPdfIn;
        newData.diPdfIn = diPdfIn === null || diPdfIn === "" ? null : diPdfIn;
        newData.priceSaleEsp = parseFloat(dataSt.priceSaleEsp);
        newData.listPhotos = list;
        newData.saleEspAt = dataSt.saleEspAt;
        newData.outputAt = dataSt.outputAt;
        console.log("Datos enviados a la API:", newData);
        serviContainersApiCXN
          .post(`${process.env.REACT_APP_HOST_API}/containers`, newData)
          .then((values) => {
            showNotificationApp("Se ha creado correctamente", "success");
            onClose();
          })
          .catch((error) => {
            showNotificationApp(
              "Ha ocurrido un error mientras se guardan los datos",
              "error"
            );
            setDataSt(iniData);
            setDataValid(iniDataValid);
          })
          .finally(() => setLoading(false));
      } else {
        let updateData = new Object();
        // updateData.createAt = dayjs(editData.createAt, "YYYY-MM").toDate();
        updateData.codeExt = dataSt.codeExt;
        updateData.codeInt = dataSt.codeInt;
        updateData.modeCatch = dataSt.modeCatch;
        updateData.typeModeCatch = dataSt.typeModeCatch;
        updateData.placeCountry = dataSt.place_countries;
        updateData.model = dataSt.model;
        updateData.profitAsoc = parseFloat(dataSt.profitAsoc) / 100;
        updateData.provider = dataSt.provider;
        updateData.state = dataSt.state;
        updateData.owner = dataSt.owner;
        updateData.weight = parseFloat(dataSt.weight);
        updateData.factoryYear = parseInt(dataSt.factoryYear["$y"]);
        updateData.propertyContainerAsocs = getListProperties();
        updateData.tacCode = dataSt.tacCode;
        updateData.description = dataSt.description;
        // updateData.createAt = dayjs().year(dataSt.createAt["$y"]);
        updateData.createAt = dataSt.createAt;
        updateData.invoice = dataSt.invoice;
        updateData.di = dataSt.di;
        updateData.invoicePdfIn = invoicePdfIn;

        updateData.diPdfIn = diPdfIn;

        updateData.priceSaleEsp = parseFloat(dataSt.priceSaleEsp);
        updateData.listPhotos = dataSt.listPhotos;
        updateData.saleEspAt = dataSt.saleEspAt;
        updateData.outputAt = dataSt.outputAt;
        serviContainersApiCXN
          .patch(
            `${process.env.REACT_APP_HOST_API}/containers/${editData.id}`,
            updateData
          )
          .then((values) => {
            showNotificationApp("Se ha actualizado correctamente", "success");
            onClose();
          })
          .catch((error) => {
            showNotificationApp(
              "Ha ocurrido un error mientras se guardan los datos",
              "error"
            );
            console.log(error);
            onClose();
          });
      }
    } else {
      setDataValid({ ...valid });
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const userIdt = getUserId();
    setUserId (userIdt);

    if (!open) {
      return undefined;
    }
    if (create) {
      setLoading(true);
      Promise.all([
        serviContainersApiCXN.get(`${process.env.REACT_APP_HOST_API}/users/${userIdt}`),
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/place_countries?pagination=false`
        ),
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/type_containers?pagination=false`
        ),
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/providers?pagination=false`
        ),
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/state_containers?pagination=false`
        ),
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/owners?pagination=false`
        ),
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/property_containers?pagination=false`
        ),
      ])
        .then((values) => {
          const [
            userData,
            place_countries,
            model,
            provider,
            state,
            owners,
            property_containers
          ] = values;
          console.log ( 'initData', userData );
          setUserData ( iniData1 (userData.data) );
          setPropertyContainerAsocs(
            adapterListPropertiesCheck(property_containers.data["hydra:member"])
          );
          setPlace_countries(place_countries.data["hydra:member"]);
          setModel(model.data["hydra:member"]);
          setProvider(provider.data["hydra:member"]);
          setStateContainer(state.data["hydra:member"]);
          setOwner(owners.data["hydra:member"]);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      Promise.all([
        
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/place_countries?pagination=false`
        ),
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/type_containers?pagination=false`
        ),
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/providers?pagination=false`
        ),
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/state_containers?pagination=false`
        ),
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/owners?pagination=false`
        ),
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/property_containers?pagination=false`
        ),
        serviContainersApiCXN.get(`${process.env.REACT_APP_HOST_API}/users/${userIdt}`),
      ])
        .then((values) => {
          const [
            
            place_countries,
            model,
            provider,
            state,
            owners,
            property_containers,
            userData,
          ] = values;
          
          setPlace_countries(place_countries.data["hydra:member"]);
          setModel(model.data["hydra:member"]);
          setProvider(provider.data["hydra:member"]);
          setStateContainer(state.data["hydra:member"]);
          setOwner(owners.data["hydra:member"]);
          setPropertyContainerAsocs(
            adapterListPropertiesCheck(
              property_containers.data["hydra:member"],
              editData.obj.propertyContainerAsocs
            )
          );
          setUserData ( iniData1 ( userData.data ) ).then();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          console.log(editData);
          setDataSt({
            ...iniData,
            codeExt: editData.codeExt,
            codeInt: editData.codeInt,
            factoryYear: dayjs().year(editData.factoryYear),
            typeModeCatch: editData.typeModeCath,
            modeCatch: editData.modeCatch,
            profitAsoc: parseFloat(editData.profitAsocContainer),
            provider: editData.obj?.provider["@id"],
            place_countries: editData.obj?.placeCountry["@id"],
            state: editData.obj?.state["@id"],
            model: editData.obj?.model["@id"],
            owner: editData.owner,
            weight: editData.obj?.weight,
            tacCode: editData.tacCode,
            description: editData.description,
            createAt: dayjs(editData.obj.createAt),
            invoice: editData.invoice,
            di: editData.di,
            invoicePdfIn: editData.invoicePdfIn,
            diPdfIn: editData.diPdfIn,
            priceSaleEsp: editData.priceSaleEsp,
            listPhotos: editData.listPhotos,
            saleEspAt: dayjs(editData.obj.saleEspAt),
            outputAt: dayjs(editData.obj.outputAt),
          });
          setLoading(false);
        });
    }
  }, [open]);

  return (
    <React.Fragment>
   <Dialog open={open} fullWidth maxWidth="sm" scroll="paper" color="primary">
    <DialogTitle className="dialog-title" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      {`${create ? `Agregar Contenedores` : `Editar Contenedores`} `}
      <div style={{ marginLeft: 'auto', fontSize: '1rem', fontWeight: 'bold' }}>
      <FormControl
                    variant="standard"
                    sx={{ minWidth: "120px", width: "260px" }}
                    disabled={true}
                  >
                    <InputLabel id="country">Precio de Lista</InputLabel>
                    <Select
                      labelId="state-ct-select-label-equipo"
                      id="state-ct-select-equipo"
                      value={dataSt.model}
                      onChange={handleModel}
                      label="model"
                    >
                      {Model.map((ele) => {
                        return (
                          <MenuItem key={ele.id} value={ele["@id"]}>
                            {ele.priceList}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
      </div>
          <Tooltip title="Cerrar" placement="top">
            <IconButton
              aria-label="cerrar-form-data-client-sale"
              disabled={ loading}
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers>
          <Stack spacing={2}>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "8rem",
                  background: "#f2f2f2",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
          
                  
                <Stack direction="row" justifyContent="space-between">
                  <TextField
                    variant="standard"
                    label={`Código Externo`}
                    required
                    type="text"
                    disabled={ loading || !create }
                    value={dataSt.codeExt}
                    onChange={handleCodeExt}
                    autoComplete="false"
                    error={dataValid.codeExt.error}
                    helperText={dataValid.codeExt.errorText}
                    sx={{ minWidth: "120px", width: "260px" }}
                  />
                  <TextField
                    variant="standard"
                    label={`Código Interno`}
                    required
                    type="text"
                    disabled={loading || !create}
                    value={dataSt.codeInt}
                    onChange={handleCodeInt}
                    autoComplete="false"
                    error={dataValid.codeInt.error}
                    helperText={dataValid.codeInt.errorText}
                    sx={{ minWidth: "120px", width: "260px" }}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <FormControl
                    variant="standard"
                    sx={{ minWidth: "120px", width: "260px" }}
                    disabled={loading ||  userData.rolesApp == 2 }
                  >
                    <InputLabel id="country">Lugar</InputLabel>
                    <Select
                      labelId="state-ct-select-label-equipo"
                      id="state-ct-select-equipo"
                      value={dataSt.place_countries}
                      onChange={handlePlace_countries}
                      label="country"
                    >
                      {place_countries.map((ele) => {
                        return (
                          <MenuItem key={ele.id} value={ele["@id"]}>
                            {ele.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    sx={{ minWidth: "120px", width: "260px" }}
                    disabled={loading ||  userData.rolesApp == 2}
                  >
                    <InputLabel id="country">Tipo de Contenedor</InputLabel>
                    <Select
                      labelId="state-ct-select-label-equipo"
                      id="state-ct-select-equipo"
                      value={dataSt.model}
                      onChange={handleModel}
                      label="model"
                    >
                      {Model.map((ele) => {
                        return (
                          <MenuItem key={ele.id} value={ele["@id"]}>
                            {ele.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  {/* <FormControl
                    variant="standard"
                    sx={{ minWidth: "120px", width: "260px" }}
                    disabled={loading}
                  >
                    <InputLabel id="country">Estado del Contenedor</InputLabel>
                    <Select
                      labelId="state-ct-select-label-equipo"
                      id="state-ct-select-equipo"
                      value={dataSt.state}
                      onChange={handleState}
                      label="model"
                    >
                      {State.map((ele) => {
                        return (
                          <MenuItem key={ele.id} value={ele["@id"]}>
                            {ele.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl> */}
 <FormControl
  variant="standard"
  sx={{ minWidth: "120px", width: "260px" }}
  disabled={loading}
>
  <InputLabel id="country">Estado del Contenedor</InputLabel>
  <Select
    labelId="state-ct-select-label-equipo"
    id="state-ct-select-equipo"
    value={dataSt.state}
    onChange={handleState}
    label="model"
  >
    {State.map((ele) => {
      if (userData.rolesApp === 2) {
        if (ele.name === "RESERVADO PARA ARRIENDO" || ele.name === "RESERVADO PARA VENTA") {
          return (
            <MenuItem key={ele.id} value={ele["@id"]}>
              {ele.name}
            </MenuItem>
          );
        } else {
          return null;
        }
      } else {
        return (
          <MenuItem key={ele.id} value={ele["@id"]}>
            {ele.name}
          </MenuItem>
        );
      }
    })}
  </Select>
</FormControl>


                  <FormControl
                    variant="standard"
                    sx={{ minWidth: "120px", width: "260px" }}
                    disabled={loading ||  userData.rolesApp == 2}
                  >
                    <InputLabel id="country">Proveedor</InputLabel>
                    <Select
                      labelId="state-ct-select-label-equipo"
                      id="state-ct-select-equipo"
                      value={dataSt.provider}
                      onChange={handleProvider}
                      label="model"
                    >
                      {Provider.map((ele) => {
                        return (
                          <MenuItem key={ele.id} value={ele["@id"]}>
                            {ele.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <FormControl
                    variant="standard"
                    sx={{ minWidth: "120px", width: "260px" }}
                    disabled={loading ||  userData.rolesApp == 2}
                  >
                    <InputLabel id="country">Propietario</InputLabel>
                    <Select
                      labelId="state-ct-select-label-propietario"
                      id="state-ct-select-propietario"
                      value={dataSt.owner}
                      onChange={handleOwner}
                      label="model"
                    >
                      {Owner.map((ele) => {
                        return (
                          <MenuItem key={ele["@id"]} value={ele["@id"]}>
                            {ele.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    variant="standard"
                    label="Peso contenedor"
                    required
                    type="number"
                    disabled={loading ||  userData.rolesApp == 2}
                    value={dataSt.weight}
                    onChange={handleWeight}
                    autoComplete="false"
                    sx={{ minWidth: "120px", width: "260px" }}
                    inputProps={{ min: 0, max: 100 }}
                  />
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <TextField
                    variant="standard"
                    label="Factura"
                    required
                    type="text"
                    disabled={loading ||  userData.rolesApp == 2}
                    value={dataSt.invoice}
                    onChange={handleinvoice}
                    autoComplete="false"
                    error={dataValid.invoice.error}
                    sx={{ minWidth: "120px", width: "260px" }}
                    inputProps={{ min: 0, max: 100 }}
                  />

                  <TextField
                    variant="standard"
                    label="DI"
                    required
                    type="text"
                    disabled={loading ||  userData.rolesApp == 2}
                    value={dataSt.di}
                    onChange={handledi}
                    autoComplete="false"
                    error={dataValid.di.error}
                    sx={{ minWidth: "120px", width: "260px" }}
                    inputProps={{ min: 0, max: 100 }}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <MuiFileInput
                    variant="standard"
                    inputProps={{ accept: ".pdf" }}
                    value={pdfInvoice}
                    onChange={handlePdfInvoice}
                    disabled={loading ||  userData.rolesApp == 2}
                    error={pdfValidInvoice.error}
                    helperText={pdfValidInvoice.text}
                    sx={{ minWidth: "120px", width: "260px" }}
                  />
                  <MuiFileInput
                    variant="standard"
                    inputProps={{ accept: ".pdf" }}
                    value={pdfDI}
                    onChange={handlePdfDI}
                    disabled={loading ||  userData.rolesApp == 2}
                    error={pdfValidDI.error}
                    helperText={pdfValidDI.text}
                    sx={{ minWidth: "120px", width: "260px" }}
                  />
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <TextField
                    variant="standard"
                    label={`TATC`}
                    required
                    type="text"
                    disabled={loading ||  userData.rolesApp == 2}
                    value={dataSt.tacCode}
                    onChange={handleTacCode}
                    autoComplete="false"
                    error={dataValid.tacCode.error}
                    helperText={dataValid.tacCode.errorText}
                    sx={{
                      minWidth: "120px",
                      width: "260px",
                    }}
                  />
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es"
                  >
                    <DatePicker
                      label="Año de fabricación"
                      value={dayjs(dataSt.factoryYear)}
                      maxDate={dayjs("9999")}
                      views={["year"]}
                      disabled={loading ||  userData.rolesApp == 2}
                      onChange={(newValue) =>
                        setDataSt({ ...dataSt, factoryYear: newValue })
                      }
                      autoComplete="false"
                      slotProps={{
                        textField: {
                          variant: "standard",
                          fullWidth: true,
                        },
                      }}
                      sx={{ minWidth: "120px", width: "260px" }}
                    />
                  </LocalizationProvider>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es"
                  >
                    <DatePicker
                      label="Fecha de internación"
                      value={dataSt.createAt}
                      maxDate={dayjs("9999")}
                      onChange={(newValue) =>
                        setDataSt({ ...dataSt, createAt: newValue })
                      }
                      disabled={loading ||  userData.rolesApp == 2}
                      autoComplete="false"
                      slotProps={{
                        textField: {
                          variant: "standard",
                          fullWidth: true,
                          format: "DD-MM-YYYY",
                        },
                      }}
                      sx={{ minWidth: "120px", width: "260px" }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es"
                  >
                    <DatePicker
                      label="Fecha de Egreso"
                      value={dataSt.outputAt}
                      maxDate={dayjs("9999")}
                      onChange={(newValue) =>
                        setDataSt({ ...dataSt, outputAt: newValue })
                      }
                      disabled={loading ||  userData.rolesApp == 2}
                      autoComplete="false"
                      slotProps={{
                        textField: {
                          variant: "standard",
                          fullWidth: true,
                          format: "DD-MM-YYYY",
                        },
                      }}
                      sx={{ minWidth: "120px", width: "260px" }}
                    />
                  </LocalizationProvider>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es"
                  >
                    <DatePicker
                      label="Fecha de compra"
                      value={dataSt.saleEspAt}
                      maxDate={dayjs("9999")}
                      onChange={(newValue) =>
                        setDataSt({ ...dataSt, saleEspAt: newValue })
                      }
                      disabled={loading ||  userData.rolesApp == 2}
                      autoComplete="false"
                      slotProps={{
                        textField: {
                          variant: "standard",
                          fullWidth: true,
                          format: "DD-MM-YYYY",
                        },
                      }}
                      sx={{ minWidth: "120px", width: "260px" }}
                    />
                  </LocalizationProvider>
                  <TextField
                    variant="standard"
                    label="Ganancia en %"
                    required
                    type="number"
                    disabled={loading ||  userData.rolesApp == 2}
                    value={dataSt.profitAsoc}
                    onChange={handleProfitAsoc}
                    autoComplete="false"
                    error={dataValid.profitAsoc.error}
                    sx={{ minWidth: "120px", width: "260px" }}
                    inputProps={{ min: 0, max: 100 }}
                  />
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormLabel component="legend">
                      Características contenedor
                    </FormLabel>
                    <FormGroup>
                      {PropertyContainerAsocs?.map((ele, key) => (
                        <FormControlLabel
                          key={ele.id}
                          control={<Checkbox checked={ele.checked} />}
                          onChange={(event) =>
                            handlePropertiesContainers(event, key)
                          }
                          disabled={loading ||  userData.rolesApp == 2}
                          name={ele.name}
                          label={ele.name}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                  <TextField
                    variant="standard"
                    label="Precio de venta"
                    required
                    type="number"
                    disabled={loading ||  userData.rolesApp == 2}
                    value={dataSt.priceSaleEsp}
                    onChange={handlepriceSaleEsp}
                    autoComplete="false"
                    sx={{ minWidth: "120px", width: "260px" }}
                    inputProps={{ min: 0, max: 100 }}
                  />
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  {/* <p>Añadir fotos del contenedor</p> */}
                  <MuiFileInput
                    fullWidth={true}
                    variant="standard"
                    multiple
                    inputProps={{
                      accept: "image/png, image/jpeg",
                    }}
                    value={photos}
                    onChange={handlePhotos}
                    disabled={loading ||  userData.rolesApp == 2}
                    error={photosValid.error}
                    helperText={photosValid.text}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <TextField
                    fullWidth={true}
                    multiline
                    rows={2}
                    name="standart"
                    variant="standard"
                    label={`Observaciones`}
                    required
                    type="text"
                    disabled={loading ||  userData.rolesApp == 2}
                    value={dataSt.description}
                    onChange={handleDescription}
                    autoComplete="false"
                  />
                </Stack>
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ borderRadius: 0 }}
            color="inherit"
            disabled={loading}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{
              borderRadius: 0,
              background: "#212544",
            }}
            loading={loading}
            onClick={handleSubmit}
          >
            Aceptar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
