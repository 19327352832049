import * as React from "react";
import { Box, Tab, Paper } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TypeContainer from "./TypeContainer/TypeContainer";
import Provider from "./Provider/Provider";
import AreaContry from "./AreaContry/AreaContry";
import User from "./User/User";
import Profit from "./Profit/Profit";
import TypeCost from "./TypeCost/TypeCost";
import StateContainer from "./StateContainer/StateContainer";
import Place from "./Place/Place";
import TabProperties from "./Properties/TabProperties";
import TabTrader from "./Lessor/TabTrader";
import TabOwners from "./Owners/TabOwners";
// import Tabla from "./prueba/tabla";

export default function BasicTabs() {
  const [valueTab, setValueTab] = React.useState("tab1");

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <React.Fragment>
      <TabContext value={valueTab}>
        <Box sx={{ width: "100%" }}>
          <TabList
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              value="tab1"
              label="TIPOS DE CONTENEDORES"
              sx={{ color: "primary" }}
            />
            <Tab
              value="tab2"
              label="PROVEDORES"
              sx={{ marginLeft: 2, color: "primary" }}
            />
            <Tab
              value="tab3"
              label="PAÍSES"
              sx={{ marginLeft: 2, color: "primary" }}
            />
            {/* AREACONTENT */}
            <Tab
              value="tab4"
              label="USUARIOS"
              sx={{ marginLeft: 2, color: "primary" }}
            />
            {/* USERNAME */}
            <Tab
              value="tab5"
              label="GANANCIAS"
              sx={{ marginLeft: 2, color: "primary" }}
            />
            {/* //GENERALCONFIGURATION */}
            <Tab
              value="tab6"
              label="TIPO DE GASTO"
              sx={{ marginLeft: 2, color: "primary" }}
            />

            <Tab
              value="tab7"
              label="CARACTERÍSTICAS CONTENEDOR"
              sx={{ marginLeft: 2, color: "primary" }}
            />

            <Tab
              value="tab8"
              label="ESTADO CONTENEDOR"
              sx={{ marginLeft: 2, color: "primary" }}
            />

            <Tab
              value="tab9"
              label="LUGARES"
              sx={{ marginLeft: 2, color: "primary" }}
            />
            <Tab
              value="tab10"
              label="ARRENDADOR"
              sx={{ marginLeft: 2, color: "primary" }}
            />
            <Tab
              value="tab11"
              label="PROPIETARIOS"
              sx={{ marginLeft: 2, color: "primary" }}
            />
            {/* <Tab
              value="tab12"
              label="PROPIETARIOS"
              sx={{ marginLeft: 2, color: "primary" }}
            /> */}
          </TabList>
        </Box>
        <Paper>
          <Box>
            <TabPanel value="tab1" sx={{ heigh: "300px" }}>
              <TypeContainer />
            </TabPanel>
            <TabPanel value="tab2" sx={{ heigh: "300px" }}>
              <Provider />
            </TabPanel>
            <TabPanel value="tab3" sx={{ heigh: "300px" }}>
              <AreaContry />
            </TabPanel>
            <TabPanel value="tab4" sx={{ heigh: "300px" }}>
              <User />
            </TabPanel>
            <TabPanel value="tab5" sx={{ heigh: "300px" }}>
              <Profit />
            </TabPanel>
            <TabPanel value="tab6" sx={{ heigh: "300px" }}>
              <TypeCost />
            </TabPanel>
            <TabPanel value="tab7" sx={{ heigh: "300px" }}>
              <TabProperties />
            </TabPanel>
            <TabPanel value="tab8" sx={{ heigh: "300px" }}>
              <StateContainer />
            </TabPanel>
            <TabPanel value="tab9" sx={{ heigh: "300px" }}>
              <Place />
            </TabPanel>
            <TabPanel value="tab10" sx={{ heigh: "300px" }}>
              <TabTrader />
            </TabPanel>
            <TabPanel value="tab11" sx={{ heigh: "300px" }}>
              <TabOwners />
            </TabPanel>
            {/* <TabPanel value="tab12" sx={{ heigh: "300px" }}>
              <Tabla />
            </TabPanel> */}
          </Box>
        </Paper>
      </TabContext>
    </React.Fragment>
  );
}
