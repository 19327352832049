import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../../Providers/AppProvider";
import { useContext } from "react";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { serviContainersApiCXN } from "../../../auth/axios.Container";

const iniData = {
  name: "",
  concept: "",
  defaultValue: "",
  active: true,
};
const iniDataValid = {
  name: { error: false, errorText: "" },
  concept: { error: false, errorText: "" },
  defaultValue: { error: false, errorText: "" },
};

export default function FormElements({
  open,
  onClose,
  create,
  editData,
  onUpdate,
  onAdd,
}) {
  const [dataSt, setDataSt] = React.useState(iniData);
  const [dataValid, setDataValid] = React.useState(iniDataValid);
  const [loading, setLoading] = React.useState(false);
  const [refreshTable, setRefreshTable] = React.useState(0);
  const [, setSelectionTab] = React.useState([]);

  const { showNotificationApp } = useContext(AppContext);
  const theme = useTheme();

  const handleClose = () => {
    setDataSt(iniData);
    setDataValid(iniDataValid);
    setLoading(false);
    onClose();
  };

  const handleRefreshT = () => {
    setRefreshTable(refreshTable === 0 ? 1 : 0);
    setSelectionTab([]);
  };

  const handleName = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        name: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        name: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      name: event.target.value,
    });
  };
  const handleConcept = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        concept: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        concept: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      concept: event.target.value,
    });
  };
  const handleDefaultValue = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        defaultValue: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        defaultValue: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      defaultValue: event.target.value,
    });
  };
  const handleActive = (event) => {
    setDataSt({
      ...dataSt,
      active: event.target.checked,
    });
  };

  const handleSubmit = () => {
    let valid = { ...dataValid };
    let fail = false;
    setLoading(true);

    if (dataValid.name.error) fail = true;
    if (dataValid.concept.error) fail = true;
    if (dataValid.defaultValue.error) fail = true;

    if (dataSt.name === "") {
      fail = true;
      valid = {
        ...valid,
        name: { error: true, errorText: "Este campo es obligatorio." },
      };
    }
    if (dataSt.concept === "") {
      fail = true;
      valid = {
        ...valid,
        concept: { error: true, errorText: "Este campo es obligatorio." },
      };
    }
    if (dataSt.defaultValue === "") {
      fail = true;
      valid = {
        ...valid,
        defaultValue: { error: true, errorText: "Este campo es obligatorio." },
      };
    }

    if (!fail) {
      if (create) {
        let newData = new Object();
        newData.name = dataSt.name;
        newData.concept = dataSt.concept;
        newData.defaultValue = parseFloat(dataSt.defaultValue);
        newData.active = dataSt.active;
        serviContainersApiCXN
          .post(`${process.env.REACT_APP_HOST_API}/type_costs`, newData)
          .then((response) => {
            showNotificationApp("Se ha creado correctamente", "success");
            onClose();
          })
          .catch((error) => {
            showNotificationApp(
              "Ha ocurrido un error mientras se guardan los datos",
              "error"
            );
            console.log(error);
            setDataSt(iniData);
            setDataValid(iniDataValid);
          })
          .finally(() => setLoading(false));
      } else {
        let updateData = new Object();
        updateData.name = dataSt.name;
        updateData.concept = dataSt.concept;
        updateData.defaultValue = dataSt.defaultValue;
        updateData.active = dataSt.active;
        serviContainersApiCXN
          .patch(
            `${process.env.REACT_APP_HOST_API}/type_costs/${editData.id}`,
            updateData
          )
          .then((response) => {
            showNotificationApp("Se ha actualizado correctamente", "success");
            onClose();
          })
          .catch((error) => {
            showNotificationApp(
              "Ha ocurrido un error mientras se guardan los datos",
              "error"
            );
            console.log(error);
            onClose();
          });
      }
    } else {
      setDataValid({ ...valid });
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }
    if (create) {
    } else {
      console.log(editData);
      setDataSt({
        id: editData.id,
        name: editData.name,
        concept: editData.concept,
        defaultValue: editData.defaultValue,
        active: editData.active === "Activado" ? true : false,
      });
    }
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        scroll="paper"
        color="primary"
      >
        <DialogTitle className="dialog-title">
          {`${create ? `Agregar tipo de Gasto` : `Editar tipo de Gasto`} `}
          <Tooltip title="Cerrar" placement="top">
            <IconButton
              aria-label="cerrar-form-data-client-sale"
              disabled={loading}
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers sx={{ maxHeight: "305h", minHeight: "35vh" }}>
          <Stack
            direction="column"
            spacing={2}
            alignItems="initial"
            alignContent="center"
            sx={{ mb: "1vh" }}
          >
            <TextField
              variant="standard"
              label={`Nombre`}
              required
              type="text"
              disabled={loading}
              value={dataSt.name}
              onChange={handleName}
              autoComplete="false"
              error={dataValid.name.error}
              helperText={dataValid.name.errorText}
              sx={{ minWidth: "120px", width: "400px" }}
            />
            <TextField
              variant="standard"
              label={`Concepto`}
              required
              type="text"
              disabled={loading}
              value={dataSt.concept}
              onChange={handleConcept}
              autoComplete="false"
              error={dataValid.concept.error}
              helperText={dataValid.concept.errorText}
              sx={{ minWidth: "120px", width: "400px" }}
            />
            <TextField
              variant="standard"
              label={`Valor por defecto`}
              required
              type="text"
              disabled={loading}
              value={dataSt.defaultValue}
              onChange={handleDefaultValue}
              autoComplete="false"
              error={dataValid.defaultValue.error}
              helperText={dataValid.defaultValue.errorText}
              sx={{ minWidth: "120px", width: "400px" }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={dataSt.active}
                  onChange={handleActive}
                  inputProps={{ "aria-label": "controlled" }}
                  label="Label"
                />
              }
              label="Estado"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ borderRadius: 0 }}
            color="inherit"
            disabled={loading}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{
              borderRadius: 0,
              background: "#212544",
            }}
            loading={loading}
            onClick={handleSubmit}
          >
            Aceptar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
