// import * as React from "react";
// import {
//   Box,
//   Stack,
//   Divider,
//   IconButton,
//   Skeleton,
//   Tooltip,
// } from "@mui/material";
// import {
//   DataGrid,
//   GridToolbarContainer,
//   GridToolbarExport,
//   GridToolbarFilterButton,
//   esES,
// } from "@mui/x-data-grid";
// import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
// import { useContext } from "react";
// import { AppContext } from "../../../Providers/AppProvider";
// import { serviContainersApiCXN } from "../../../auth/axios.Container";
// import TextField from "@mui/material/TextField";
// import SearchIcon from "@mui/icons-material/Search";
// import InputBase from "@mui/material/InputBase";

// const iniData = (data) => {
//   let ret = [];
//   data.forEach((element) => {
//     console.log(element);
//     ret.push({
//       id: element["@id"],
//       codeInt: element.container.codeInt,
//       operation: element.operation,
//       dateAt: new Date(element.dateAt),
//       clientName: element.container.clientName,
//       di: element.container.di,
//       invoice: element.container.invoice,
//       model: element.container.model,
//       owner: element.container.owner,
//       price: element.container.price,
//       provider: element.container.provider,
//       state: element.container.state,
//       tacCode: element.container.tacCode,
//     });
//   });
//   return ret;
// };

// export default function TabXContainer() {
//   //const
//   const [loading, setLoading] = React.useState(false);
//   const [refreshTable, setRefreshTable] = React.useState(0);
//   const [selectionTab, setSelectionTab] = React.useState([]);
//   const [items, setItems] = React.useState([]);
//   const { showNotificationApp } = useContext(AppContext);

//   const handleSubmitShearch = (text) => {
//     setLoading(true);

//     serviContainersApiCXN
//       .get(
//         `${process.env.REACT_APP_HOST_API}/operation_containers/container/${text}?pagination=false`
//       )
//       .then((response) => {
//         console.log(response.data["hydra:member"]);
//         const data = response.data["hydra:member"];
//         if (data.length === 0) {
//           showNotificationApp(
//             "No existe registro de contenedores asociado a ese código",
//             "info"
//           );
//         } else {
//           setItems(iniData(data));
//         }
//       })
//       .catch(function (error) {
//         // handle error
//         showNotificationApp("Error cargando datos del sistema", "error");
//         setItems([]);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };

//   const handleRefreshT = () => {
//     setLoading(true);
//     setSelectionTab([]);
//     setRefreshTable(refreshTable === 0 ? 1 : 0);
//     setTimeout(() => {
//       setLoading(false);
//     }, 1000);
//   };

//   const MenuToolBar = ({ onSubmitShearch }) => {
//     // const [search, setSearch] = React.useState([]);
//     const [search, setSearch] = React.useState({ codeInt: "" });

//     const handleCodeInt = (event) => {
//       setSearch({
//         ...search,
//         codeInt: event.target.value,
//       });
//     };

//     const handleSubmit = () => {
//       onSubmitShearch(search.codeInt);
//     };

//     return (
//       <React.Fragment>
//         <Stack
//           direction="row"
//           justifyContent="flex-end"
//           divider={<Divider orientation="vertical" />}
//         >
//           <IconButton
//             variant="text"
//             aria-label="refresh"
//             color="primary"
//             onClick={handleRefreshT}
//           >
//             <Tooltip title="Actualizar Tabla" placement="top">
//               <RefreshSharpIcon />
//             </Tooltip>
//           </IconButton>
//           <Divider orientation="vertical" flexItem />
//           {/* <InputBase
//             sx={{ ml: 1, flex: 1 }}
//             placeholder="Buscar por código interno"
//             inputProps={{ "aria-label": "buscar por código interno" }}
//             value={search.codeInt}
//             onChange={handleCodeInt}
//           /> */}
//           <InputBase
//             sx={{ ml: 1, flex: 1 }}
//             placeholder="Buscar por código interno"
//             inputProps={{ "aria-label": "buscar por código interno" }}
//             value={search.codeInt}
//             onChange={handleCodeInt}
//           />
//           <IconButton
//             type="button"
//             // sx={{ p: "10px" }}
//             aria-label="search"
//             onClick={handleSubmit}
//           >
//             <SearchIcon />
//           </IconButton>
//         </Stack>
//       </React.Fragment>
//     );
//   };

//   const CustomToolbar = () => {
//     return (
//       <GridToolbarContainer>
//         <GridToolbarFilterButton />
//         <GridToolbarExport
//           printOptions={{ disableToolbarButton: true }}
//           csvOptions={{ allColumns: true }}
//         />
//         <MenuToolBar onSubmitShearch={handleSubmitShearch} />
//       </GridToolbarContainer>
//     );
//   };

//   return (
//     <React.Fragment>
//       {loading ? (
//         <Skeleton
//           variant="rectangular"
//           width={"100%"}
//           height={"400px"}
//           animation="wave"
//         />
//       ) : (
//         <React.Fragment>
//           <Box sx={{ height: "60vh", width: "100%" }}>
//             <DataGrid
//               checkboxSelection
//               localeText={esES.components.MuiDataGrid.defaultProps.localeText}
//               rows={items}
//               columns={[
//                 { field: "codeInt", headerName: "Código Interno", width: 200 },
//                 {
//                   field: "dateAt",
//                   headerName: "Fecha",
//                   width: 200,
//                   type: "dateTime",
//                 },

//                 {
//                   field: "operation",
//                   headerName: "",
//                   width: 200,
//                 },
//                 {
//                   field: "clientName",
//                   headerName: "Cliente",
//                   width: 200,
//                 },
//                 {
//                   field: "di",
//                   headerName: "DI",
//                   width: 200,
//                 },
//                 {
//                   field: "invoice",
//                   headerName: "Factura",
//                   width: 200,
//                 },
//                 {
//                   field: "model",
//                   headerName: "Modelo",
//                   width: 200,
//                 },
//                 {
//                   field: "owner",
//                   headerName: "Propietario",
//                   width: 200,
//                 },
//                 {
//                   field: "price",
//                   headerName: "Precio",
//                   width: 200,
//                 },
//                 {
//                   field: "provider",
//                   headerName: "Proveedor",
//                   width: 200,
//                 },
//                 {
//                   field: "state",
//                   headerName: "Estado",
//                   width: 200,
//                 },
//                 {
//                   field: "tacCode",
//                   headerName: "TATC",
//                   width: 200,
//                 },
//               ]}
//               initialState={{
//                 sorting: {
//                   sortModel: [{ field: "name", sort: "desc" }],
//                 },
//               }}
//               components={{
//                 Toolbar: CustomToolbar,
//               }}
//               onRowSelectionModelChange={(newSelectionModel) => {
//                 setSelectionTab(newSelectionModel);
//               }}
//               rowSelectionModel={selectionTab}
//               pageSizeOptions={[5, 25, 50, 100]}
//             />
//           </Box>
//         </React.Fragment>
//       )}
//     </React.Fragment>
//   );
// }

import * as React from "react";
import {
  Box,
  Stack,
  Divider,
  IconButton,
  Skeleton,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  esES,
} from "@mui/x-data-grid";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import { useContext } from "react";
import { AppContext } from "../../../Providers/AppProvider";
import { serviContainersApiCXN } from "../../../auth/axios.Container";

const iniData = (data) => {
  let ret = [];
  data.forEach((element) => {
    if (element.container) {
      console.log(element);
      ret.push({
        id: element["@id"],
        codeInt: element.container.codeInt,
        operation: element.operation,
        dateAt: new Date(element.dateAt),
        clientName: element.container.clientName,
        di: element.container.di,
        invoice: element.container.invoice,
        model: element.container.model,
        owner: element.container.owner,
        price: element.container.price,
        provider: element.container.provider,
        state: element.container.state,
        tacCode: element.container.tacCode,
      });
    }
  });
  return ret;
};

export default function TabOperationsContainers() {
  //const
  const [loading, setLoading] = React.useState(true);
  const [refreshTable, setRefreshTable] = React.useState(0);
  const [selectionTab, setSelectionTab] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const { showNotificationApp } = useContext(AppContext);

  React.useEffect(() => {
    setLoading(true);
    serviContainersApiCXN
      .get(
        `${process.env.REACT_APP_HOST_API}/operation_containers?pagination=false`
      )
      .then((response) => {
        setItems(iniData(response.data["hydra:member"]));
      })
      .catch(function (error) {
        // handle error
        showNotificationApp("Error cargando datos del sistema", "error");
      })
      .finally(function () {
        // always executed
        setLoading(false);
      });
  }, [refreshTable]);

  const handleRefreshT = () => {
    setLoading(true);
    setSelectionTab([]);
    setRefreshTable(refreshTable === 0 ? 1 : 0);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const MenuToolBar = () => {
    return (
      <React.Fragment>
        <Stack
          direction="row"
          justifyContent="flex-end"
          divider={<Divider orientation="vertical" />}
        >
          <IconButton
            variant="text"
            aria-label="refresh"
            color="primary"
            onClick={handleRefreshT}
          >
            <Tooltip title="Actualizar Tabla" placement="top">
              <RefreshSharpIcon />
            </Tooltip>
          </IconButton>
        </Stack>
      </React.Fragment>
    );
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true }}
        />
        <MenuToolBar />
      </GridToolbarContainer>
    );
  };

  return (
    <React.Fragment>
      {loading ? (
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"400px"}
          animation="wave"
        />
      ) : (
        <React.Fragment>
          <Box sx={{ height: "60vh", width: "100%" }}>
            <DataGrid
              checkboxSelection
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              rows={items}
              columns={[
                { field: "codeInt", headerName: "Código Interno", width: 200 },
                {
                  field: "dateAt",
                  headerName: "Fecha",
                  width: 200,
                  type: "dateTime",
                },

                {
                  field: "operation",
                  headerName: "Operación Realizada",
                  width: 200,
                },
                {
                  field: "clientName",
                  headerName: "Cliente",
                  width: 200,
                },
                {
                  field: "di",
                  headerName: "DI",
                  width: 200,
                },
                {
                  field: "invoice",
                  headerName: "Factura",
                  width: 200,
                },
                {
                  field: "model",
                  headerName: "Modelo",
                  width: 200,
                },
                {
                  field: "owner",
                  headerName: "Propietario",
                  width: 200,
                },
                {
                  field: "price",
                  headerName: "Precio",
                  width: 200,
                },
                {
                  field: "provider",
                  headerName: "Proveedor",
                  width: 200,
                },
                {
                  field: "state",
                  headerName: "Estado",
                  width: 200,
                },
                {
                  field: "tacCode",
                  headerName: "TATC",
                  width: 200,
                },
              ]}
              initialState={{
                sorting: {
                  sortModel: [{ field: "name", sort: "desc" }],
                },
              }}
              components={{
                Toolbar: CustomToolbar,
              }}
              onRowSelectionModelChange={(newSelectionModel) => {
                setSelectionTab(newSelectionModel);
              }}
              rowSelectionModel={selectionTab}
              pageSizeOptions={[5, 25, 50, 100]}
            />
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
