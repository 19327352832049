// import * as React from "react";
// import {
//   Box,
//   Stack,
//   Divider,
//   IconButton,
//   Skeleton,
//   Tooltip,
// } from "@mui/material";
// import {
//   DataGrid,
//   GridToolbarContainer,
//   GridToolbarExport,
//   GridToolbarFilterButton,
// } from "@mui/x-data-grid";
// import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
// import LowPriorityIcon from "@mui/icons-material/LowPriority";
// import FromRentModule from "./FormRentModule";
// import { useContext } from "react";
// import { AppContext } from "../../../Providers/AppProvider";
// import { serviContainersApiCXN } from "../../../auth/axios.Container";
// import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
// import ViewRentModule from "./ViewRentModule";
// import { isAccessPermission } from "../../../auth/security.ServiContainers";

// const iniData = (data) => {
//   let ret = [];

//   data.forEach((element) => {
//     console.log(element.photos);
//     const photos = element.photos.map((photo) => ({
//       name: photo,
//     }));

//     ret.push({
//       id: element.id,
//       module: element.module.codeModule,
//       price: element.price,
//       profitAsoc: `${element.profitAsoc * 100}%`,
//       clientName: element.clientName,
//       bill: element.bill || "SIN FACTURA",
//       initRentAt: new Date(element.initRentAt),
//       finishRentAt: new Date(element.finishRentAt),
//       profitOwner: element.profitOwner,
//       profitTrider: element.profitTrader,
//       photos: photos,
//     });
//   });
//   return ret;
// };

// export default function RentModule() {
//   //const
//   const [loading, setLoading] = React.useState(true);
//   const [refreshTable, setRefreshTable] = React.useState(0);
//   const [selectionTab, setSelectionTab] = React.useState([]);
//   const [items, setItems] = React.useState([]);
//   const [returnM, setReturnM] = React.useState(false);
//   const { showNotificationApp } = useContext(AppContext);
//   const [openViewData, setOpenViewData] = React.useState(false);

//   console.log(returnM);
//   const handleUpdateData = () => {
//     setReturnM(true);
//   };

//   const handleCloseReturnM = () => {
//     setReturnM(false);
//     handleRefreshT();
//   };

//   const handleViewData = () => {
//     if (selectionTab.length === 1) {
//       setOpenViewData(true);
//     }
//   };
//   const handleCloseViewData = () => {
//     setOpenViewData(false);
//     handleRefreshT();
//   };

//   React.useEffect(() => {
//     setLoading(true);
//     serviContainersApiCXN
//       .get(
//         `${process.env.REACT_APP_HOST_API}/rent_containers/module?pagination=false`
//       )
//       .then((response) => {
//         console.log(response.data["hydra:member"]);
//         setItems(iniData(response.data["hydra:member"]));
//       })
//       .catch(function (error) {
//         // handle error
//         console.log(error);
//       })
//       .finally(function () {
//         // always executed
//         setLoading(false);
//       });
//   }, [refreshTable]);

//   const handleRefreshT = () => {
//     setLoading(true);
//     setRefreshTable(refreshTable === 0 ? 1 : 0);
//     setSelectionTab([]);
//     setTimeout(() => {
//       setLoading(false);
//     }, 1000);
//   };
//   const MenuToolBar = () => {
//     return (
//       <React.Fragment>
//         <Stack
//           direction="row"
//           justifyContent="flex-end"
//           divider={<Divider orientation="vertical" />}
//         >
//           <IconButton
//             variant="text"
//             aria-label="refresh"
//             color="primary"
//             onClick={handleRefreshT}
//           >
//             <Tooltip title="Actualizar Tabla" placement="top">
//               <RefreshSharpIcon />
//             </Tooltip>
//           </IconButton>
//           <IconButton
//             variant="text"
//             aria-label="view"
//             color="primary"
//             onClick={handleViewData}
//             disabled={selectionTab.length !== 1}
//           >
//             <Tooltip title="Ver" placement="top">
//               <VisibilitySharpIcon />
//             </Tooltip>
//           </IconButton>
//           <Divider orientation="vertical" flexItem />
//           {(isAccessPermission(0) || isAccessPermission(2)) && (
//             <>
//               <IconButton
//                 variant="text"
//                 aria-label="Devolver Contenedor"
//                 color="primary"
//                 disabled={selectionTab.length !== 1}
//                 onClick={handleUpdateData}
//               >
//                 <Tooltip title="Devolver Contenedor" placement="top">
//                   <LowPriorityIcon />
//                 </Tooltip>
//               </IconButton>
//             </>
//           )}
//         </Stack>
//       </React.Fragment>
//     );
//   };

//   //menubar
//   const CustomToolbar = () => {
//     return (
//       <GridToolbarContainer>
//         <GridToolbarFilterButton />
//         <GridToolbarExport
//           printOptions={{ disableToolbarButton: true }}
//           csvOptions={{ allColumns: true }}
//         />
//         <MenuToolBar />
//       </GridToolbarContainer>
//     );
//   };

//   // const CustomPhotosCell = (params) => {
//   //   const photos = params.value;

//   //   if (Array.isArray(photos) && photos.length > 0) {
//   //     return (
//   //       <ul>
//   //         {photos.map((photo, index) => (
//   //           <li key={index}>{photo.name}</li>
//   //         ))}
//   //       </ul>
//   //     );
//   //   } else {
//   //     return <span>No hay fotos disponibles</span>;
//   //   }
//   // };

//   return (
//     <React.Fragment>
//       {loading ? (
//         <Skeleton
//           variant="rectangular"
//           width={"100%"}
//           height={"400px"}
//           animation="wave"
//         />
//       ) : (
//         <React.Fragment>
//           <Box sx={{ height: "60vh", width: "100%" }}>
//             <DataGrid
//               rows={items}
//               columns={[
//                 { headerName: "Módulo", field: "module", width: 200 },
//                 {
//                   headerName: "Inic. Arriendo",
//                   field: "initRentAt",
//                   type: "date",
//                   width: 200,
//                 },
//                 {
//                   headerName: "Fin. Arriendo",
//                   field: "finishRentAt",
//                   type: "date",
//                   width: 200,
//                 },
//                 { headerName: "Precio Venta", field: "price", width: 200 },
//                 {
//                   headerName: "Margen Ganancia",
//                   field: "profitAsoc",
//                   width: 200,
//                 },
//                 { headerName: "Cliente", field: "clientName", width: 200 },
//                 { headerName: "Factura Venta", field: "bill", width: 200 },
//                 {
//                   headerName: "Ganancia Propietario",
//                   field: "profitOwner",
//                   width: 200,
//                 },
//                 {
//                   headerName: "Ganancia Arrendador",
//                   field: "profitTrider",
//                   width: 200,
//                 },
//                 // {
//                 //   headerName: "Photos",
//                 //   field: "photos",
//                 //   width: 200,
//                 //   renderCell: CustomPhotosCell, // Utiliza la función de renderización personalizada
//                 // },
//               ]}
//               initialState={{
//                 sorting: {
//                   sortModel: [{ field: "id", sort: "desc" }],
//                 },
//               }}
//               //menu
//               components={{
//                 Toolbar: CustomToolbar,
//               }}
//               onRowSelectionModelChange={(newSelectionModel) => {
//                 console.log(newSelectionModel);
//                 setSelectionTab(newSelectionModel);
//               }}
//               rowSelectionModel={selectionTab}
//               pageSizeOptions={[5]}
//               checkboxSelection
//             />
//           </Box>
//           <FromRentModule
//             open={returnM}
//             onClose={handleCloseReturnM}
//             editData={
//               selectionTab.length === 1
//                 ? items.find((elem) => elem.id === selectionTab[0])
//                 : null
//             }
//           />
//           <ViewRentModule
//             open={openViewData}
//             onClose={handleCloseViewData}
//             viewDataRentModule={
//               selectionTab.length === 1
//                 ? items.find((elem) => elem.id === selectionTab[0])
//                 : null
//             }
//           />
//         </React.Fragment>
//       )}
//     </React.Fragment>
//   );
// }

import * as React from "react";
import {
  // Box,
  Stack,
  Divider,
  IconButton,
  Skeleton,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import FromRentModule from "./FormRentModule";
import { useContext } from "react";
import { AppContext } from "../../../Providers/AppProvider";
import { serviContainersApiCXN } from "../../../auth/axios.Container";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import ViewRentModule from "./ViewRentModule";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import "primereact/resources/themes/md-light-indigo/theme.css";
import "./container.css";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getUserId, isAccessPermission } from "../../../auth/security.ServiContainers";
import { ROLES, isAccess } from "../../../auth/security.ServiContainers";
import { getUserRoleData } from './../../../utils/Roles';


const iniData1 = (data) => {
  console.log( 'Data completa', data);
    console.log('RolesApp', data.rolesApp);
    console.log('Country', data.areaCountry.name);


  const ret = { obj: data };   

  return {
    rolesApp: ret.obj.rolesApp,
    country: ret.obj.areaCountry.name
  };  
};

function filterStateSeller ( containers ){
   
  const filterArray = containers.filter(elemento => 
    elemento.state == 'DISPONIBLE PARA VENTA' ||
    elemento.state == 'DISPONIBLE PARA ARRIENDO' ||
    elemento.state == 'DISPONIBLE PARA VENTA Y ARRIENDO'
  );
  
  return filterArray;
}



function filterCountryOperario ( containers, country ){
  console.log ( 'filter operario', {containers, country} );
  const filterArray = containers.filter(elemento => 
    elemento.obj.module.container.placeCountry.areaCountry.name == country
  );
  return filterArray;
}


const iniData = (data) => {
  let ret = [];

  data.forEach((element) => {
    console.log(element);
    const photos = element.photos.map((photo) => ({
      name: photo,
    }));

    ret.push({
      id: element.id,
      module: element.module.codeModule,
      price: element.price,
      profitAsoc: `${element.profitAsoc * 100}%`,
      clientName: element.clientName,
      bill: element.bill || "SIN FACTURA",
      initRentAt: new Date(element.initRentAt).toLocaleString(),
      finishRentAt: new Date(element.finishRentAt).toLocaleString(),
      profitOwner: element.profitOwner,
      profitTrider: element.profitTrader,
      photos: photos,
      obj: element,
    });
  });
  return ret;
};

const filtersDef = {
  module: { value: null, matchMode: FilterMatchMode.CONTAINS },
  initRentAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
  finishRentAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
  // model: { value: null, matchMode: FilterMatchMode.CONTAINS },
  price: { value: null, matchMode: FilterMatchMode.CONTAINS },
  profitAsoc: { value: null, matchMode: FilterMatchMode.CONTAINS },
  clientName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  bill: { value: null, matchMode: FilterMatchMode.CONTAINS },
  profitOwner: { value: null, matchMode: FilterMatchMode.CONTAINS },
  profitTrider: { value: null, matchMode: FilterMatchMode.CONTAINS },
  // selledAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
};

export default function RentModule() {
  //const
  const [loading, setLoading] = React.useState(true);
  const [refreshTable, setRefreshTable] = React.useState(0);
  const [selectionTab, setSelectionTab] = React.useState(null);
  const [items, setItems] = React.useState([]);
  const [filters, setFilters] = React.useState(filtersDef);

  const [returnM, setReturnM] = React.useState(false);
  const { showNotificationApp } = useContext(AppContext);
  const [openViewData, setOpenViewData] = React.useState(false);

  const dt = React.useRef(null);
  const [metaKey, setMetaKey] = React.useState(false);
  const cols = [
    { header: "Módulo", field: "module" },
    {
      header: "Inic. Arriendo",
      field: "initRentAt",
      type: "date",
    },
    {
      header: "Fin. Arriendo",
      field: "finishRentAt",
      type: "date",
    },
    { header: "Tipo", field: "model" },
    { header: "Precio Venta", field: "price" },
    {
      header: "Margen Ganancia",
      field: "profitAsoc",
    },
    { header: "Cliente", field: "clientName" },
    { header: "Factura Venta", field: "bill" },
    {
      header: "Ganancia Propietario",
      field: "profitOwner",
    },
    {
      header: "Ganancia Arrendador",
      field: "profitTrider",
    },
  ];
  const [items1, setItems1] = React.useState([]);
  const [userId, setUserId] = React.useState([]);
  const [rolesAppValue, setRolesAppValue] = React.useState(null); // Estado para rolesApp
  const [userDataTCountry, setUserDataTCountry] = React.useState(''); 

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportCSV = (selectionOnly) => {
    console.log(dt);
    dt.current.exportCSV({ selectionOnly });
  };

  console.log(returnM);
  const handleUpdateData = () => {
    setReturnM(true);
  };

  const handleCloseReturnM = () => {
    setReturnM(false);
    handleRefreshT();
  };

  const handleViewData = () => {
    if (selectionTab) {
      setOpenViewData(true);
    }
  };
  const handleCloseViewData = () => {
    setOpenViewData(false);
    handleRefreshT();
  };

  // React.useEffect(() => {
  //   setLoading(true);
  //   serviContainersApiCXN
  //     .get(
  //       `${process.env.REACT_APP_HOST_API}/rent_containers/module?pagination=false`
  //     )
  //     .then((response) => {
  //       console.log(response.data["hydra:member"]);
  //       setItems(iniData(response.data["hydra:member"]));
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     })
  //     .finally(function () {
  //       // always executed
  //       setLoading(false);
  //     });
  // }, [refreshTable]);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // Primero, obtén los datos del rol del usuario usando getUserRoleData
        const { rolesApp, country } = await getUserRoleData();
        setRolesAppValue(rolesApp); // Actualiza rolesAppValue con el valor obtenido
        setUserDataTCountry(country); // Actualiza userDataTCountry con el valor obtenido

        // Ahora, realiza la petición a /modules/
        setLoading(true);
        const response = await serviContainersApiCXN.get(`${process.env.REACT_APP_HOST_API}/rent_containers/module?pagination=false`);
        let itemsToSet;

        if (rolesApp === 2) {
          itemsToSet = filterStateSeller(iniData(response.data["hydra:member"]));
        } else if (rolesApp === 1) {
          itemsToSet = filterCountryOperario(iniData(response.data["hydra:member"]), country);
        } else if (rolesApp === 0) {
          itemsToSet = iniData(response.data["hydra:member"]);
        }

        setItems(itemsToSet);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        showNotificationApp("Error cargando datos del sistema", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Llama a la función fetchData
  }, [refreshTable]);
  // React.useEffect(() => {
  //   const userIdt = getUserId();
  //   setUserId (userIdt);

  //   let userDataT;

  //   console.log ( "UserIdt", userIdt, "UserId", userId );
  //   console.log(userId);
  
  //   // Definir la ruta de contenedores basada en los roles del usuario
  //   const getUserContainerRoute = () => {
  //     const possibleRoles = [ROLES.admin.tag, ROLES.operario.tag, ROLES.vendedor.tag, ROLES.manager.tag];
  
  //     for (const role of possibleRoles) {
  //       const userRole = isAccess(role);
  //       if (userRole) {
  //         const containerRoute = `rent_containers/module?pagination=false`;
  //         return containerRoute;
  //       }
  //     }
  //     return null;
  //   };
  
  //   const containerRoute = getUserContainerRoute();
  
  //   // Realizar el primer get de usuario
  //   setLoading(true);
  //   serviContainersApiCXN.get(`${process.env.REACT_APP_HOST_API}/users/${userIdt}`)
  //    .then((response) => {
  //       console.log("Response Jose", response);
  //       userDataT = iniData1(response.data);
  //       setItems1(userDataT);
  //       console.log("response Data", (items1));
  
  //       // Realizar el segundo get de containers después de obtener el userId
  //       console.log ( 'Checking If' );
  //       if (containerRoute) { // Solo proceder si se encontró una ruta válida
  //         console.log ( 'get containers' );
  //         return serviContainersApiCXN.get(`${process.env.REACT_APP_HOST_API}/${containerRoute}`).then((response) => {
  //           console.log ( 'Roles APP:', userDataT.rolesApp );
  //           if ( userDataT.rolesApp == 2 ){
  //             console.log ( 'Roles APP:');
  //             setItems( filterStateSeller (iniData(response.data["hydra:member"])));
  //             console.log ( filterStateSeller (iniData(response.data["hydra:member"])));
  //           }
  //           else 
  //             if ( userDataT.rolesApp == 1 ){
  //               console.log ( 'Role Operario' );
  //               console.log ( 'Country User', userDataT, userDataT.country );
  //               setItems( filterCountryOperario(iniData(response.data["hydra:member"]), userDataT.country));
  //               console.log (filterCountryOperario(iniData(response.data["hydra:member"])));
  //             }
  //             else 
  //             if (userDataT.rolesApp == 0){
  //               console.log ( 'Role Admin' );
  //               console.log ( 'containers Response', iniData(response.data["hydra:member"]) )
  //               setItems((iniData(response.data["hydra:member"])))};
  //           }
  //         );
  //       }
  //     })
     
  //    .catch(function (error) {
  //       showNotificationApp("Error cargando datos del sistema", "error");
  //       console.log(error);
  //     })
  //    .finally(function () {
  //       setLoading(false);
        
  //     });
  // }, [refreshTable]);

  const handleRefreshT = () => {
    setLoading(true);
    setRefreshTable(refreshTable === 0 ? 1 : 0);
    setSelectionTab([]);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  const MenuToolBar = () => {
    return (
      <React.Fragment>
        <Stack
          direction="row"
          justifyContent="flex-end"
          divider={<Divider orientation="vertical" />}
        >
          {/* <IconButton
            variant="text"
            aria-label="refresh"
            color="primary"
            onClick={handleRefreshT}
          >
            <Tooltip title="Actualizar Tabla" placement="top">
              <RefreshSharpIcon />
            </Tooltip>
          </IconButton>
          <IconButton
            variant="text"
            aria-label="view"
            color="primary"
            onClick={handleViewData}
            disabled={selectionTab.length !== 1}
          >
            <Tooltip title="Ver" placement="top">
              <VisibilitySharpIcon />
            </Tooltip>
          </IconButton>
          <Divider orientation="vertical" flexItem />
          {(isAccessPermission(0) || isAccessPermission(2)) && (
            <>
              <IconButton
                variant="text"
                aria-label="Devolver Contenedor"
                color="primary"
                disabled={selectionTab.length !== 1}
                onClick={handleUpdateData}
              >
                <Tooltip title="Devolver Contenedor" placement="top">
                  <LowPriorityIcon />
                </Tooltip>
              </IconButton>
            </>
          )} */}
        </Stack>
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex align-items-center justify-content-start">
      {/* <Button
        type="button"
        icon="pi-download"
        onClick={() => exportCSV(false)}
        data-pr-tooltip="CSV"
      /> */}
      <Stack
        direction="row"
        justifyContent="flex-start"
        divider={<Divider orientation="vertical" />}
      >
        <IconButton
          variant="text"
          color="primary"
          onClick={() => exportCSV(false)}
        >
          <Tooltip title="Exportar" placement="top">
            <FileDownloadIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          variant="text"
          aria-label="refresh"
          color="primary"
          onClick={handleRefreshT}
        >
          <Tooltip title="Actualizar Tabla" placement="top">
            <RefreshSharpIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          variant="text"
          aria-label="view"
          color="primary"
          onClick={handleViewData}
          disabled={!selectionTab}
        >
          <Tooltip title="Ver" placement="top">
            <VisibilitySharpIcon />
          </Tooltip>
        </IconButton>
        <Divider orientation="vertical" flexItem />
        {( rolesAppValue === 0  || rolesAppValue === 1) && (
          <>
            <IconButton
              variant="text"
              aria-label="Devolver Contenedor"
              color="primary"
              disabled={!selectionTab}
              onClick={handleUpdateData}
            >
              <Tooltip title="Devolver Contenedor" placement="top">
                <LowPriorityIcon />
              </Tooltip>
            </IconButton>
          </>
        )}
      </Stack>
    </div>
  );

  //menubar
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true }}
        />
        <MenuToolBar />
      </GridToolbarContainer>
    );
  };

  // const CustomPhotosCell = (params) => {
  //   const photos = params.value;

  //   if (Array.isArray(photos) && photos.length > 0) {
  //     return (
  //       <ul>
  //         {photos.map((photo, index) => (
  //           <li key={index}>{photo.name}</li>
  //         ))}
  //       </ul>
  //     );
  //   } else {
  //     return <span>No hay fotos disponibles</span>;
  //   }
  // };

  return (
    <React.Fragment>
      {loading ? (
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"400px"}
          animation="wave"
        />
      ) : (
        <React.Fragment>
          {/* <Box sx={{ height: "60vh", width: "100%" }}> */}
          <div className="card">
            <DataTable
              // stripedRows
              // showGridlines
              ref={dt}
              header={header}
              value={items}
              scrollable
              scrollHeight="500px"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25, 50]}
              removableSort
              dataKey="id"
              selectionMode="single"
              selection={selectionTab}
              onSelectionChange={(e) => setSelectionTab(e.value)}
              tableStyle={
                {
                  // minWidth: "50rem",
                  // color: "red",
                }
              }
              filters={filters}
              filterDisplay="row"
              size="normal"
              metaKeySelection={metaKey}
            >
              {/* <Column
                  field="name"
                  header="Nombre"
                  sortable
                  filter
                  filterPlaceholder="Search by Nombre"
                ></Column>
                <Column
                  field="description"
                  header="Descripción"
                  sortable
                  filter
                  filterPlaceholder="Search by Descripción"
                ></Column> */}
              {cols.map((col, index) => (
                <Column
                  sortable
                  filter
                  filterPlaceholder="Search"
                  key={index}
                  field={col.field}
                  header={col.header}
                  style={{ height: "50px", minWidth: "200px" }}
                />
              ))}
            </DataTable>
          </div>
          {/* </Box> */}
          <FromRentModule
            open={returnM}
            onClose={handleCloseReturnM}
            editData={
              selectionTab
              // ? items.find((elem) => elem.id === selectionTab[0])
              // : null
            }
          />
          <ViewRentModule
            open={openViewData}
            onClose={handleCloseViewData}
            viewDataRentModule={
              selectionTab
              // ? items.find((elem) => elem.id === selectionTab[0])
              // : null
            }
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
