import React from "react";
import TabConfiguration from "./TabConfiguration";
import { useContext } from "react";
import { AppContext } from "../../Providers/AppProvider";
import { getUserId, isAccessPermission } from "../../auth/security.ServiContainers";
import { ROLES, isAccess } from "../../auth/security.ServiContainers";
import { serviContainersApiCXN } from "../../auth/axios.Container";


const iniData1 = (data) => {
  console.log( 'Data completa', data);
    console.log('RolesApp', data.rolesApp);


  const ret = { obj: data };   

  return {
    rolesApp: ret.obj.rolesApp,
    country: ret.obj.areaCountry.name
  };  
};


function Home() {
  const { showNotificationApp, onLogout } = useContext(AppContext);
  const [items1, setItems1] = React.useState(0);
  const [userId, setUserId] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [refreshTable, setRefreshTable] = React.useState(0);

  React.useEffect(() => {
    const userIdt = getUserId();
    setUserId (userIdt);

    let userDataT;

    console.log ( "UserIdt", userIdt, "UserId", userId );
    console.log(userId);
  
    // Definir la ruta de contenedores basada en los roles del usuario
    const getUserContainerRoute = () => {
      const possibleRoles = [ROLES.admin.tag, ROLES.operario.tag, ROLES.vendedor.tag, ROLES.manager.tag];
  
      for (const role of possibleRoles) {
        const userRole = isAccess(role);
        if (userRole) {
          const containerRoute = `containers/?pagination=false`;
          return containerRoute;
        }
      }
      return null;
    };
  
    const containerRoute = getUserContainerRoute();
  
    // Realizar el primer get de usuario
    setLoading(true);
    serviContainersApiCXN.get(`${process.env.REACT_APP_HOST_API}/users/${userIdt}`)
     .then((response) => {
        console.log("Response Jose", response);
        userDataT = iniData1(response.data);
        setItems1(userDataT);
        console.log("response Data", (items1));
      })
     
     .catch(function (error) {
        showNotificationApp("Error cargando datos del sistema", "error");
        console.log(error);
      })
     .finally(function () {
        setLoading(false);
        
      });
  }, [refreshTable]);


  if ( items1.rolesApp === 1 || items1.rolesApp === 2 ) {
    showNotificationApp("Su usuario no tiene acceso al área solicitada. Por favor vuelva a autenticarse.", "error");
      onLogout();
      return;
  }

  return (
    <div className="configuration">
      <TabConfiguration />
    </div>
  );
}

export default Home;
