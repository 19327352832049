import * as React from "react";
import {
  // Button,
  Stack,
  // Dialog,
  // DialogTitle,
  // DialogContent,
  IconButton,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { serviContainersApiCXN } from "../../../auth/axios.Container";
import dayjs from "dayjs";
import Skeleton from "@mui/material/Skeleton";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import ViewPDFInvoiceM from "./viewPDFInvoiceMDirect";
import ViewPDFDiM from "./viewPDFDiMDirect";
import ViewPhotosContainerM from "./viewPhotosContainerM";
import { getUserRoleData } from './../../../utils/Roles';

const iniData = {
  codeModule: "",
  state: "",
  owner: "",
  model: "",
  totalCosts: "",
  // propertyContainerAsocs: "",
  place_countries: "",
  profitAsoc: "",
  weight: "",
  createAt: dayjs().add(1, "d").toDate(),
  di: "",
  invoice: "",
  factoryYear: "",
  description: "",
  invoicePdfIn: "",
  diPdfIn: "",
  photo1: "",
  photo2: "",
  photo3: "",
  photo4: "",
  photo5: "",
  priceSaleEsp: "",
  saleEspAt: dayjs().add(1, "d").toDate(),
  outputAt: dayjs().add(1, "d").toDate(),
  priceList:"",
};

export default function ViewDirectModule() {
  const [loading, setLoading] = React.useState(true);
  const [dataSt, setDataSt] = React.useState(iniData);
  const [valueCost, setValueCost] = React.useState("");
  const { id } = useParams();
  const [valueCostLength, setValueCostLength] = React.useState("");
  const [openViewInvoicePdf, setOpenViewInvoicePdf] = React.useState(false);
  const [openViewDiPdf, setOpenViewDiPdf] = React.useState(false);
  const [openViewPhotoContainer, setOpenViewPhotoContainer] =
    React.useState(false);
  const [objectImg, setObjectImg] = React.useState({
    id: "",
    photo: "",
  });
  // const [selectedPhoto, setSelectedPhoto] = React.useState(null);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [rolesAppValue, setRolesAppValue] = React.useState(null);

  const handleViewPhotoContainer = (photoName) => {
    if (!openViewPhotoContainer) {
      setOpenViewPhotoContainer(true);
    }
    setObjectImg({
      id: dataSt.id,
      photo: photoName,
    });
  };

  const handleCloseViewPhotoContainer = () => {
    setOpenViewPhotoContainer(false);
  };

  const handleViewInvoicePdf = () => {
    if (!openViewInvoicePdf) {
      setOpenViewInvoicePdf(true);
    }
  };

  const handleCloseViewInvoicePdf = () => {
    setOpenViewInvoicePdf(false);
  };

  const handleViewDiPdf = () => {
    if (!openViewDiPdf) {
      setOpenViewDiPdf(true);
    }
  };

  const handleCloseViewDiPdf = () => {
    setOpenViewDiPdf(false);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const { rolesApp, country } = await getUserRoleData();
        console.log(rolesApp, country);
        setRolesAppValue(rolesApp); // Actualizar rolesAppValue con el valor obtenido
        setDataLoaded(true); // Indicamos que los datos se han cargado
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    setLoading(true);
    serviContainersApiCXN
      .get(`${process.env.REACT_APP_HOST_API}/modules/${id}`)
      .then((response) => {
        const newPhotos = [];
        for (
          let i = 0;
          i < response.data.container.photos.length && i < 5;
          i++
        ) {
          newPhotos.push(response.data.container.photos[i]);
        }
        console.log(response.data);
        setDataSt({
          id: response.data.container.id,
          codeModule: response.data.codeModule,
          state: response.data.state.name,
          owner: response.data.container.owner.name,
          model: response.data.container.model.name,
          priceList: response.data.container.model.priceList,
          typeCostAsocContainersLength:
            response.data.container.typeCostAsocContainers.length,
          typeCostAsocContainers:
            response.data.container.typeCostAsocContainers,
          // propertyContainerAsocs:
          //   response.data.container?.propertyContainerAsocs,
          place_countries: response.data.container.placeCountry.name,
          profitAsoc: response.data.profitAsoc,
          weight: response.data.container.weight,
          description: response.data.container.description,
          createAt: response.data.createAt,
          factoryYear: response.data.container.factoryYear,
          di: response.data.container.di,
          invoice: response.data.container.invoice,
          pdfInvoice: response.data.container.invoicePdf,
          pdfDi: response.data.container.diPdf,
          priceSaleEsp: response.data.container.priceSaleEsp,
          photosN: response.data.container.photos,
          saleEspAt: new Date(response.data.container.saleEspAt),
          outputAt: new Date(response.data.container.outputAt),
          ...newPhotos.reduce((acc, photos, index) => {
            acc[`photo${index + 1}`] = photos;
            return acc;
          }, {}),
        });
        setValueCost(response.data.container.typeCostAsocContainers);
        setValueCostLength(
          response.data.container.typeCostAsocContainers.length
        );
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoading(false);
      });
  }, [dataLoaded]);
  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          maxWidth: 900,
          background: "#fbfbfb",
          transform: "translateY(-60px)",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            color: "#fff",
            backgroundColor: "#004590",
            textAlign: "center",
          }}
        >
          Vista del Módulo
        </Typography>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack
              direction="column"
              spacing={2}
              alignItems="initial"
              sx={{ m: 3 }}
            >
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
            </Stack>

            <Stack
              direction="column"
              spacing={2}
              alignItems="initial"
              sx={{ m: 3 }}
            >
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
            </Stack>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack
              direction="column"
              spacing={2}
              alignItems="initial"
              sx={{ m: 3 }}
            >
              <Typography>
                <strong>Código: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.codeModule}
                </Box>
              </Typography>

              <Typography>
                <strong>Estado: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.state}
                </Box>
              </Typography>
              <Typography>
                <strong>DI: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.di}
                </Box>
                {dataSt.pdfDi ? (
                  <IconButton
                    variant="text"
                    color="primary"
                    onClick={() => handleViewDiPdf()}
                  >
                    <Tooltip title="Ver" placement="top">
                      <VisibilitySharpIcon />
                    </Tooltip>
                  </IconButton>
                ) : (
                  ": No hay pdf asociado a este DI"
                )}
              </Typography>
              <Typography>
                <strong>Factura</strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.invoice}
                </Box>
                {dataSt.pdfInvoice ? (
                  <IconButton
                    variant="text"
                    color="primary"
                    onClick={() => handleViewInvoicePdf()}
                  >
                    <Tooltip title="Ver" placement="top">
                      <VisibilitySharpIcon />
                    </Tooltip>
                  </IconButton>
                ) : (
                  ": No hay pdf asociado a esta Factura"
                )}
              </Typography>
              <Typography>
                <strong>Propietarios: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.owner}
                </Box>
              </Typography>

              <Typography>
                <strong>Modelo: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.model}
                </Box>
              </Typography>

              {/* <Typography>
              <strong>Características Contenedor: </strong>
              {dataSt.propertyContainerAsocs.length === 0 ? (
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  No hay Características asociadas
                </Box>
              ) : (
                dataSt.propertyContainerAsocs.map((item, index) => (
                  <Box
                    key={index}
                    component={"span"}
                    sx={{
                      backgroundColor: "#ebebeb",
                      borderRadius: "12px",
                      textAlign: "center",
                      padding: "8px",
                    }}
                  >
                    {item.propertyContainer.name}
                  </Box>
                ))
              )}
            </Typography> */}
              <Typography>
                <strong>Precio de Venta: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.priceSaleEsp}
                </Box>
              </Typography>
              <Typography>
                <strong>Fecha de Venta: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.saleEspAt.toDateString()}
                </Box>
              </Typography>

              <Typography>
                <strong>Fecha de Egreso: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.outputAt.toDateString()}
                </Box>
              </Typography>
              <Typography>
                <strong>Observaciones: </strong>

                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    paddingX: "10px",
                    paddingY: "8px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    marginRight: "2px",
                    display: "inline-block",
                  }}
                >
                  {dataSt.description === ""
                    ? "No hay Observaciones"
                    : dataSt.description}
                </Box>
              </Typography>
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              alignItems="initial"
              sx={{ m: 3 }}
            >
              <Typography>
                <strong>Precio de Lista: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.priceList}
                </Box>
                </Typography>
                {rolesAppValue!== 2 && (
                <>
              <Typography>
                <strong>Costos Asociados: </strong>
                {valueCostLength === 0 ? (
                  <Box
                    component={"span"}
                    sx={{
                      backgroundColor: "#ebebeb",
                      borderRadius: "12px",
                      textAlign: "center",
                      padding: "8px",
                    }}
                  >
                    No hay costos asociados
                  </Box>
                ) : (
                  <Box
                    component={"span"}
                    sx={{
                      backgroundColor: "#ebebeb",
                      borderRadius: "12px",
                      textAlign: "center",
                      padding: "8px",
                    }}
                  >
                    {valueCost.reduce(
                      (total, item) => total + item.valueCost,
                      0
                    )}
                  </Box>
                )}
              </Typography>
              </>
              )}
              <Typography>
                <strong>Lugar: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.place_countries}
                </Box>
              </Typography>
              <Typography>
                <strong>Margen Ganancial: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.profitAsoc}
                </Box>
              </Typography>

              <Typography>
                <strong>Peso: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.weight}
                </Box>
              </Typography>
              <Typography>
                <strong>Fecha Internación: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.createAt.toString()}
                </Box>
              </Typography>
              <Typography>
                <strong>Año de fabricación: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.factoryYear}
                </Box>
              </Typography>
              <Stack
                direction="column"
                spacing={2}
                alignItems="initial"
                sx={{ m: 3 }}
              >
                {dataSt.photosN &&
                  dataSt.photosN.map((photo, index) => (
                    <div key={index}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Typography>
                          <strong>Foto {index + 1}: </strong>
                        </Typography>
                        <Box
                          component={"span"}
                          sx={{
                            backgroundColor: "#ebebeb",
                            borderRadius: "12px",
                            textAlign: "center",
                            padding: "8px",
                          }}
                        >
                          <IconButton
                            variant="text"
                            aria-label={`view-photo`}
                            color="primary"
                            onClick={() => handleViewPhotoContainer(photo)}
                          >
                            <Tooltip title="Ver" placement="top">
                              <VisibilitySharpIcon />
                            </Tooltip>
                          </IconButton>
                        </Box>
                      </Box>
                    </div>
                  ))}
              </Stack>
            </Stack>
          </Box>
        )}
      </Box>
      {dataSt.pdfInvoice && (
        <ViewPDFInvoiceM
          open={openViewInvoicePdf}
          onClose={handleCloseViewInvoicePdf}
          viewDataModule={dataSt}
        />
      )}
      {dataSt.pdfDi && (
        <ViewPDFDiM
          open={openViewDiPdf}
          onClose={handleCloseViewDiPdf}
          viewDataModule={dataSt}
        />
      )}

      {objectImg.id !== "" ? (
        <ViewPhotosContainerM
          open={openViewPhotoContainer}
          onClose={handleCloseViewPhotoContainer}
          viewDataModule={objectImg}
        />
      ) : null}
    </React.Fragment>
  );
}
