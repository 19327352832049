import * as React from 'react';
import { TextField, Stack, Box, Alert, Fade } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import LOGO_CONTAINERS from '../../img/whitelogo.jpeg'
import AccountCircle from '@mui/icons-material/AccountCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { serviContainersApiCXN } from '../axios.Container'
import { clearStorage, setJWTFromStorage } from '../localStorage.Container';
import { useContext } from 'react';
import { AppContext } from '../../Providers/AppProvider';

const iniData = {
  user: "",
  pass: "",
  showPassword: false,
};

const iniDataValid = {
  user: { error: false, errorText: "" },
  pass: { error: false, errorText: "" },
};

export default function Login() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dataSt, setDataSt] = React.useState(iniData);
  const [dataValid, setDataValid] = React.useState(iniDataValid);
  const [errorLogin, setErrorLogin] = React.useState();

  const { onLogin } = useContext(AppContext);

  const handleUserName = (e) => {
    if (e.target.value === '') {
      setDataValid({
        ...dataValid,
        user: { error: true, errorText: 'Campo obligatorio' }
      })
    } else {
      setDataValid({
        ...dataValid,
        user: { error: false, errorText: '' }
      })
    }
    setDataSt({
      ...dataSt,
      user: e.target.value
    });
  };

  const handlePassword = (e) => {
    if (e.target.value === '') {
      setDataValid({
        ...dataValid,
        pass: { error: true, errorText: 'Campo obligatorio' }
      })
    } else {
      setDataValid({
        ...dataValid,
        pass: { error: false, errorText: '' }
      })
    }
    setDataSt({
      ...dataSt,
      pass: e.target.value
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = async () => {
    setLoading(true);
    const body = {
      username: dataSt.user,
      password: dataSt.pass
    }

    try {
      const response = await serviContainersApiCXN.post(`/auth`, JSON.stringify(body));
      clearStorage();
      if (!setJWTFromStorage(response.data?.token)) throw new Error("No se ha podido guardar los datos de sesión.");
      onLogin();
    } catch (error) {
      console.log(error);
      setErrorLogin("Credenciales no válidas.");
    };
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Box
        width={"100%"}
        height={"100vh"}
        sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText', pt: "15vh" }}
      >
        <Box backgroundColor="white" sx={{ width: { xs: "95%", md: "45%" }, p: "10px", mx: "auto", boxShadow: 4, borderRadius: "15px" }}>
          <Box
            sx={{ height: 'auto', display: "flex", flexDirection: "column", gap: "5px" }}>
            <Stack direction='row' spacing={2} display='flex' alignItems='center' justifyContent='center'>
              <Box
                component='img'
                sx={{
                  height: "auto",
                  width: "80%",
                  my: 2,
                }}
                alt="CONTAINER_IMG"
                src={LOGO_CONTAINERS}
                loading='lazy'
              />
            </Stack>
            <Stack direction='row' alignItems="center" spacing={2} sx={{ mx: { xs: '2%', md: "20%" } }}>
              <AccountCircle sx={{ mr: 1, my: 0.5, color: '#1976d2', flexGrow: "0" }} />
              <TextField
                id="usuario"
                label={`Nombre de Usuario`}
                type='text'
                value={dataSt.user}
                onChange={handleUserName}
                error={dataValid.user.error}
                helperText={dataValid.user.errorText || " "}
                autoComplete='false'
                sx={{ flexGrow: "1", minWidth: '235px' }}
                required
                variant="standard" />
            </Stack>
            <Stack direction='row' alignItems="center" spacing={2} sx={{ mx: { xs: '2%', md: "20%" } }}>
              {showPassword ? (
                <VisibilityOff
                  onClick={handleClickShowPassword}
                  sx={{ color: '#1976d2', flexGrow: "0" }}
                />
              ) : (
                <Visibility
                  onClick={handleClickShowPassword}
                  sx={{ color: '#1976d2', flexGrow: "0" }}
                />
              )}
              <TextField
                id="contraseña"
                label={`Contraseña`}
                value={dataSt.pass}
                onChange={handlePassword}
                type={showPassword ? 'text' : 'password'}
                error={dataValid.pass.error}
                helperText={dataValid.pass.errorText || " "}
                autoComplete='false'
                sx={{ flexGrow: "1", minWidth: '235px' }}
                required
                variant="standard" />
            </Stack>
            {errorLogin && (
              <Fade in={errorLogin && true}>
                <Alert sx={{ mx: { xs: '2%', md: "20%" } }} severity="error">{errorLogin}</Alert>
              </Fade>
            )}
          </Box>
          <Box display="flex" justifyContent="center" sx={{ my: "30px" }}>
            <LoadingButton variant='contained'
              sx={{ borderRadius: 0 }}
              color='primary'
              loading={loading}
              disabled={dataSt.user === "" || dataSt.pass === ""}
              onClick={handleSubmit}
            >iniciar sesión</LoadingButton>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
