import React from "react";
import "../Header/Header.css";
import Logo1 from "../../img/whitelogo.jpeg";

function Header() {
  return (
    <div className="logo">
      <img className="img" src={Logo1} alt="Imagen" />
    </div>
  );
}

export default Header;
