import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../../Providers/AppProvider";
import { useContext } from "react";
import { serviContainersApiCXN } from "../../../auth/axios.Container";
import { MuiFileInput } from "mui-file-input";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Switch } from "@mui/material";

const iniPhotosValid = {
  error: false,
  text: "Imágenes de hasta 5Mb (.jpg/.png)",
};

const iniPdfValifBill = {
  error: false,
  text: "Añadir pdf asociado a factura (.pdf)",
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(window.btoa(reader.result));
    reader.onerror = reject;
  });

// const pdfToBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => {
//       const base64String = reader.result.split(",")[1];
//       resolve(base64String);
//     };
//     reader.onerror = reject;
//   });

const iniData = {
  price: "",
  profitAsoc: "",
  clientName: "",
  clientID: "",
  bill: "",
  goTransport: true,
  returnTransport: true,
};
const iniDataValid = {
  price: { error: false, errorText: "" },
  profitAsoc: { error: false, errorText: "" },
  clientName: { error: false, errorText: "" },
  clientID: { error: false, errorText: "" },
  bill: { error: false, errorText: "" },
};

export default function FormElements({ open, onClose, viewData }) {
  const [dataSt, setDataSt] = React.useState(iniData);
  const [dataValid, setDataValid] = React.useState(iniDataValid);
  const [loading, setLoading] = React.useState(false);
  const { showNotificationApp } = useContext(AppContext);
  const [photos, setPhotos] = React.useState(null);
  const [photosValid, setPhotosValid] = React.useState(iniPhotosValid);
  // const [pdfInvoice, setPdfInvoice] = React.useState(null);
  // const [pdfValidInvoice, setPdfValidInvoice] =
  //   React.useState(iniPdfValidInvoice);
  const [pdfBill, setPdfBill] = React.useState(null);
  const [pdfValifBill, setPdfValifBill] = React.useState(iniPdfValifBill);

  const handleClose = () => {
    setDataSt(iniData);
    setDataValid(iniDataValid);
    setLoading(false);
    onClose();
  };
  const handleGoTransport = (event) => {
    setDataSt({
      ...dataSt,
      goTransport: event.target.checked,
    });
  };
  const handleReturnTransport = (event) => {
    setDataSt({
      ...dataSt,
      returnTransport: event.target.checked,
    });
  };

  const handlePdfBill = (value) => {
    let valid;
    if (!valid) setPdfValifBill(iniPdfValifBill);
    else setPdfValifBill({ ...valid });
    setPdfBill(value);
  };

  const handlePhotos = (value) => {
    let valid;
    // value.forEach((file) => {
    //   if (!valid) {
    //     if (file.type !== "image/jpeg" && file.type !== "image/png") {
    //       valid = { error: true, text: "Formato no válido de imagen." };
    //     }
    //     if (file.size > 5000000) {
    //       valid = valid
    //         ? { error: true, text: valid.text + " Hay imágenes de más de 5Mb." }
    //         : { error: true, text: "Hay imágenes de más de 5Mb." };
    //     }
    //   }
    // });

    if (!valid) setPhotosValid(iniPhotosValid);
    else setPhotosValid({ ...valid });
    setPhotos(value);
  };

  const handlePrice = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        price: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        price: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      price: event.target.value,
    });
  };

  const handleProfitAsoc = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        profitAsoc: {
          error: true,
          errorText: "Este campo es obligatorio.",
        },
      });
    } else {
      setDataValid({
        ...dataValid,
        profitAsoc: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      profitAsoc: event.target.value,
    });
  };

  const handleClientName = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        clientName: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        clientName: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      clientName: event.target.value,
    });
  };

  const handleClientID = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        clientID: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        clientID: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      clientID: event.target.value,
    });
  };

  const handleBill = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        bill: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        bill: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      bill: event.target.value,
    });
  };

  const handleSubmit = async () => {
    let valid = { ...dataValid };
    let fail = false;
    setLoading(true);

    let list = [];
    if (photos !== null) {
      await Promise.all(photos.map((file) => toBase64(file))).then(
        (listPhotos) => (list = listPhotos)
      );
    }
    let billPdfIn = "";
    if (pdfBill !== null) {
      const file = pdfBill;
      await toBase64(file).then((base64) => (billPdfIn = base64));
    }
    if (dataValid.price.error) fail = true;
    if (dataValid.profitAsoc.error) fail = true;
    if (dataValid.clientName.error) fail = true;
    if (dataValid.clientID.error) fail = true;
    if (dataValid.bill.error) fail = true;

    if (dataSt.price === "") {
      fail = true;
      valid = {
        ...valid,
        price: { error: true, errorText: "Este campo es obligatorio." },
      };
    }

    if (dataSt.profitAsoc === "") {
      fail = true;
      valid = {
        ...valid,
        profitAsoc: { error: true, errorText: "Este campo es obligatorio." },
      };
    }

    if (dataSt.clientName === "") {
      fail = true;
      valid = {
        ...valid,
        clientName: { error: true, errorText: "Este campo es obligatorio." },
      };
    }

    if (dataSt.clientID === "") {
      fail = true;
      valid = {
        ...valid,
        clientID: {
          error: true,
          errorText: "Este campo es obligatorio.",
        },
      };
    }

    if (dataSt.bill === "") {
      fail = true;
      valid = {
        ...valid,
        bill: { error: true, errorText: "Este campo es obligatorio." },
      };
    }

    if (!fail) {
      let newSale = new Object();
      newSale.module = `/api/modules/${viewData.id}`;
      newSale.price = parseFloat(dataSt.price);
      newSale.profitAsoc = parseFloat(dataSt.profitAsoc) / 100;
      newSale.clientName = dataSt.clientName;
      newSale.clientID = dataSt.clientID;
      newSale.bill = dataSt.bill;
      newSale.listPhotos = list;
      newSale.billPdfIn = billPdfIn;
      newSale.goTransport = dataSt.goTransport;
      newSale.returnTransport = dataSt.returnTransport;
      console.log("Datos enviados a la API:", newSale);
      serviContainersApiCXN
        .post(
          `${process.env.REACT_APP_HOST_API}/sale_containers/module`,
          newSale
        )
        .then((response) => {
          showNotificationApp(
            "Se ha efectuado la venta correctamente",
            "success"
          );
          onClose();
        })
        .catch((error) => {
          showNotificationApp(
            "Ha ocurrido un error mientras se guardan los datos",
            "error"
          );
          console.log(error);
        })
        .finally(() => setLoading(false));
    } else {
      setDataValid({ ...valid });
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }

    console.log(viewData);
    setDataSt({
      ...iniData,
      profitAsoc: viewData?.obj?.profitAsoc * 100,
    });
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        scroll="paper"
        color="primary"
      >
        <DialogTitle className="dialog-title">
          {`Vender Módulo`}
          <Tooltip title="Cerrar" placement="top">
            <IconButton
              aria-label="cerrar-form-data-client-sale"
              disabled={loading}
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers sx={{ maxHeight: "305h", minHeight: "35vh" }}>
          <Stack
            direction="column"
            spacing={2}
            alignItems="initial"
            alignContent="center"
            sx={{ mb: "1vh" }}
          >
            <TextField
              variant="standard"
              label={`Precio`}
              required
              type="number"
              disabled={loading}
              value={dataSt.price}
              onChange={handlePrice}
              autoComplete="false"
              error={dataValid.price.error}
              helperText={dataValid.price.errorText}
              sx={{ minWidth: "120px", width: "400px" }}
            />
            <TextField
              variant="standard"
              label={`Ganancia Asociada en %`}
              required
              type="number"
              disabled={loading}
              value={dataSt.profitAsoc}
              onChange={handleProfitAsoc}
              autoComplete="false"
              error={dataValid.profitAsoc.error}
              helperText={dataValid.profitAsoc.errorText}
              sx={{ minWidth: "120px", width: "400px" }}
            />

            <TextField
              variant="standard"
              label={`Nombre del Cliente`}
              required
              type="text"
              disabled={loading}
              value={dataSt.clientName}
              onChange={handleClientName}
              autoComplete="false"
              error={dataValid.clientName.error}
              helperText={dataValid.clientName.errorText}
              sx={{ minWidth: "120px", width: "400px" }}
            />
            <TextField
              variant="standard"
              label={`ID del Cliente`}
              required
              type="text"
              disabled={loading}
              value={dataSt.clientID}
              onChange={handleClientID}
              autoComplete="false"
              error={dataValid.clientID.error}
              helperText={dataValid.clientID.errorText}
              sx={{ minWidth: "120px", width: "400px" }}
            />
            <TextField
              variant="standard"
              label={`Factura`}
              required
              type="text"
              disabled={loading}
              value={dataSt.bill}
              onChange={handleBill}
              autoComplete="false"
              error={dataValid.bill.error}
              helperText={dataValid.bill.errorText}
              sx={{ minWidth: "120px", width: "400px" }}
            />
            <MuiFileInput
              fullWidth={true}
              variant="standard"
              inputProps={{ accept: ".pdf" }}
              value={pdfBill}
              onChange={handlePdfBill}
              disabled={loading}
              error={pdfValifBill.error}
              helperText={pdfValifBill.text}
            />
            <p>Añadir fotos del contenedor</p>
            <MuiFileInput
              variant="standard"
              multiple
              inputProps={{
                accept: "image/png, image/jpeg",
              }}
              value={photos}
              onChange={handlePhotos}
              disabled={loading}
              error={photosValid.error}
              helperText={photosValid.text}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={dataSt.goTransport}
                  onChange={handleGoTransport}
                  inputProps={{ "aria-label": "controlled" }}
                  label="Label"
                />
              }
              label="Transporte de ida pagado"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={dataSt.returnTransport}
                  onChange={handleReturnTransport}
                  inputProps={{ "aria-label": "controlled" }}
                  label="Label"
                />
              }
              label="Transporte de regreso pagado"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ borderRadius: 0 }}
            color="inherit"
            disabled={loading}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{
              borderRadius: 0,
              background: "#212544",
            }}
            loading={loading}
            onClick={handleSubmit}
          >
            Aceptar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
