import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { serviContainersApiCXN } from "../../../auth/axios.Container";
import { useContext } from "react";
import { AppContext } from "../../../Providers/AppProvider";

export default function DoughnutChartDemo() {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [items, setItems] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const { showNotificationApp } = useContext(AppContext);

    const iniData = (data) => {
        let ret = {};
        data.forEach((element) => {
            const countryName = element.container?.placeCountry?.name;
            if (countryName in ret) {
                ret[countryName]++;
            } else {
                ret[countryName] = 1;
            }
        });
        return ret;
    };

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        setLoading(true);
        serviContainersApiCXN
          .get(`${process.env.REACT_APP_HOST_API}/modules?pagination=false`)
          .then((response) => {
            const containerData = iniData(response.data["hydra:member"]);
            const data = {
                labels: Object.keys(containerData),
                datasets: [
                    {
                        data: Object.values(containerData),
                        backgroundColor: [
                            documentStyle.getPropertyValue('--blue-500'), 
                            documentStyle.getPropertyValue('--yellow-500'), 
                            documentStyle.getPropertyValue('--green-500'),
                            documentStyle.getPropertyValue('--cyan-500'),
                            documentStyle.getPropertyValue('--red-500'),
                            documentStyle.getPropertyValue('--purple-500'),
                            documentStyle.getPropertyValue('--orange-500'),
                            documentStyle.getPropertyValue('--violet-500'),
                            documentStyle.getPropertyValue('--yellow-500'),
                            documentStyle.getPropertyValue('--green-500'),
                            // Agrega más colores si tienes más tipos de contenedores
                        ],
                        hoverBackgroundColor: [
                            documentStyle.getPropertyValue('--blue-400'), 
                            documentStyle.getPropertyValue('--yellow-400'), 
                            documentStyle.getPropertyValue('--green-400'),
                            documentStyle.getPropertyValue('--cyan-400'),
                            documentStyle.getPropertyValue('--red-400'),
                            documentStyle.getPropertyValue('--purple-400'),
                            documentStyle.getPropertyValue('--orange-400'),
                            documentStyle.getPropertyValue('--violet-400'),
                            documentStyle.getPropertyValue('--yellow-400'),
                            documentStyle.getPropertyValue('--green-400'),
                            // Agrega más colores si tienes más tipos de contenedores
                        ]
                }
            ]
        };
        const options = {
            cutout: '60%'
        };

        setChartData(data);
        setChartOptions(options);
    }).catch(function (error) {
        console.error('Error fetching data:', error);
        showNotificationApp("Error cargando datos del sistema", "error");
      })
      .finally(function () {
        setLoading(false);
      });
  }, [showNotificationApp]);

    return (
        <div className="card flex justify-content-center">
            <Chart style={{width:"400px"}}  type="doughnut" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
        </div>
    )
}

        