import * as React from "react";
import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { QRCodeCanvas } from "qrcode.react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import dayjs from "dayjs";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import ViewPDFInvoice from "./viewPDFInvoice";
import ViewPDFDi from "./ViewPDFDi";
import ViewPhotosContainer from "./viewPhotosContainer";
import { getUserRoleData } from './../../../utils/Roles';

const iniData = {
  id: "",
  codeExt: "",
  codeInt: "",
  model: "",
  priceList:"",
  provider: "",
  placeCountries: "",
  typeModeCatch: "",
  profitAsoc: "",
  state: "",
  owner: "",
  weight: 0,
  propertyContainerAsocs: [],
  factoryYear: dayjs().add(1, "d").toDate(),
  description: "",
  tacCode: "",
  typeCostAsocContainers: [],
  createAt: dayjs().add(1, "d").toDate(),
  invoice: "",
  di: "",
  totalCost: "",
  invoicePdfIn: "",
  diPdfIn: "",
  photo1: "",
  photo2: "",
  photo3: "",
  photo4: "",
  photo5: "",
  priceSaleEsp: "",
  saleEspAt: dayjs().add(1, "d").toDate(),
  outputAt: dayjs().add(1, "d").toDate(),
};

export default function ViewContainer({ open, onClose, viewDataContainer }) {
  const [dataSt, setDataSt] = React.useState(iniData);
  console.log("RESDULTADO", viewDataContainer);
  const [loading, setLoading] = React.useState(false);
  const [openViewInvoicePdf, setOpenViewInvoicePdf] = React.useState(false);
  const [openViewDiPdf, setOpenViewDiPdf] = React.useState(false);
  const [openViewPhotoContainer, setOpenViewPhotoContainer] =
    React.useState(false);
  const [objectImg, setObjectImg] = React.useState({
    id: "",
    photo: "",
  });
  const [selectedPhoto, setSelectedPhoto] = React.useState(null);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [rolesAppValue, setRolesAppValue] = React.useState(null);

  const handleClose = () => {
    setDataSt(iniData);
    setLoading(false);
    onClose();
  };

  const handleViewPhotoContainer = (photoName) => {
    if (!openViewPhotoContainer) {
      setOpenViewPhotoContainer(true);
    }
    setObjectImg({
      id: viewDataContainer.id,
      photo: photoName,
    });
  };

  const handleCloseViewPhotoContainer = () => {
    setOpenViewPhotoContainer(false);
  };

  const handleViewInvoicePdf = () => {
    if (!openViewInvoicePdf) {
      setOpenViewInvoicePdf(true);
    }
  };

  const handleCloseViewInvoicePdf = () => {
    setOpenViewInvoicePdf(false);
  };

  const handleViewDiPdf = () => {
    if (!openViewDiPdf) {
      setOpenViewDiPdf(true);
    }
  };

  const handleCloseViewDiPdf = () => {
    setOpenViewDiPdf(false);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const { rolesApp, country } = await getUserRoleData();
        console.log(rolesApp, country);
        setRolesAppValue(rolesApp); // Actualizar rolesAppValue con el valor obtenido
        setDataLoaded(true); // Indicamos que los datos se han cargado
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    fetchData();
  }, []);
  

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }
    const newPhotos = [];
    for (let i = 0; i < viewDataContainer.obj.photos.length && i < 5; i++) {
      newPhotos.push(viewDataContainer.obj.photos[i]);
    }

    console.log(viewDataContainer);
    setDataSt({
      codeExt: viewDataContainer.codeExt,
      codeInt: viewDataContainer.codeInt,
      typeModeCatch: viewDataContainer.typeModeCath,
      profitAsoc: viewDataContainer.profitAsoc,
      provider: viewDataContainer.provider,
      placeCountries: viewDataContainer.place_countries,
      state: viewDataContainer.state,
      model: viewDataContainer.model,
      priceList: viewDataContainer.obj?.model.priceList,
      propertyContainerAsocs: viewDataContainer.propertyContainerAsocs,
      owner: viewDataContainer.obj?.owner.name,
      weight: viewDataContainer.obj?.weight,
      factoryYear: dayjs().year(viewDataContainer.factoryYear),
      tacCode: viewDataContainer.tacCode,
      description: viewDataContainer.description,
      typeCostAsocContainers: viewDataContainer.typeCostAsocContainers,
      createAt: viewDataContainer.createAt,
      invoice: viewDataContainer.invoice,
      di: viewDataContainer.di,
      totalCosts: viewDataContainer.totalCosts,
      invoicePdf: viewDataContainer.obj.invoicePdf,
      id: viewDataContainer.id,
      pdfInvoice: viewDataContainer.obj.invoicePdf,
      pdfDi: viewDataContainer.obj.diPdf,
      priceSaleEsp: viewDataContainer.priceSaleEsp,
      saleEspAt: new Date(viewDataContainer.obj.saleEspAt),

      outputAt: new Date(viewDataContainer.obj.outputAt),
      // photo: viewDataContainer.obj.photos,
      ...newPhotos.reduce((acc, photos, index) => {
        acc[`photo${index + 1}`] = photos;
        return acc;
      }, {}),
    });
  }, [open, dataLoaded]);

  if (!open) {
    return undefined;
  }
  console.log(objectImg);
  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL);
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = `QR_Code${viewDataContainer.id}.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  

  return (
    <React.Fragment>
       <Dialog open={open} fullWidth maxWidth="sm" scroll="paper" color="primary">
    <DialogTitle className="dialog-title" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {`Vista del Contenedor `}
          <div style={{ marginLeft: 'auto', fontSize: '1rem', fontWeight: 'bold' }}>
          <Typography>
                <strong>Precio de lista: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.priceList}
                </Box>
              </Typography>
      </div>
          <Tooltip title="Cerrar" placement="top">
            <IconButton
              aria-label="cerrar-form-data-client-sale"
              disabled={loading}
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers>
          <>
            <Stack
              direction="column"
              spacing={2}
              alignItems="initial"
              sx={{ m: 3 }}
            >
              <Typography>
                <strong>Código Externo: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.codeExt}
                </Box>
              </Typography>

              <Typography>
                <strong>Código Interno: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.codeInt}
                </Box>
              </Typography>

              <Typography>
                <strong>Proveedor: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.provider}
                </Box>
              </Typography>

              <Typography>
                <strong>Lugar: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.placeCountries}
                </Box>
              </Typography>

              <Typography>
                <strong>Estado del Contenedor: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.state}
                </Box>
              </Typography>

              <Typography>
                <strong>Tipo de Contenedor: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.model}
                </Box>
              </Typography>
            </Stack>

            <Stack
              direction="column"
              spacing={2}
              alignItems="initial"
              sx={{ m: 3 }}
            >
              <Typography>
                <strong>Propietario: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.owner}
                </Box>
              </Typography>

              <Typography>
                <strong>Peso: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.weight}
                </Box>
              </Typography>
              <Typography>
                <strong>Ganancia Asociada: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.profitAsoc}
                </Box>
              </Typography>
              <Typography>
                <strong>Factura: </strong>

                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.invoice}
                </Box>
                {dataSt.pdfInvoice ? (
                  <IconButton
                    variant="text"
                    color="primary"
                    onClick={() => handleViewInvoicePdf()}
                  >
                    <Tooltip title="Ver" placement="top">
                      <VisibilitySharpIcon />
                    </Tooltip>
                  </IconButton>
                ) : (
                  ": No hay pdf asociado a esta Factura"
                )}
              </Typography>
              <Typography>
                <strong>DI: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.di}
                </Box>
                {dataSt.pdfDi ? (
                  <IconButton
                    variant="text"
                    color="primary"
                    onClick={() => handleViewDiPdf()}
                  >
                    <Tooltip title="Ver" placement="top">
                      <VisibilitySharpIcon />
                    </Tooltip>
                  </IconButton>
                ) : (
                  ": No hay pdf asociado a este DI"
                )}
              </Typography>

              <Typography>
                <strong>TATC: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.tacCode}
                </Box>
              </Typography>
              <Typography>
                <strong>Precio de Venta: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.priceSaleEsp}
                </Box>
              </Typography>

              <Typography>
                <strong>Año de Fabricación: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.factoryYear["$y"]}
                </Box>
              </Typography>

              <Typography>
                <strong>Fecha de Ingreso del Contenedor: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.createAt.toString()}
                </Box>
              </Typography>

              <Typography>
                <strong>Fecha de Venta: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.saleEspAt.toDateString()}
                </Box>
              </Typography>

              <Typography>
                <strong>Fecha de Egreso: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.outputAt.toDateString()}
                </Box>
              </Typography>

              <Typography>
                <strong>Observaciones: </strong>

                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    paddingX: "10px",
                    paddingY: "8px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    marginRight: "2px",
                    display: "inline-block",
                  }}
                >
                  {dataSt.description === ""
                    ? "No hay Observaciones"
                    : dataSt.description}
                </Box>
              </Typography>

              <Typography>
                <strong>Características Contenedor: </strong>
                {dataSt.propertyContainerAsocs.length === 0 ? (
                  <Box
                    component={"span"}
                    sx={{
                      backgroundColor: "#ebebeb",
                      borderRadius: "12px",
                      textAlign: "center",
                      padding: "8px",
                    }}
                  >
                    No hay Características asociadas
                  </Box>
                ) : (
                  dataSt.propertyContainerAsocs.map((item, index) => (
                    <Box
                      key={index}
                      component={"span"}
                      sx={{
                        backgroundColor: "#ebebeb",
                        borderRadius: "12px",
                        textAlign: "center",
                        padding: "8px",
                      }}
                    >
                      {item.propertyContainer.name}
                    </Box>
                  ))
                )}
              </Typography>
              {rolesAppValue!== 2 && (
                <>
              <Typography>
                <strong>Total de costos: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.totalCosts}
                </Box>
              </Typography>
              
              <Typography>
                <strong>Costos Asociados: </strong>
                {dataSt.typeCostAsocContainers.length === 0 ? (
                  <Box
                    component={"span"}
                    sx={{
                      backgroundColor: "#ebebeb",
                      borderRadius: "12px",
                      textAlign: "center",
                      padding: "8px",
                    }}
                  >
                    No hay costos asociados
                  </Box>
                ) : (
                  dataSt.typeCostAsocContainers.map((item, index) => (
                    <Box
                      key={index}
                      component={"span"}
                      sx={{
                        backgroundColor: "#ebebeb",
                        borderRadius: "12px",
                        textAlign: "center",
                        padding: "8px",
                      }}
                    >
                      {item.typeCost.name}
                    </Box>
                  ))
                )}
              </Typography>
              </>
              )}
              <Stack
                direction="column"
                spacing={2}
                alignItems="initial"
                sx={{ m: 3 }}
              >
                {viewDataContainer.obj.photos &&
                  viewDataContainer.obj.photos.map((photo, index) => (
                    <div key={index}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Typography>
                          <strong>Foto {index + 1}: </strong>
                        </Typography>
                        <Box
                          component={"span"}
                          sx={{
                            backgroundColor: "#ebebeb",
                            borderRadius: "12px",
                            textAlign: "center",
                            padding: "8px",
                          }}
                        >
                          <IconButton
                            variant="text"
                            aria-label={`view-photo`}
                            color="primary"
                            onClick={() => handleViewPhotoContainer(photo)}
                          >
                            <Tooltip title="Ver" placement="top">
                              <VisibilitySharpIcon />
                            </Tooltip>
                          </IconButton>
                        </Box>
                      </Box>
                    </div>
                  ))}
              </Stack>

              {/* <Stack
                direction="column"
                spacing={2}
                alignItems="initial"
                sx={{ m: 3 }}
              >
                {viewDataContainer.obj.photos &&
                  viewDataContainer.obj.photos.map((photo, index) => (
                    <div key={index}>
                      <Typography>
                        <strong>Foto: </strong>
                      </Typography>
                      <Box
                        component={"span"}
                        sx={{
                          backgroundColor: "#ebebeb",
                          borderRadius: "12px",
                          textAlign: "center",
                          padding: "8px",
                        }}
                      >
                        <IconButton
                          variant="text"
                          aria-label={`view-photo`}
                          color="primary"
                          onClick={() => handleViewPhotoContainer(photo)}
                        >
                          <Tooltip title="Ver" placement="top">
                            <VisibilitySharpIcon />
                          </Tooltip>
                        </IconButton>
                      </Box>
                    </div>
                  ))}
              </Stack> */}
            </Stack>

            <Stack
              direction="row"
              spacing={3}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ my: "2vh" }}
            >
              <QRCodeCanvas
                value={`${process.env.REACT_APP_HOST_FRONT}/contenedor/view/${viewDataContainer.id}`}
                id="qrCodeEl"
              />
            </Stack>

            <Stack
              direction="row"
              spacing={3}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                variant="outlined"
                startIcon={<CloudDownloadIcon />}
                sx={{
                  color: "#fff",
                  backgroundColor: "#004590",
                }}
                onClick={downloadQRCode}
              >
                Descargar Código QR
              </Button>
            </Stack>
          </>
        </DialogContent>
      </Dialog>
      {dataSt.pdfInvoice && (
        <ViewPDFInvoice
          open={openViewInvoicePdf}
          onClose={handleCloseViewInvoicePdf}
          viewDataContainer={viewDataContainer}
        />
      )}
      {dataSt.pdfDi && (
        <ViewPDFDi
          open={openViewDiPdf}
          onClose={handleCloseViewDiPdf}
          viewDataContainer={viewDataContainer}
        />
      )}

      {objectImg.id !== "" ? (
        <ViewPhotosContainer
          open={openViewPhotoContainer}
          onClose={handleCloseViewPhotoContainer}
          viewDataContainer={objectImg}
        />
      ) : null}
    </React.Fragment>
  );
}
