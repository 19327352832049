import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { serviContainersApiCXN } from "../../../auth/axios.Container";
import { useContext } from "react";
import { AppContext } from "../../../Providers/AppProvider";

export default function BasicDemo() {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [loading, setLoading] = React.useState(true);
    const { showNotificationApp } = useContext(AppContext);

    const iniData = (data) => {
        let ret = {};
        let chileCount = 0;
        data.forEach((element) => {
            const countryName = element.container?.placeCountry?.name;
            if (countryName) {
                    chileCount++;   
            }
        });
        ret["Chile"] = chileCount;
        return ret;
    };

    useEffect(() => {
        setLoading(true);
        serviContainersApiCXN
            .get(`${process.env.REACT_APP_HOST_API}/modules?pagination=false`)
            .then((response) => {
                const containerData = iniData(response.data["hydra:member"]);
                const data = {
                    labels: ['Perú', 'Guatemala', 'Chile', 'Bolivia'],
                    datasets: [
                        {
                            label: 'Cantidad de contenedores por país',
                            data: [0, 0, containerData["Chile"] || 0, 0],
                            backgroundColor: [
                                '#007bff', // Azul
                                '#ffc107', // Amarillo
                                '#28a745', // Verde
                                '#17a2b8', // Cyan
                                '#dc3545', // Rojo
                                '#6610f2', // Morado
                                '#fd7e14', // Naranja
                                '#6f42c1', // Violeta
                                '#ffc107', // Amarillo
                                '#218838', // Verde
                                // Agrega más colores si tienes más tipos de contenedores
                            ],
                            hoverBackgroundColor: [
                                '#0056b3', // Azul oscuro
                                '#e0a800', // Amarillo oscuro
                                '#218838', // Verde oscuro
                                '#138496', // Cyan oscuro
                                '#c82333', // Rojo oscuro
                                '#4e0890', // Morado oscuro
                                '#d95d0e', // Naranja oscuro
                                '#5a1e99', // Violeta oscuro
                                '#e0a800', // Amarillo oscuro
                                '#218838', // Verde oscuro
                                // Agrega más colores si tienes más tipos de contenedores
                            ]
                        }
                    ]
                };
                const options = {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                };

                setChartData(data);
                setChartOptions(options);
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
                showNotificationApp("Error cargando datos del sistema", "error");
            })
            .finally(function () {
                setLoading(false);
            });
    }, [showNotificationApp]);

    return (
        <div className="card">
            <Chart style={{width:"400px"}} type="bar" data={chartData} options={chartOptions} />
        </div>
    )
}
