import React from "react";
import OperationsContainers from "./OperationsContainers";
import { useContext } from "react";
import { AppContext } from "../../Providers/AppProvider";
import { ROLES, isAccess } from "../../auth/security.ServiContainers";
import "../stye/styles.css";

function WrapperOperationsContainers() {
    const { showNotificationApp, onLogout } = useContext(AppContext);

    // if (!isAccess(ROLES.manager.tag)) {
    //     showNotificationApp("Su usuario no tiene acceso al área solicitada. Por favor vuelva a autenticarse.", "error");
    //     onLogout();
    //     return;
    // }

    return (
        <div className="configuration">
            <OperationsContainers />
        </div>
    );
}

export default WrapperOperationsContainers;
