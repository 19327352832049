import * as React from "react";
import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import ViewPhoto from "./ViewPhotoM";
import dayjs from "dayjs";
import ViewPDFBillS from "./viewPDFBillS";

const iniData = {
  id: "",
  codeInt: "",
  price: "",
  profitAsoc: "",
  clientName: "",
  bill: "",
  pay: "",
  transport: "",
  selledAt: dayjs().add(1, "d").toDate(),
  // photos: [],
  photo1: "",
  photo2: "",
  photo3: "",
  photo4: "",
  photo5: "",
  invoicePdfIn: "",
};

export default function ViewContainer({ open, onClose, viewDataSaleModule }) {
  const [dataSt, setDataSt] = React.useState(iniData);
  const [loading, setLoading] = React.useState(false);
  const [openViewPhoto, setOpenViewPhoto] = React.useState(false);
  const [refreshTable, setRefreshTable] = React.useState(0);
  const [openViewInvoicePdf, setOpenViewInvoicePdf] = React.useState(false);
  const [openViewPhotoContainer, setOpenViewPhotoContainer] =
    React.useState(false);
  const [objectImg, setObjectImg] = React.useState({
    id: "",
    photo: "",
  });

  const handleViewPhoto = (photoName) => {
    if (!openViewPhoto) {
      setOpenViewPhoto(true);
    }
    setObjectImg({
      id: viewDataSaleModule.id,
      photo: photoName,
    });
  };
  const handleCloseViewPhoto = () => {
    setOpenViewPhoto(false);
    handleRefreshT();
  };

  const handleRefreshT = () => {
    setRefreshTable(refreshTable === 0 ? 1 : 0);
    // setSelectionTab([]);
  };
  const handleViewInvoicePdf = () => {
    if (!openViewInvoicePdf) {
      setOpenViewInvoicePdf(true);
    }
  };

  const handleCloseViewInvoicePdf = () => {
    setOpenViewInvoicePdf(false);
  };

  const handleClose = () => {
    setDataSt(iniData);
    setLoading(false);
    onClose();
  };

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }
    const newPhotos = [];
    for (let i = 0; i < viewDataSaleModule.photos.length && i < 5; i++) {
      newPhotos.push(viewDataSaleModule.photos[i].name);
    }

    console.log(viewDataSaleModule.id);
    setDataSt({
      id: viewDataSaleModule.obj.id,
      codeInt: viewDataSaleModule.codeInt,
      price: viewDataSaleModule.price,
      profitAsoc: viewDataSaleModule.profitAsoc,
      clientName: viewDataSaleModule.clientName,
      bill: viewDataSaleModule.bill,
      pay: viewDataSaleModule.pay,
      transport: viewDataSaleModule.transport,
      selledAt: viewDataSaleModule.selledAt,
      ...newPhotos.reduce((acc, photo, index) => {
        acc[`photo${index + 1}`] = photo;
        return acc;
      }, {}),
    });
  }, [open]);

  if (!open) {
    return undefined;
  }
  console.log(objectImg);
  return (
    <React.Fragment>
      <Dialog open={open} fullWidth scroll="paper" color="primary">
        <DialogTitle className="dialog-title">
          {`Vista de la venta del módulo`}
          <Tooltip title="Cerrar" placement="top">
            <IconButton
              aria-label="cerrar-form-data-client-sale"
              disabled={loading}
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers>
          <>
            <Stack
              direction="column"
              spacing={2}
              alignItems="initial"
              sx={{ m: 3 }}
            >
              <Typography>
                <strong>Código Interno: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.codeInt}
                </Box>
              </Typography>

              <Typography>
                <strong>Precio: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.price}
                </Box>
              </Typography>

              <Typography>
                <strong>Ganancia: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.profitAsoc}
                </Box>
              </Typography>

              <Typography>
                <strong>Nombre del cliente: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.clientName}
                </Box>
              </Typography>

              <Typography>
                <strong>Factura: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.bill}
                </Box>
                <IconButton
                  variant="text"
                  color="primary"
                  onClick={() => handleViewInvoicePdf()}
                >
                  <Tooltip title="Ver" placement="top">
                    <VisibilitySharpIcon />
                  </Tooltip>
                </IconButton>
              </Typography>
            </Stack>

            <Stack
              direction="column"
              spacing={2}
              alignItems="initial"
              sx={{ m: 3 }}
            >
              <Typography>
                <strong>Pagado: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.pay}
                </Box>
              </Typography>

              <Typography>
                <strong>Transportado: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.transport}
                </Box>
              </Typography>

              <Typography>
                <strong>Fecha de venta: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.selledAt.toString()}
                </Box>
              </Typography>
              <Stack
                direction="column"
                spacing={2}
                alignItems="initial"
                sx={{ m: 3 }}
              >
                {viewDataSaleModule.photos.map((photo, index) => (
                  <div key={index}>
                    <Typography>
                      <strong>Foto {index + 1}: </strong>
                    </Typography>
                    <Box
                      component={"span"}
                      sx={{
                        backgroundColor: "#ebebeb",
                        borderRadius: "12px",
                        textAlign: "center",
                        padding: "8px",
                      }}
                    >
                      {/* {photo.name} */}
                      <IconButton
                        variant="text"
                        aria-label={`view-photo-${index}`}
                        color="primary"
                        onClick={() => handleViewPhoto(photo.name)}
                      >
                        <Tooltip title="Ver" placement="top">
                          <VisibilitySharpIcon />
                        </Tooltip>
                      </IconButton>
                    </Box>
                  </div>
                ))}
              </Stack>
            </Stack>
          </>
        </DialogContent>
      </Dialog>
      {objectImg.id !== "" ? (
        <ViewPhoto
          open={openViewPhoto}
          onClose={handleCloseViewPhoto}
          viewDataSaleModule={objectImg}
        />
      ) : null}
      {console.log(viewDataSaleModule)}
      <ViewPDFBillS
        open={openViewInvoicePdf}
        onClose={handleCloseViewInvoicePdf}
        viewDataSaleModule={viewDataSaleModule}
      />
    </React.Fragment>
  );
}
