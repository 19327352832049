import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
} from "@mui/material";
// import { useTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../../Providers/AppProvider";
import { useContext } from "react";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { serviContainersApiCXN } from "../../../auth/axios.Container";
import { MuiFileInput } from "mui-file-input";

const iniPdfValidInvoice = {
  error: false,
  text: "Añadir pdf asociado a factura (.pdf)",
};

const pdfToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      resolve(base64String);
    };
    reader.onerror = reject;
  });

const iniData = {
  id: "",
  pay: true,
  transport: true,
  bill: "",
};

export default function FormSaleContainer({ open, onClose, editData }) {
  const [dataSt, setDataSt] = React.useState(iniData);
  const [loading, setLoading] = React.useState(false);

  const { showNotificationApp } = useContext(AppContext);
  const [pdfInvoice, setPdfInvoice] = React.useState(null);
  const [pdfValidInvoice, setPdfValidInvoice] =
    React.useState(iniPdfValidInvoice);

  const handleClose = () => {
    setDataSt(iniData);
    setLoading(false);
    onClose();
  };
  const handlePay = (event) => {
    setDataSt({
      ...dataSt,
      pay: event.target.checked,
    });
  };
  const handleTransport = (event) => {
    setDataSt({
      ...dataSt,
      transport: event.target.checked,
    });
  };
  const handleBill = (event) => {
    setDataSt({
      ...dataSt,
      bill: event.target.value,
    });
  };
  const handlePdfInvoice = (value) => {
    let valid;
    if (!valid) setPdfValidInvoice(iniPdfValidInvoice);
    else setPdfValidInvoice({ ...valid });
    setPdfInvoice(value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let listInvoice = [];
    if (pdfInvoice !== null) {
      await Promise.all(pdfInvoice.map((file) => pdfToBase64(file))).then(
        (listPdfInvoice) => (listInvoice = listPdfInvoice)
      );
    }

    // let updateData = new Object();
    let updateData = {};
    updateData.pay = dataSt.pay;
    updateData.transport = dataSt.transport;
    updateData.bill = dataSt.bill;
    updateData.listInvoice = dataSt.listInvoice;
    serviContainersApiCXN
      .patch(`/sale_containers/${editData.id}`, updateData)
      .then((values) => {
        showNotificationApp("Se ha actualizado correctamente", "success");
        onClose();
      })
      .catch((error) => {
        showNotificationApp(
          "Ha ocurrido un error mientras se guardan los datos",
          "error"
        );
        console.log(error);
        onClose();
      });
  };

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }

    setDataSt({
      pay: editData.pay === "SI" ? true : false,
      transport: editData.transport === "SI" ? true : false,
      bill: editData.bill,
      listInvoice: editData.listInvoice,
    });
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        scroll="paper"
        color="primary"
      >
        <DialogTitle className="dialog-title">
          {`Editar venta de contenedores `}
          <Tooltip title="Cerrar" placement="top">
            <IconButton
              aria-label="cerrar-form-data-client-sale"
              disabled={loading}
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers>
          <Stack spacing={2}>
            <Stack direction="column" justifyContent="space-between">
              <FormControlLabel
                control={
                  <Switch
                    checked={dataSt.pay}
                    onChange={handlePay}
                    inputProps={{ "aria-label": "controlled" }}
                    label="Label"
                  />
                }
                label="Pagado"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={dataSt.transport}
                    onChange={handleTransport}
                    inputProps={{ "aria-label": "controlled" }}
                    label="Label"
                  />
                }
                label="Trasportado"
              />
              <TextField
                variant="standard"
                label="Factura de venta"
                required
                type="text"
                disabled={loading}
                value={dataSt.bill}
                onChange={handleBill}
                autoComplete="false"
                inputProps={{ min: 0, max: 100 }}
              />
              <MuiFileInput
                variant="standard"
                multiple
                inputProps={{
                  accept: "pdf",
                }}
                value={pdfInvoice}
                onChange={handlePdfInvoice}
                disabled={loading}
                error={pdfValidInvoice.error}
                helperText={pdfValidInvoice.text}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ borderRadius: 0 }}
            color="inherit"
            disabled={loading}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{
              borderRadius: 0,
              background: "#212544",
            }}
            loading={loading}
            onClick={handleSubmit}
          >
            Aceptar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
