import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>404 - Página no encontrada</h1>
      <p style={styles.text}>Esta ruta no existe. Regresar al <Link to="/" style={styles.link}>Inicio</Link>.</p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
    height: '100vh',
    backgroundColor:'#F4F4F4',
    position: 'absolute',
    zIndex:999,
  },
  title: {
    fontSize: '2rem',
    marginBottom: '1rem',
  },
  text: {
    fontSize: '1.2rem',
  },
  link: {
    textDecoration: 'underline',
    color: 'blue',
  },
};

export default NotFound;