import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../../Providers/AppProvider";
import { useContext } from "react";
import { serviContainersApiCXN } from "../../../auth/axios.Container";

const iniData = {
  name: "",
  description: "",
};
const iniDataValid = {
  name: { error: false, errorText: "" },
};

export default function FormTrader({ open, onClose, create, editData }) {
  const [dataSt, setDataSt] = React.useState(iniData);
  const [dataValid, setDataValid] = React.useState(iniDataValid);
  const [loading, setLoading] = React.useState(false);

  const { showNotificationApp } = useContext(AppContext);

  const handleClose = () => {
    setDataSt(iniData);
    setDataValid(iniDataValid);
    setLoading(false);
    onClose();
  };

  const handleName = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        name: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        name: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      name: event.target.value,
    });
  };
  const handleDescription = (event) => {
    setDataSt({
      ...dataSt,
      description: event.target.value,
    });
  };

  const handleSubmit = () => {
    let valid = { ...dataValid };
    let fail = false;
    setLoading(true);

    if (dataValid.name.error) fail = true;

    if (dataSt.name === "") {
      fail = true;
      valid = {
        ...valid,
        name: { error: true, errorText: "Este campo es obligatorio." },
      };
    }

    if (!fail) {
      if (create) {
        let newData = new Object();
        newData.name = dataSt.name;
        newData.description = dataSt.description;
        serviContainersApiCXN
          .post(`${process.env.REACT_APP_HOST_API}/traders`, newData)
          .then((response) => {
            showNotificationApp("Se ha creado correctamente", "success");
            onClose();
          })
          .catch((error) => {
            showNotificationApp(
              "Ha ocurrido un error mientras se guardan los datos",
              "error"
            );
            console.log(error);
            setDataSt(iniData);
            setDataValid(iniDataValid);
          })
          .finally(() => setLoading(false));
      } else {
        let updateData = new Object();
        updateData.name = dataSt.name;
        updateData.description = dataSt.description;
        serviContainersApiCXN
          .patch(
            `${process.env.REACT_APP_HOST_API}/traders/${editData.id}`,
            updateData
          )
          .then((response) => {
            showNotificationApp("Se ha actualizado correctamente", "success");
            onClose();
          })
          .catch((error) => {
            showNotificationApp(
              "Ha ocurrido un error mientras se guardan los datos",
              "error"
            );
            console.log(error);
            onClose();
          });
      }
    } else {
      setDataValid({ ...valid });
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }
    if (create) {
    } else {
      setDataSt({
        id: editData.id,
        name: editData.name,
        description: editData.description,
      });
    }
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        scroll="paper"
        color="primary"
      >
        <DialogTitle className="dialog-title">
          {`${create ? `Agregar arrendador` : `Editar arrendador`} `}
          <Tooltip title="Cerrar" placement="top">
            <IconButton
              aria-label="cerrar-form-data-client-sale"
              disabled={loading}
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers sx={{ maxHeight: "305h", minHeight: "35vh" }}>
          <Stack
            direction="column"
            spacing={2}
            alignItems="initial"
            alignContent="center"
            sx={{ mb: "1vh" }}
          >
            <TextField
              variant="standard"
              label={`Nombre`}
              required
              type="text"
              disabled={loading}
              value={dataSt.name}
              onChange={handleName}
              autoComplete="false"
              error={dataValid.name.error}
              helperText={dataValid.name.errorText}
              sx={{ minWidth: "120px", width: "400px" }}
            />
            <TextField
              variant="standard"
              label={`Descripción`}
              required
              type="text"
              disabled={loading}
              value={dataSt.description}
              onChange={handleDescription}
              autoComplete="false"
              sx={{ minWidth: "120px", width: "400px" }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ borderRadius: 0 }}
            color="inherit"
            disabled={loading}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{
              borderRadius: 0,
              background: "#212544",
            }}
            loading={loading}
            onClick={handleSubmit}
          >
            Aceptar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
