import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import { useContext } from "react";
import { AppContext } from "../../../Providers/AppProvider";
import { serviContainersApiCXN } from "../../../auth/axios.Container";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const iniData = {
  id1: "",
  id2: "",
  id3: "",
  id4: "",
  divideAt: dayjs().add(1, "d"),
};

const iniDataValid = {
  id1: { error: false, errorText: "" },
  id2: { error: false, errorText: "" },
  id3: { error: false, errorText: "" },
  id4: { error: false, errorText: "" },
  divideAt: { error: false, errorText: "" },
};

export default function DividerContainer({ open, onClose, BpRadio, viewData }) {
  const [loading, setLoading] = React.useState(false);
  const [valueSelected, setValueSelected] = React.useState(2);
  const [dataValid, setDataValid] = React.useState(iniDataValid);
  const [dataSt, setDataSt] = React.useState(iniData);
  const { showNotificationApp } = useContext(AppContext);

  const handleClose = () => {
    setDataSt(iniData);
    setDataValid(iniDataValid);
    setLoading(false);
    onClose();
  };

  function isUnique(value, pos) {
    let listValues = [dataSt.id1, dataSt.id2];
    if (valueSelected >= 3) {
      listValues.push(dataSt.id3);
      if (valueSelected > 3) listValues.push(dataSt.id4);
    }

    return !listValues.some(
      (ele, i) => i !== pos && ele !== "" && ele === value
    );
  }

  const handleId1 = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        id1: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else if (!isUnique(event.target.value.toUpperCase(), 0)) {
      setDataValid({
        ...dataValid,
        id1: {
          error: true,
          errorText: "Este código coincide con el de otra división.",
        },
      });
    } else {
      setDataValid({
        ...dataValid,
        id1: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      id1: event.target.value.toUpperCase(),
    });
  };

  const handleId2 = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        id2: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else if (!isUnique(event.target.value.toUpperCase(), 1)) {
      setDataValid({
        ...dataValid,
        id2: {
          error: true,
          errorText: "Este código coincide con el de otra división.",
        },
      });
    } else {
      setDataValid({
        ...dataValid,
        id2: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      id2: event.target.value.toUpperCase(),
    });
  };

  const handleId3 = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        id3: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else if (!isUnique(event.target.value.toUpperCase(), 2)) {
      setDataValid({
        ...dataValid,
        id3: {
          error: true,
          errorText: "Este código coincide con el de otra división.",
        },
      });
    } else {
      setDataValid({
        ...dataValid,
        id3: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      id3: event.target.value.toUpperCase(),
    });
  };

  const handleId4 = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        id4: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else if (!isUnique(event.target.value.toUpperCase(), 3)) {
      setDataValid({
        ...dataValid,
        id4: {
          error: true,
          errorText: "Este código coincide con el de otra división.",
        },
      });
    } else {
      setDataValid({
        ...dataValid,
        id4: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      id4: event.target.value.toUpperCase(),
    });
  };

  const handleDivideAt = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        divideAt: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        divideAt: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      divideAt: event.target.value,
    });
  };

  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  });

  const handleBPradio = (event) => {
    setValueSelected(event.target.value);
  };

  function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }

  const handleSubmit = () => {
    let valid = { ...dataValid };
    let fail = false;
    setLoading(true);

    if (dataValid.id1.error || dataValid.id2.error) fail = true;
    if (valueSelected >= 3 && dataValid.id3.error) fail = true;
    if (valueSelected === 4 && dataValid.id4.error) fail = true;
    if (dataValid.divideAt.error) fail = true;

    if (dataSt.id1 === "") {
      valid = {
        ...valid,
        id1: { error: true, errorText: "Dato obligatorio." },
      };
      fail = true;
    }

    if (dataSt.id2 === "") {
      valid = {
        ...valid,
        id2: { error: true, errorText: "Dato obligatorio." },
      };
      fail = true;
    }
    if (valueSelected >= 3) {
      if (dataSt.id3 === "") {
        valid = {
          ...valid,
          id3: { error: true, errorText: "Dato obligatorio." },
        };
        fail = true;
      }

      if (valueSelected === 4) {
        if (dataSt.id4 === "") {
          valid = {
            ...valid,
            id4: { error: true, errorText: "Dato obligatorio." },
          };
          fail = true;
        }
      }
    }

    if (!fail) {
      let listNewContainers = [dataSt.id1, dataSt.id2];
      if (valueSelected >= 3) {
        listNewContainers.push(dataSt.id3);
        if (valueSelected > 3) {
          listNewContainers.push(dataSt.id4);
        }
      }

      let divideAt = dataSt.divideAt;
      serviContainersApiCXN
        .post(
          `${process.env.REACT_APP_HOST_API}/containers/${viewData.id}/divide`,
          {
            divide: listNewContainers,
            divideAt,
          }
        )
        .then((response) => {
          showNotificationApp("Se ha dividido correctamente.", "success");
          onClose();
        })
        .catch((error) => {
          console.log(error);
          showNotificationApp(
            "Ha ocurrido un error mientras se guardan los datos",
            "error"
          );
          setDataSt(iniData);
          setDataValid(iniDataValid);
        })
        .finally(() => setLoading(false));
    } else {
      setDataValid({ ...valid });
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        scroll="paper"
        color="primary"
      >
        <DialogTitle className="dialog-title">
          {`Dividir el contenedor`}
          <Tooltip title="Cerrar" placement="top">
            <IconButton
              aria-label="cerrar-form-data-client-sale"
              disabled={loading}
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers sx={{ maxHeight: "auto" }}>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel
                id="demo-customized-radios"
                sx={{ textAlign: "center" }}
              >
                Seleccione en cuantas partes quiere dividir
              </FormLabel>
              <RadioGroup
                row
                sx={{ justifyContent: "center" }}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={valueSelected}
                onChange={handleBPradio}
              >
                <FormControlLabel value={2} control={<BpRadio />} label="2" />
                <FormControlLabel value={3} control={<BpRadio />} label="3" />
                <FormControlLabel value={4} control={<BpRadio />} label="4" />
              </RadioGroup>
            </FormControl>
            <TextField
              fullWidth={true}
              label="Cód.Interno división 1"
              variant="standard"
              required
              type="text"
              disabled={loading}
              value={dataSt.id1}
              onChange={handleId1}
              autoComplete="false"
              error={dataValid.id1.error}
              helperText={dataValid.id1.errorText}
            />
            <TextField
              fullWidth={true}
              label="Cód.Interno división 2"
              variant="standard"
              required
              type="text"
              disabled={loading}
              value={dataSt.id2}
              onChange={handleId2}
              autoComplete="false"
              error={dataValid.id2.error}
              helperText={dataValid.id2.errorText}
            />
            {valueSelected >= 3 && (
              <>
                <TextField
                  fullWidth={true}
                  label="Cód.Interno división 3"
                  variant="standard"
                  required
                  type="text"
                  disabled={loading}
                  value={dataSt.id3}
                  onChange={handleId3}
                  autoComplete="false"
                  error={dataValid.id3.error}
                  helperText={dataValid.id3.errorText}
                />
                {valueSelected > 3 && (
                  <TextField
                    fullWidth={true}
                    label="Cód.Interno división 4"
                    variant="standard"
                    required
                    type="text"
                    disabled={loading}
                    value={dataSt.id4}
                    onChange={handleId4}
                    autoComplete="false"
                    error={dataValid.id4.error}
                    helperText={dataValid.id4.errorText}
                  />
                )}
              </>
            )}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
              <DatePicker
                fullWidth={true}
                label="Fecha de divición"
                value={dayjs(dataSt.divideAt)}
                maxDate={dayjs("9999")}
                onChange={(newValue) =>
                  setDataSt({ ...dataSt, divideAt: newValue })
                }
                autoComplete="false"
                slotProps={{
                  textField: {
                    variant: "standard",
                    fullWidth: true,
                    format: "DD-MM-YYYY",
                  },
                }}
              />
            </LocalizationProvider>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ borderRadius: 0 }}
            color="inherit"
            disabled={loading}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <LoadingButton
            variant="contained"
            sx={{
              borderRadius: 0,
              background: "#212544",
            }}
            loading={loading}
            onClick={handleSubmit}
          >
            Aceptar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
