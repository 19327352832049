import * as React from "react";
import {
  Box,
  Stack,
  Divider,
  IconButton,
  Skeleton,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  esES,
} from "@mui/x-data-grid";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import { useContext } from "react";
import { AppContext } from "../../../Providers/AppProvider";
import { serviContainersApiCXN } from "../../../auth/axios.Container";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";

// const iniData = (data) => {
//   let ret = [];
//   data.forEach((element) => {
//     ret.push({
//       idOwner: element["@id"],
//       nameOwner: element.nameOwner,
//       createAt: new Date(element.createAt),
//       typeReg: element.typeReg,
//       value: element.value,
//     });
//   });
//   return ret;
// };
const iniData = (data) => {
  let ret = [];
  data.forEach((element, index) => {
    ret.push({
      id: index,
      idOwner: element["@id"],
      nameOwner: element.nameOwner,
      createAt: new Date(element.createAt),
      typeReg: element.typeReg,
      value: element.value,
    });
  });
  return ret;
};

export default function TabXContainer() {
  //const
  const [loading, setLoading] = React.useState(false);
  const [refreshTable, setRefreshTable] = React.useState(0);
  const [selectionTab, setSelectionTab] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const { showNotificationApp } = useContext(AppContext);

  const handleSubmitShearch = (text) => {
    setLoading(true);

    serviContainersApiCXN
      .get(`${process.env.REACT_APP_HOST_API}/contability_owners/owner/${text}`)
      .then((response) => {
        console.log(response.data["hydra:member"]);
        const data = response.data["hydra:member"];
        if (data.length === 0) {
          showNotificationApp(
            "No existe registro de contenedores asociado a ese código",
            "info"
          );
        } else {
          setItems(iniData(data));
        }
      })
      .catch(function (error) {
        // handle error
        showNotificationApp("Error cargando datos del sistema", "error");
        setItems([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRefreshT = () => {
    setLoading(true);
    setSelectionTab([]);
    setRefreshTable(refreshTable === 0 ? 1 : 0);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const MenuToolBar = ({ onSubmitShearch }) => {
    // const [search, setSearch] = React.useState([]);
    const [search, setSearch] = React.useState({ idOwner: "" });

    const handleIdOwner = (event) => {
      setSearch({
        ...search,
        idOwner: event.target.value,
      });
    };

    const handleSubmit = () => {
      onSubmitShearch(search.idOwner);
    };

    return (
      <React.Fragment>
        <Stack
          direction="row"
          justifyContent="flex-end"
          divider={<Divider orientation="vertical" />}
        >
          <IconButton
            variant="text"
            aria-label="refresh"
            color="primary"
            onClick={handleRefreshT}
          >
            <Tooltip title="Actualizar Tabla" placement="top">
              <RefreshSharpIcon />
            </Tooltip>
          </IconButton>
          <Divider orientation="vertical" flexItem />
          {/* <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Buscar por código interno"
            inputProps={{ "aria-label": "buscar por código interno" }}
            value={search.codeInt}
            onChange={handleCodeInt}
          /> */}
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Buscar por propietario"
            inputProps={{ "aria-label": "buscar por propietario" }}
            value={search.idOwner}
            onChange={handleIdOwner}
          />
          <IconButton
            type="button"
            // sx={{ p: "10px" }}
            aria-label="search"
            onClick={handleSubmit}
          >
            <SearchIcon />
          </IconButton>
        </Stack>
      </React.Fragment>
    );
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true }}
        />
        <MenuToolBar onSubmitShearch={handleSubmitShearch} />
      </GridToolbarContainer>
    );
  };

  return (
    <React.Fragment>
      {loading ? (
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"400px"}
          animation="wave"
        />
      ) : (
        <React.Fragment>
          <Box sx={{ height: "60vh", width: "100%" }}>
            <DataGrid
              checkboxSelection
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              rows={items}
              columns={[
                // { field: "idOwner", headerName: "ID", width: 300 },
                {
                  field: "nameOwner",
                  headerName: "Propietario",
                  width: 200,
                },
                {
                  field: "createAt",
                  headerName: "Fecha",
                  width: 200,
                  type: "dateTime",
                },

                {
                  field: "typeReg",
                  headerName: "Tipo de registro",
                  width: 200,
                },
                {
                  field: "value",
                  headerName: "valor",
                  width: 200,
                },
              ]}
              initialState={{
                sorting: {
                  sortModel: [{ field: "name", sort: "desc" }],
                },
              }}
              components={{
                Toolbar: CustomToolbar,
              }}
              onRowSelectionModelChange={(newSelectionModel) => {
                setSelectionTab(newSelectionModel);
              }}
              rowSelectionModel={selectionTab}
              pageSizeOptions={[5, 25, 50, 100]}
            />
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
