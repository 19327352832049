import * as React from "react";
import {
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { serviContainersApiCXN } from "../../../auth/axios.Container";
import { AppContext } from "../../../Providers/AppProvider";

const iniPhotosValid = {
  error: false,
  text: "Imágenes de hasta 5Mb (.jpg/.png)",
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(window.btoa(reader.result));
    reader.onerror = reject;
  });

const processListPhotos = (photos) => {
  let photosArray = [];
  if (photos) {
    photos.forEach(async (ele) => {
      await toBase64(ele).then((b64) => photosArray.push(b64));
    });
  }

  return photosArray;
};

export default function FormElements({ open, onClose, viewData }) {
  const [photos, setPhotos] = React.useState(null);
  const [photosValid, setPhotosValid] = React.useState(iniPhotosValid);
  const [loading, setLoading] = React.useState(false);

  const { showNotificationApp } = React.useContext(AppContext);

  const handleClose = () => {
    setLoading(false);
    onClose();
  };

  const handlePhotos = (value) => {
    let valid;
    value.forEach((file) => {
      if (!valid) {
        if (file.type !== "image/jpeg" && file.type !== "image/png") {
          valid = { error: true, text: "Formato no válido de imagen." };
        }
        if (file.size > 5000000) {
          valid = valid
            ? { error: true, text: valid.text + " Hay imágenes de más de 5Mb." }
            : { error: true, text: "Hay imágenes de más de 5Mb." };
        }
      }
    });

    if (!valid) setPhotosValid(iniPhotosValid);
    else setPhotosValid({ ...valid });
    setPhotos(value);
  };

  const handleSubmit = async () => {
    setLoading(true);

    let list = [];
    if (photos !== null) {
      await Promise.all(photos.map((file) => toBase64(file))).then(
        (listPhotos) => (list = listPhotos)
      );
    }

    serviContainersApiCXN
      .post(
        `${process.env.REACT_APP_HOST_API}/sale_containers/${viewData.id}/return`,
        {
          listPhotos: list,
        }
      )
      .then((res) => {
        console.log(res);
        showNotificationApp("Se ha devuelto correctamente.", "success");
        onClose();
      })
      .catch((err) => {
        console.log(err);
        showNotificationApp("Ha ocurrido un error.", "error");
      })
      .finally(() => setLoading(false));
    // setLoading(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        scroll="paper"
        color="primary"
      >
        <DialogTitle className="dialog-title">
          Devolver Módulo
          <Tooltip title="Cerrar" placement="top">
            <IconButton
              aria-label="cerrar-form-data-client-sale"
              disabled={loading}
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers sx={{ maxHeight: "auto", minHeight: "auto" }}>
          <Stack
            useFlexGap
            flexWrap="wrap"
            direction="row"
            spacing={2}
            alignItems="initial"
            alignContent="center"
            sx={{ mb: "1vh" }}
          >
            <p>Desea devolver módulo</p>
            {/* <p>Si desea, puede añadir fotos del contenedor a devolver</p>
            <MuiFileInput
              variant="standard"
              multiple
              inputProps={{
                accept: "image/png, image/jpeg",
              }}
              value={photos}
              onChange={handlePhotos}
              disabled={loading}
              error={photosValid.error}
              helperText={photosValid.text}
            /> */}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ borderRadius: 0 }}
            color="inherit"
            disabled={loading}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{
              borderRadius: 0,
              background: "#212544",
            }}
            loading={loading}
            onClick={handleSubmit}
            disabled={photosValid.error}
          >
            Devolver
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
