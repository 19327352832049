import React from "react";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import ListAltIcon from "@mui/icons-material/ListAlt";
import NightShelterIcon from "@mui/icons-material/NightShelter";
import HistoryIcon from "@mui/icons-material/History";
import { Link } from "react-router-dom";
import { Box, Tooltip } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useContext } from "react";
import { AppContext } from "../../Providers/AppProvider";
import "./Sidebar.css";
import { getUserId, isAccessPermission } from "../../auth/security.ServiContainers";
import { ROLES, isAccess } from "../../auth/security.ServiContainers";
import { serviContainersApiCXN } from "../../auth/axios.Container";

const iniData1 = (data) => {
  console.log( 'Data completa', data);
    console.log('RolesApp', data.rolesApp);


  const ret = { obj: data };   

  return {
    rolesApp: ret.obj.rolesApp,
    country: ret.obj.areaCountry.name
  };  
};

function Sidebar() {
  const { onLogout } = useContext(AppContext);
  const [items1, setItems1] = React.useState(0);
  const [userId, setUserId] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [refreshTable, setRefreshTable] = React.useState(0);
  const { showNotificationApp } = useContext(AppContext);
  

  React.useEffect(() => {
    const userIdt = getUserId();
    setUserId (userIdt);

    let userDataT;

    console.log ( "UserIdt", userIdt, "UserId", userId );
    console.log(userId);
    // Realizar el primer get de usuario
    setLoading(true);
    serviContainersApiCXN.get(`${process.env.REACT_APP_HOST_API}/users/${userIdt}`)
     .then((response) => {
        console.log("Response Jose", response);
        userDataT = iniData1(response.data);
        console.log(userDataT);
        setItems1(userDataT);
        console.log("response Data", (items1));
      })
     
     .catch(function (error) {
        showNotificationApp("Error cargando datos del sistema", "error");
        console.log(error);
      })
     .finally(function () {
        setLoading(false);
        
      });
  }, [refreshTable]);

  return (
    <React.Fragment>
      <Box className="sidebar">
        <MenuList className="menu">
          <MenuItem>
            <Link to="/">
              <ListItemIcon style={{ color: "#f1f1f1" }}>
                <Tooltip title="Inicio" placement="top">
                  <HomeIcon />
                </Tooltip>
              </ListItemIcon>
            </Link>
          </MenuItem>
          { items1.rolesApp === 0  && (
            <MenuItem>
              <Link to="/configuraciones">
                <ListItemIcon style={{ color: "#f1f1f1" }}>
                  <Tooltip title="Configuraciones" placement="top">
                    <SettingsIcon />
                  </Tooltip>
                </ListItemIcon>
              </Link>
            </MenuItem>
          )} 
          <MenuItem>
            <Link to="/contenedores">
              <ListItemIcon style={{ color: "#f1f1f1" }}>
                <Tooltip title="Contenedores" placement="top">
                  <ListAltIcon />
                </Tooltip>
              </ListItemIcon>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/modulos">
              <ListItemIcon>
                <Tooltip title="Módulos" placement="top">
                  <NightShelterIcon style={{ color: "#f1f1f1" }} />
                </Tooltip>
              </ListItemIcon>
            </Link>
          </MenuItem>
          { items1.rolesApp === 0  && (
            <MenuItem>
              <Link to="/operations-containers">
                <ListItemIcon>
                  <Tooltip title="Historial de operaciones" placement="top">
                    <HistoryIcon style={{ color: "#f1f1f1" }} />
                  </Tooltip>
                </ListItemIcon>
              </Link>
            </MenuItem>
          )}
          <MenuItem>
            <ListItemIcon onClick={() => onLogout()}>
              <Tooltip title="Cerrar sesión" placement="top">
                <LogoutIcon style={{ color: "#f1f1f1" }} />
              </Tooltip>
            </ListItemIcon>
          </MenuItem>
        </MenuList>
      </Box>
    </React.Fragment>
  );
}

export default Sidebar;
