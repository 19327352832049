import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AppContext } from "../../../Providers/AppProvider";
import { useContext } from "react";
import { serviContainersApiCXN } from "../../../auth/axios.Container";
// import FormLabel from "@mui/material/FormLabel";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import { MuiFileInput } from "mui-file-input";
import { getUserRoleData } from './../../../utils/Roles';

const iniPdfValidInvoice = {
  error: false,
  text: "Añadir pdf asociado a factura (.pdf)",
};

const iniPdfValidDI = {
  error: false,
  text: "Añadir pdf asociado a DI (.pdf)",
};
const iniPhotosValid = {
  error: false,
  text: "Añadir Imágenes asociadas al módulo(.jpg/.png)",
};
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(window.btoa(reader.result));
    reader.onerror = reject;
  });
// const pdfToBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => {
//       const base64String = reader.result.split(",")[1];
//       resolve(base64String);
//     };
//     reader.onerror = reject;
//   });

const iniData = {
  id: "",
  codeModule: "",
  model: "",
  provider: "",
  place_countries: "",
  // typeModeCatch: "DI",
  // modeCatch: "",
  profitAsoc: "",
  state: "",
  owner: "",
  weight: 1,
  propertyContainerAsocs: [],
  factoryYear: dayjs(),
  description: "",
  tacCode: "",
  // createAt: dayjs().add(1, "d").format("DD/MM/YYYY"),
  createAt: dayjs(),
  invoice: "",
  di: "",
  priceSaleEsp: 0,
  saleEspAt: dayjs(),
  outputAt: dayjs(),
};

const iniDataValid = {
  codeModule: { error: false, errorText: "" },
  // modeCatch: { error: false, errorText: "" },
  profitAsoc: { error: false, errorText: "" },
  lastUpdateStateAt: { error: false, errorText: "" },
  transformModule: { error: false, errorText: "" },
  owner: { error: false, errorText: "" },
  weight: { error: false, errorText: "" },
  tacCode: { error: false, errorText: "" },
  invoice: { error: false, errorText: "" },
  di: { error: false, errorText: "" },
};

const adapterListPropertiesCheck = (
  propertyContainerAsocs,
  listProperties = []
) => {
  let data = [];
  propertyContainerAsocs.forEach((element) => {
    if (
      listProperties.some((ele) => {
        return ele.propertyContainer["@id"] === element["@id"];
      })
    ) {
      data.push({ id: element["@id"], checked: true, name: element.name });
    } else {
      data.push({ id: element["@id"], checked: false, name: element.name });
    }
  });
  return data;
};

export default function FormElements({ open, onClose, create, editData }) {
  const [dataSt, setDataSt] = React.useState(iniData, { codeModule: "" });
  const [dataValid, setDataValid] = React.useState(iniDataValid);
  const [loading, setLoading] = React.useState(false);
  const [Model, setModel] = React.useState([]);
  const [place_countries, setPlace_countries] = React.useState([]);
  const [Provider, setProvider] = React.useState([]);
  const [State, setStateContainer] = React.useState([]);
  const [Owner, setOwner] = React.useState([]);
  const [PropertyContainerAsocs, setPropertyContainerAsocs] = React.useState(
    []
  );
  const [pdfInvoice, setPdfInvoice] = React.useState(null);
  const [pdfValidInvoice, setPdfValidInvoice] =
    React.useState(iniPdfValidInvoice);

  const [pdfDI, setPdfDI] = React.useState(null);
  const [pdfValidDI, setPdfValidDI] = React.useState(iniPdfValidDI);
  const [photos, setPhotos] = React.useState(null);
  const [photosValid, setPhotosValid] = React.useState(iniPhotosValid);
  const { showNotificationApp } = useContext(AppContext);
  const [rolesApp, setRolesApp] = React.useState(null);

  const handlePdfInvoice = (value) => {
    let valid;
    if (!valid) setPdfValidInvoice(iniPdfValidInvoice);
    else setPdfValidInvoice({ ...valid });
    setPdfInvoice(value);
  };
  const handlePhotos = (value) => {
    let valid;
    if (!valid) setPhotosValid(iniPhotosValid);
    else setPhotosValid({ ...valid });
    setPhotos(value);
  };
  const handlePdfDI = (value) => {
    let valid;
    if (!valid) setPdfValidDI(iniPdfValidDI);
    else setPdfValidDI({ ...valid });
    setPdfDI(value);
  };

  // const handlesaleEspAt = (e) => {
  //   setDataSt({
  //     ...dataSt,
  //     saleEspAt: e.target.value,
  //   });
  // };

  // const handleoutputAt = (e) => {
  //   setDataSt({
  //     ...dataSt,
  //     outputAt: e.target.value,
  //   });
  // };

  const handleClose = () => {
    setDataSt(iniData);
    setDataValid(iniDataValid);
    setLoading(false);
    onClose();
  };
  const handleCodeModule = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        codeModule: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        codeModule: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      codeModule: event.target.value,
    });
  };
  const handlePlace_countries = (e) => {
    setDataSt({
      ...dataSt,
      place_countries: e.target.value,
    });
  };
  const handlepriceSaleEsp = (e) => {
    setDataSt({
      ...dataSt,
      priceSaleEsp: e.target.value,
    });
  };
  const handleModel = (e) => {
    setDataSt({
      ...dataSt,
      model: e.target.value,
    });
  };
  const handleWeight = (e) => {
    setDataSt({
      ...dataSt,
      weight: e.target.value,
    });
  };
  const handleState = (e) => {
    setDataSt({
      ...dataSt,
      state: e.target.value,
    });
  };
  const handleProvider = (e) => {
    setDataSt({
      ...dataSt,
      provider: e.target.value,
    });
  };
  const handleOwner = (e) => {
    setDataSt({
      ...dataSt,
      owner: e.target.value,
    });
  };
  // const handlePropertiesContainers = (event, key) => {
  //   let newList = [...PropertyContainerAsocs];
  //   newList[key] = {
  //     ...newList[key],
  //     checked: event.target.checked,
  //   };
  //   setPropertyContainerAsocs([...newList]);
  // };
  const handleinvoice = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        invoice: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        invoice: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      invoice: event.target.value,
    });
  };

  const handledi = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        di: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        di: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      di: event.target.value,
    });
  };

  const getListProperties = () => {
    const tempo = [];
    PropertyContainerAsocs.forEach((ele) => {
      if (ele.checked) {
        tempo.push({
          propertyContainer: ele.id,
        });
      }
    });
    return tempo;
  };

  const handleProfitAsoc = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        profitAsoc: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        profitAsoc: { error: false, errorText: "" },
      });
    }
    if (parseFloat(event.target.value) >= 100) {
      setDataSt({
        ...dataSt,
        profitAsoc: 100,
      });
    } else if (parseFloat(event.target.value) <= 0) {
      setDataSt({
        ...dataSt,
        profitAsoc: 0,
      });
    } else {
      setDataSt({
        ...dataSt,
        profitAsoc: event.target.value,
      });
    }
  };

  const handleDescription = (event) => {
    setDataSt({
      ...dataSt,
      description: event.target.value,
    });
  };

  // const handleTacCode = (event) => {
  //   const tacCode = event.target.value;

  //   if (tacCode === "") {
  //     setDataValid({
  //       ...dataValid,
  //       tacCode: { error: true, errorText: "Este campo es obligatorio." },
  //     });
  //   } else {
  //     setDataValid({
  //       ...dataValid,
  //       tacCode: { error: false, errorText: "" },
  //     });
  //   }
  //   setDataSt({
  //     ...dataSt,
  //     tacCode: tacCode,
  //   });
  // };

  const handleSubmit = async () => {
    let valid = { ...dataValid };
    let fail = false;
    setLoading(true);
    let invoicePdfIn = "";
    if (pdfInvoice !== null) {
      const file = pdfInvoice;
      await toBase64(file).then((base64) => (invoicePdfIn = base64));
    }
    let diPdfIn = "";
    if (pdfDI !== null) {
      const file = pdfDI;
      await toBase64(file).then((base64) => (diPdfIn = base64));
    }

    let list = [];
    if (photos !== null) {
      await Promise.all(photos.map((file) => toBase64(file))).then(
        (listPhotos) => (list = listPhotos)
      );
    }
    if (dataValid.codeModule.error) fail = true;
    if (dataValid.profitAsoc.error) fail = true;
    if (dataValid.weight.error) fail = true;
    if (dataValid.tacCode.error) fail = true;
    if (dataValid.invoice.error) fail = true;
    if (dataValid.di.error) fail = true;

    if (dataSt.codeModule === "") {
      fail = true;
      valid = {
        ...valid,
        codeInt: { error: true, errorText: "Este campo es obligatorio." },
      };
    } else if (dataSt.weight === "") {
      fail = true;
      valid = {
        ...valid,
        weight: { error: true, errorText: "Este campo es obligatorio" },
      };
    } else if (dataSt.invoice === "") {
      fail = true;
      valid = {
        ...valid,
        invoice: { error: true, errorText: "Este campo es obligatorio" },
      };
    } else if (dataSt.di === "") {
      fail = true;
      valid = {
        ...valid,
        di: { error: true, errorText: "Este campo es obligatorio" },
      };
    }
    if (!fail) {
      if (create) {
        // let newData = new Object();
        let newData = {};
        newData.codeExt = dataSt.codeModule;
        newData.codeInt = dataSt.codeModule;
        newData.typeModeCatch = dataSt.typeModeCatch;
        // newData.createAt = dayjs().year(dataSt.createAt["$y"]);
        newData.createAt = dataSt.createAt;
        newData.placeCountry = dataSt.place_countries;
        newData.model = dataSt.model;
        newData.profitAsoc = parseFloat(dataSt.profitAsoc) / 100;
        newData.provider = dataSt.provider;
        newData.state = dataSt.state;
        newData.owner = dataSt.owner;
        newData.factoryYear = parseInt(dataSt.factoryYear["$y"]);
        newData.propertyContainerAsocs = getListProperties();
        newData.weight = parseFloat(dataSt.weight);
        newData.tacCode = dataSt.codeModule;
        newData.description = dataSt.description;
        newData.invoice = dataSt.invoice;
        newData.di = dataSt.di;
        newData.listPhotos = list;
        newData.invoicePdfIn = invoicePdfIn;
        newData.diPdfIn = diPdfIn;
        newData.priceSaleEsp = parseFloat(dataSt.priceSaleEsp);

        newData.saleEspAt = dataSt.saleEspAt;
        newData.outputAt = dataSt.outputAt;

        const containerResponse = await serviContainersApiCXN.post(
          `${process.env.REACT_APP_HOST_API}/containers`,
          newData
        );
        if (!(containerResponse.status === 201)) {
          showNotificationApp("Error al crear el contenedor", "error");
        } else {
          console.log(containerResponse);
          const reference_to_containers = containerResponse.data["@id"];
          console.log(reference_to_containers);
          let newModule = new Object();
          newModule.container = reference_to_containers;
          newModule.codeModule = dataSt.codeModule;
          newModule.profitAsoc = parseFloat(dataSt.profitAsoc) / 100;
          newModule.state = dataSt.state;
          newModule.inboxInvoice = dataSt.invoice;
          newModule.inboxDI = dataSt.di;
          newModule.createAt = dataSt.createAt;

          console.log("Datos enviados a la API:", newData);
          const moduleResponse = await serviContainersApiCXN.post(
            "/modules",
            newModule
          );
          showNotificationApp("Módulo creado correctamente", "success");
          if (!(moduleResponse.status === 201)) {
            showNotificationApp("Error al crear el módulo", "error");
            setLoading(false);
            onClose();
          } else {
            showNotificationApp("Módulo creado correctamente", "success");
          }
          setLoading(false);
          onClose();
        }
      } else {
        let updateData = new Object();
        updateData.codeExt = dataSt.codeModule;
        updateData.codeInt = dataSt.codeModule;
        updateData.typeModeCatch = dataSt.typeModeCatch;
        // newData.createAt = dayjs().year(dataSt.createAt["$y"]);
        updateData.createAt = dataSt.createAt;
        updateData.placeCountry = dataSt.place_countries;
        updateData.model = dataSt.model;
        updateData.profitAsoc = parseFloat(dataSt.profitAsoc) / 100;
        updateData.provider = dataSt.provider;
        updateData.state = dataSt.state;
        updateData.owner = dataSt.owner;
        updateData.factoryYear = parseInt(dataSt.factoryYear["$y"]);
        updateData.propertyContainerAsocs = getListProperties();
        updateData.weight = parseFloat(dataSt.weight);
        updateData.tacCode = dataSt.codeModule;
        updateData.description = dataSt.description;
        updateData.invoice = dataSt.invoice;
        updateData.di = dataSt.di;
        updateData.priceSaleEsp = parseFloat(dataSt.priceSaleEsp);
        updateData.saleEspAt = dataSt.saleEspAt;
        updateData.outputAt = dataSt.outputAt;
        updateData.invoicePdfIn = invoicePdfIn;
        updateData.diPdfIn = diPdfIn;
        updateData.priceSaleEsp = parseFloat(dataSt.priceSaleEsp);
        updateData.listPhotos = list;
        updateData.createAt = dataSt.createAt;

        const containerResponse = await serviContainersApiCXN.patch(
          `${process.env.REACT_APP_HOST_API}/containers/${editData.obj.container.id}`,
          updateData
        );
        if (!(containerResponse.status === 201)) {
          // showNotificationApp("Error al crear el contenedor", "error");
          // console.log(updateData);
          console.log(containerResponse);
          const reference_to_containers = containerResponse.data["@id"];
          console.log(reference_to_containers);
          let newUpdate = new Object();
          newUpdate.container = reference_to_containers;
          newUpdate.codeModule = dataSt.codeModule;
          newUpdate.profitAsoc = parseFloat(dataSt.profitAsoc) / 100;
          newUpdate.state = dataSt.state;
          newUpdate.inboxInvoice = dataSt.invoice;
          newUpdate.inboxDI = dataSt.di;
          newUpdate.createAt = dataSt.createAt;

          console.log("Datos enviados a la API:", newUpdate);
          const moduleResponse = await serviContainersApiCXN.patch(
            `/modules/${editData.id}`,
            newUpdate
          );
          showNotificationApp("Módulo editado correctamente", "success");
          if (!(moduleResponse.status === 201)) {
            // showNotificationApp("Error al crear el módulo", "error");
            // setLoading(false);
            // onClose();
            showNotificationApp("Módulo editado correctamente", "success");
          } else {
            showNotificationApp("Módulo editado correctamente", "success");
          }
          setLoading(false);
          onClose();
        } else {
          console.log(containerResponse);
          const reference_to_containers = containerResponse.data["@id"];
          console.log(reference_to_containers);
          let newUpdate = new Object();
          newUpdate.container = reference_to_containers;
          newUpdate.codeModule = dataSt.codeModule;
          newUpdate.profitAsoc = parseFloat(dataSt.profitAsoc) / 100;
          newUpdate.state = dataSt.state;
          newUpdate.inboxInvoice = dataSt.invoice;
          newUpdate.inboxDI = dataSt.di;
          newUpdate.createAt = dataSt.createAt;

          console.log("Datos enviados a la API:", newUpdate);
          const moduleResponse = await serviContainersApiCXN.patch(
            `/modules/${editData.id}`,
            newUpdate
          );
          showNotificationApp("Módulo editado correctamente", "success");
          if (!(moduleResponse.status === 201)) {
            showNotificationApp("Error al crear el módulo", "error");
            setLoading(false);
            onClose();
          } else {
            showNotificationApp("Módulo editado correctamente", "success");
          }
          setLoading(false);
          onClose();
        }
      }
    } else {
      setDataValid({ ...valid });
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const fetchRoles = async () => {
      try {
        const userData = await getUserRoleData();
        setRolesApp(userData.rolesApp);
      } catch (error) {
        console.error("Error fetching user roles", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRoles();
  }, []);

  React.useEffect(() => {
    console.log(editData);
    if (!open) {
      return undefined;
    }
    if (create) {
      setLoading(true);

      Promise.all([
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/place_countries?pagination=false`
        ),
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/type_containers?pagination=false`
        ),
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/providers?pagination=false`
        ),
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/state_containers?pagination=false`
        ),
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/owners?pagination=false`
        ),
        serviContainersApiCXN.get(
          `${process.env.REACT_APP_HOST_API}/property_containers?pagination=false`
        ),
      ])
        .then((values) => {
          const [
            place_countries,
            model,
            provider,
            state,
            owners,
            property_containers,
          ] = values;
          setPropertyContainerAsocs(
            adapterListPropertiesCheck(property_containers.data["hydra:member"])
          );
          setPlace_countries(place_countries.data["hydra:member"]);
          setModel(model.data["hydra:member"]);
          setProvider(provider.data["hydra:member"]);
          setStateContainer(state.data["hydra:member"]);
          setOwner(owners.data["hydra:member"]);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoading(false));
    } else setLoading(true);
    Promise.all([
      serviContainersApiCXN.get(
        `${process.env.REACT_APP_HOST_API}/place_countries?pagination=false`
      ),
      serviContainersApiCXN.get(
        `${process.env.REACT_APP_HOST_API}/type_containers?pagination=false`
      ),
      serviContainersApiCXN.get(
        `${process.env.REACT_APP_HOST_API}/providers?pagination=false`
      ),
      serviContainersApiCXN.get(
        `${process.env.REACT_APP_HOST_API}/state_containers?pagination=false`
      ),
      serviContainersApiCXN.get(
        `${process.env.REACT_APP_HOST_API}/owners?pagination=false`
      ),
      serviContainersApiCXN.get(
        `${process.env.REACT_APP_HOST_API}/property_containers?pagination=false`
      ),
    ])
      .then((values) => {
        const [
          place_countries,
          model,
          provider,
          state,
          owners,
          property_containers,
        ] = values;
        setPropertyContainerAsocs(
          adapterListPropertiesCheck(property_containers.data["hydra:member"])
        );
        setPlace_countries(place_countries.data["hydra:member"]);
        setModel(model.data["hydra:member"]);
        setProvider(provider.data["hydra:member"]);
        setStateContainer(state.data["hydra:member"]);
        setOwner(owners.data["hydra:member"]);
        console.log(editData);
        setDataSt({
          codeModule: editData.codeModule,
          provider: editData.obj?.container.provider,
          // place_countries: editData.place_countries["@id"],
          place_countries: editData.obj.container.placeCountry["@id"],
          model: editData.obj.container.model["@id"],
          profitAsoc: parseFloat(editData.profitAsoc),
          // state: editData.state["@id"],
          state: editData.obj.state["@id"],
          owner: editData.owner["@id"],
          weight: editData.weight,
          propertyContainerAsocs: editData.propertyContainerAsocs,
          factoryYear: dayjs(editData.factoryYear),
          description: editData.description,
          tacCode: editData.tacCode,
          invoice: editData.invoice,
          di: editData.di,
          priceSaleEsp: editData.priceSaleEsp,
          listPhotos: editData.listPhotos,
          saleEspAt: dayjs(editData.obj.container.saleEspAt),
          outputAt: dayjs(editData.obj.container.outputAt),
          invoicePdfIn: editData.invoicePdfIn,
          diPdfIn: editData.diPdfIn,
          createAt: dayjs(editData.obj.createAt),
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, [open]);
  return (
    <React.Fragment>
      <Dialog open={open} fullWidth maxWidth="sm" scroll="paper" color="primary">
    <DialogTitle className="dialog-title" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {`${create ? `Crear a Módulo` : `Editar Módulo`} `}
          <div style={{ marginLeft: 'auto', fontSize: '1rem', fontWeight: 'bold' }}>
      <FormControl
                    variant="standard"
                    sx={{ minWidth: "120px", width: "260px" }}
                    disabled={true}
                  >
                    <InputLabel id="country">Precio de Lista</InputLabel>
                    <Select
                      labelId="state-ct-select-label-equipo"
                      id="state-ct-select-equipo"
                      value={dataSt.model}
                      onChange={handleModel}
                      label="model"
                    >
                      {Model.map((ele) => {
                        return (
                          <MenuItem key={ele.id} value={ele["@id"]}>
                            {ele.priceList}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
      </div>
          <Tooltip title="Cerrar" placement="top">
            <IconButton
              aria-label="cerrar-form-data-client-sale"
              disabled={loading}
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers>
          <Stack spacing={2}>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "8rem",
                  background: "#f2f2f2",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Stack direction="row" justifyContent="space-between">
                  <TextField
                    variant="standard"
                    label={`Código Módulo`}
                    required
                    type="text"
                    disabled={loading || !create}
                    value={dataSt.codeModule}
                    onChange={handleCodeModule}
                    autoComplete="false"
                    error={dataValid.codeModule.error}
                    helperText={dataValid.codeModule.errorText}
                    sx={{ minWidth: "120px", width: "260px" }}
                  />
                  <TextField
                    variant="standard"
                    label="Ganancia en %"
                    required
                    type="number"
                    disabled={loading  ||  rolesApp == 2 }
                    value={dataSt.profitAsoc}
                    onChange={handleProfitAsoc}
                    autoComplete="false"
                    error={dataValid.profitAsoc.error}
                    sx={{ minWidth: "120px", width: "260px" }}
                    inputProps={{ min: 0, max: 100 }}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <FormControl
                    variant="standard"
                    sx={{ minWidth: "120px", width: "260px" }}
                    disabled={loading  ||  rolesApp == 2 }
                  >
                    <InputLabel id="country">Lugar</InputLabel>
                    <Select
                      labelId="state-ct-select-label-equipo"
                      id="state-ct-select-equipo"
                      value={dataSt.place_countries}
                      onChange={handlePlace_countries}
                      label="country"
                    >
                      {place_countries.map((ele) => {
                        return (
                          <MenuItem key={ele.id} value={ele["@id"]}>
                            {ele.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    sx={{ minWidth: "120px", width: "260px" }}
                    disabled={loading  ||  rolesApp == 2 }
                  >
                    <InputLabel id="country">Tipo de Módulo</InputLabel>
                    <Select
                      labelId="state-ct-select-label-equipo"
                      id="state-ct-select-equipo"
                      value={dataSt.model}
                      onChange={handleModel}
                      label="model"
                    >
                      {Model.map((ele) => {
                        return (
                          <MenuItem key={ele.id} value={ele["@id"]}>
                            {ele.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  {/* <FormControl
                    variant="standard"
                    sx={{ minWidth: "120px", width: "260px" }}
                    disabled={loading}
                  >
                    <InputLabel id="country">Estado del Módulo</InputLabel>
                    {console.log(dataSt.state)}
                    <Select
                      labelId="state-ct-select-label-equipo"
                      id="state-ct-select-equipo"
                      value={dataSt.state}
                      onChange={handleState}
                      label="model"
                    >
                      {State.map((ele) => {
                        return (
                          <MenuItem key={ele.id} value={ele["@id"]}>
                            {ele.name}
                            {console.log(ele.name)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl> */}
                   <FormControl
  variant="standard"
  sx={{ minWidth: "120px", width: "260px" }}
  disabled={loading}
>
  <InputLabel id="country">Estado del Contenedor</InputLabel>
  <Select
    labelId="state-ct-select-label-equipo"
    id="state-ct-select-equipo"
    value={dataSt.state}
    onChange={handleState}
    label="model"
   
  >
    {State.map((ele) => {
      if (rolesApp === 2) {
        if (ele.name === "RESERVADO PARA ARRIENDO" || ele.name === "RESERVADO PARA VENTA") {
          return (
            <MenuItem key={ele.id} value={ele["@id"]}>
              {ele.name}
            </MenuItem>
          );
        } else {
          return null;
        }
      } else {
        return (
          <MenuItem key={ele.id} value={ele["@id"]}>
            {ele.name}
          </MenuItem>
        );
      }
    })}
  </Select>
</FormControl>
                  <FormControl
                    variant="standard"
                    sx={{ minWidth: "120px", width: "260px" }}
                    disabled={loading  ||  rolesApp == 2 }
                  >
                    <InputLabel id="country">Proveedor</InputLabel>
                    <Select
                      labelId="state-ct-select-label-equipo"
                      id="state-ct-select-equipo"
                      value={dataSt.provider}
                      onChange={handleProvider}
                      label="model"
                    >
                      {Provider.map((ele) => {
                        return (
                          <MenuItem key={ele.id} value={ele["@id"]}>
                            {ele.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <FormControl
                    variant="standard"
                    sx={{ minWidth: "120px", width: "260px" }}
                    disabled={loading  ||  rolesApp == 2 }
                  >
                    <InputLabel id="country">Propietario</InputLabel>
                    <Select
                      labelId="state-ct-select-label-propietario"
                      id="state-ct-select-propietario"
                      value={dataSt.owner}
                      onChange={handleOwner}
                      label="model"
                    >
                      {Owner.map((ele) => {
                        return (
                          <MenuItem key={ele["@id"]} value={ele["@id"]}>
                            {ele.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    variant="standard"
                    label="Peso contenedor"
                    required
                    type="number"
                    disabled={loading  ||  rolesApp == 2 }
                    value={dataSt.weight}
                    onChange={handleWeight}
                    autoComplete="false"
                    sx={{ minWidth: "120px", width: "260px" }}
                    inputProps={{ min: 0, max: 100 }}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <TextField
                    variant="standard"
                    label="Factura"
                    required
                    type="text"
                    disabled={loading  ||  rolesApp == 2 }
                    value={dataSt.invoice}
                    onChange={handleinvoice}
                    autoComplete="false"
                    error={dataValid.invoice.error}
                    sx={{ minWidth: "120px", width: "260px" }}
                    inputProps={{ min: 0, max: 100 }}
                  />

                  <TextField
                    variant="standard"
                    label="DI"
                    required
                    type="text"
                    disabled={loading  ||  rolesApp == 2 }
                    value={dataSt.di}
                    onChange={handledi}
                    autoComplete="false"
                    error={dataValid.di.error}
                    sx={{ minWidth: "120px", width: "260px" }}
                    inputProps={{ min: 0, max: 100 }}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <MuiFileInput
                    variant="standard"
                    inputProps={{ accept: ".pdf" }}
                    value={pdfInvoice}
                    onChange={handlePdfInvoice}
                    disabled={loading  ||  rolesApp == 2 }
                    error={pdfValidInvoice.error}
                    helperText={pdfValidInvoice.text}
                    sx={{ minWidth: "120px", width: "260px" }}
                  />
                  <MuiFileInput
                    variant="standard"
                    inputProps={{ accept: ".pdf" }}
                    value={pdfDI}
                    onChange={handlePdfDI}
                    disabled={loading  ||  rolesApp == 2 }
                    error={pdfValidDI.error}
                    helperText={pdfValidDI.text}
                    sx={{ minWidth: "120px", width: "260px" }}
                  />
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es"
                  >
                    <DatePicker
                      label="Año de fabricación"
                      value={dataSt.factoryYear}
                      maxDate={dayjs("9999")}
                      views={["year"]}
                      onChange={(newValue) =>
                        setDataSt({ ...dataSt, factoryYear: newValue })
                      }
                      disabled={loading  ||  rolesApp == 2 }
                      autoComplete="false"
                      slotProps={{
                        textField: {
                          variant: "standard",
                          fullWidth: true,
                        },
                      }}
                      sx={{ minWidth: "120px", width: "260px" }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es"
                  >
                    <DatePicker
                      label="Fecha de internación"
                      value={dataSt.createAt}
                      maxDate={dayjs("9999")}
                      onChange={(newValue) =>
                        setDataSt({ ...dataSt, createAt: newValue })
                      }
                      disabled={loading  ||  rolesApp == 2 }
                      autoComplete="false"
                      slotProps={{
                        textField: {
                          variant: "standard",
                          fullWidth: true,
                          format: "DD-MM-YYYY",
                        },
                      }}
                      sx={{ minWidth: "120px", width: "260px" }}
                    />
                  </LocalizationProvider>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es"
                  >
                    <DatePicker
                      label="Fecha de Egreso"
                      value={dataSt.outputAt}
                      maxDate={dayjs("9999")}
                      onChange={(newValue) =>
                        setDataSt({ ...dataSt, outputAt: newValue })
                      }
                      disabled={loading  ||  rolesApp == 2 }
                      autoComplete="false"
                      slotProps={{
                        textField: {
                          variant: "standard",
                          fullWidth: true,
                          format: "DD-MM-YYYY",
                        },
                      }}
                      sx={{ minWidth: "120px", width: "260px" }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es"
                  >
                    <DatePicker
                      label="Fecha de compra"
                      value={dataSt.saleEspAt}
                      maxDate={dayjs("9999")}
                      onChange={(newValue) =>
                        setDataSt({ ...dataSt, saleEspAt: newValue })
                      }
                      disabled={loading  ||  rolesApp == 2 }
                      autoComplete="false"
                      slotProps={{
                        textField: {
                          variant: "standard",
                          fullWidth: true,
                          format: "DD-MM-YYYY",
                        },
                      }}
                      sx={{ minWidth: "120px", width: "260px" }}
                    />
                  </LocalizationProvider>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <TextField
                    variant="standard"
                    label="Precio de venta"
                    required
                    type="number"
                    disabled={loading  ||  rolesApp == 2 }
                    value={dataSt.priceSaleEsp}
                    onChange={handlepriceSaleEsp}
                    autoComplete="false"
                    sx={{ minWidth: "120px", width: "260px" }}
                    inputProps={{ min: 0, max: 100 }}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  {/* <p>Añadir fotos del contenedor</p> */}
                  <MuiFileInput
                    fullWidth={true}
                    variant="standard"
                    multiple
                    inputProps={{
                      accept: "image/png, image/jpeg",
                    }}
                    value={photos}
                    onChange={handlePhotos}
                    disabled={loading  ||  rolesApp == 2 }
                    error={photosValid.error}
                    helperText={photosValid.text}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <TextField
                    fullWidth={true}
                    multiline
                    rows={2}
                    name="standart"
                    variant="standard"
                    label={`Observaciones`}
                    required
                    type="text"
                    disabled={loading  ||  rolesApp == 2 }
                    value={dataSt.description}
                    onChange={handleDescription}
                    autoComplete="false"
                  />
                </Stack>
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ borderRadius: 0 }}
            color="inherit"
            disabled={loading}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{
              borderRadius: 0,
              background: "#212544",
            }}
            loading={loading}
            onClick={handleSubmit}
          >
            Aceptar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
