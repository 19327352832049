// roles.js

import { getUserId } from "./../auth/security.ServiContainers";
import { serviContainersApiCXN } from "./../auth/axios.Container";

export const getUserRoleData = async () => {
  const userId = getUserId();
  
  try {
    const response = await serviContainersApiCXN.get(`${process.env.REACT_APP_HOST_API}/users/${userId}`);
    const userData = response.data;
    console.log(userData)

    return {
      rolesApp: userData.rolesApp,
      country: userData.areaCountry.name
    };
  } catch (error) {
    console.error("Error cargando datos del usuario", error);
    throw new Error("Error cargando datos del usuario");
  }
};
