import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../../Providers/AppProvider";
import { useContext } from "react";
import { serviContainersApiCXN } from "../../../auth/axios.Container";
import TrendingFlatRoundedIcon from "@mui/icons-material/TrendingFlatRounded";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const iniData = {
  static_owner: "",
  owner: "",
  invoiceTransfer: "",
  value: 0,
  transferAt: dayjs().add(1, "d"),
};

const iniDataValid = {
  value: { error: false, errorText: "" },
};

export default function FormOwners({ open, onClose, editData }) {
  const [dataSt, setDataSt] = React.useState(iniData);
  const [dataValid, setDataValid] = React.useState(iniDataValid);
  const [loading, setLoading] = React.useState(false);
  const [Owner, setOwner] = React.useState([]);
  const { showNotificationApp } = useContext(AppContext);

  const handleClose = () => {
    setDataSt(iniData);
    setDataValid(iniDataValid);
    setLoading(false);
    onClose();
  };
  const handleValue = (event) => {
    const inputValue = event.target.value;

    const regex = /^[1-9]\d*$/;

    if (inputValue === "") {
      setDataValid({
        ...dataValid,
        value: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else if (!regex.test(inputValue)) {
      setDataValid({
        ...dataValid,
        value: { error: true, errorText: "El valor debe ser mayor que cero." },
      });
    } else {
      setDataValid({
        ...dataValid,
        value: { error: false, errorText: "" },
      });
    }

    setDataSt({
      ...dataSt,
      value: inputValue,
    });
  };
  const handleOwner = (e) => {
    setDataSt({
      ...dataSt,
      owner: e.target.value,
    });
  };
  const handleInvoiceTransfer = (e) => {
    setDataSt({
      ...dataSt,
      invoiceTransfer: e.target.value,
    });
  };

  const handleSubmit = () => {
    let valid = { ...dataValid };
    let fail = false;
    setLoading(true);

    if (dataValid.value.error) fail = true;

    if (dataSt.value === "") {
      fail = true;
      valid = {
        ...valid,
        value: { error: true, errorText: "Este campo es obligatorio." },
      };
    }

    if (!fail) {
      let updateData = new Object();
      updateData.valueTransfer = parseFloat(dataSt.value);
      updateData.owner = dataSt.owner;
      updateData.transferAt = dataSt.transferAt;
      updateData.invoiceTransfer = dataSt.invoiceTransfer;

// Acceder a editData pedir acceso desd la api

      serviContainersApiCXN
        .post(
          `${process.env.REACT_APP_HOST_API}/containers/${editData.id}/transfer_owner`,
          updateData
        )
        .then((values) => {
          showNotificationApp("Se ha actualizado correctamente", "success");
          onClose();
        })
        .catch((error) => {
          showNotificationApp(
            "Ha ocurrido un error mientras se guardan los datos",
            "error"
          );
          console.log(error);
          onClose();
        });
    } else {
      setDataValid({ ...valid });
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }
    setLoading(true);
    Promise.all([
      serviContainersApiCXN.get(
        `${process.env.REACT_APP_HOST_API}/owners?pagination=false`
      ),
    ])
      .then((values) => {
        const [owner] = values;
        console.log(
          owner.data["hydra:member"].filter((element) => {
            return element["@id"] !== editData.obj?.owner["@id"];
          })
        );
        setOwner(
          owner.data["hydra:member"].filter((element) => {
            return element["@id"] !== editData.obj?.owner["@id"];
          })
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log(editData);
        setDataSt({
          ...iniData,
          static_owner: editData.ownerName,
        });
        setLoading(false);
      });
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        scroll="paper"
        color="primary"
      >
        <DialogTitle className="dialog-title">
          Traspaso de Propietario
          <Tooltip title="Cerrar" placement="top">
            <IconButton
              aria-label="cerrar-form-data-client-sale"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers>
          <Stack spacing={2}>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "8rem",
                  background: "#f2f2f2",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Stack
                  direction="row"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack
                    direction="row"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ display: "inline-block" }}>
                      <strong>Actual Propietario</strong>
                      <Box
                        component={"span"}
                        sx={{
                          backgroundColor: "#ebebeb",
                          borderRadius: "12px",
                          textAlign: "center",
                          padding: "8px",
                          display: "block",
                          maxWidth: "120px",
                          margin: "5px auto",
                        }}
                      >
                        {dataSt.static_owner}
                      </Box>
                    </Typography>
                  </Stack>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <TrendingFlatRoundedIcon />
                  </Typography>
                  {Owner.length <= 0 ? (
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      No existen propietarios disponibles
                    </Typography>
                  ) : (
                    <FormControl
                      variant="standard"
                      sx={{ minWidth: "120px", width: "260px" }}
                      disabled={loading}
                    >
                      <InputLabel id="country">Listado Propietarios</InputLabel>

                      <Select
                        labelId="state-ct-select-label-equipo"
                        id="state-ct-select-equipo"
                        value={dataSt.owner}
                        onChange={handleOwner}
                        label="model"
                      >
                        {Owner.map((ele) => {
                          return (
                            <MenuItem key={ele.id} value={ele["@id"]}>
                              {ele.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {Owner.length <= 0 ? undefined : (
                    <TextField
                      variant="standard"
                      label={`Precio de Venta`}
                      required
                      type="number"
                      disabled={loading}
                      value={dataSt.value}
                      onChange={handleValue}
                      autoComplete="false"
                      error={dataValid.value.error}
                      helperText={dataValid.value.errorText}
                      sx={{ width: "160px" }}
                    />
                  )}
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es"
                  >
                    <DatePicker
                      label="Fecha de transferencia"
                      value={dayjs(dataSt.transferAt)}
                      maxDate={dayjs("9999")}
                      onChange={(newValue) =>
                        setDataSt({ ...dataSt, transferAt: newValue })
                      }
                      autoComplete="false"
                      slotProps={{
                        textField: {
                          variant: "standard",
                          fullWidth: true,
                          format: "DD-MM-YYYY",
                        },
                      }}
                      sx={{ minWidth: "120px", width: "260px" }}
                    />
                  </LocalizationProvider>
                </Stack>
                <Stack>
                  <TextField
                    variant="standard"
                    fullWidth={true}
                    label={`Factura de transferencia`}
                    required
                    type="text"
                    disabled={loading}
                    value={dataSt.invoiceTransfer}
                    onChange={handleInvoiceTransfer}
                    autoComplete="false"
                  />
                </Stack>
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ borderRadius: 0 }}
            color="inherit"
            disabled={loading}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{
              borderRadius: 0,
              background: "#212544",
            }}
            loading={loading}
            onClick={handleSubmit}
          >
            Aceptar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
