import axios from "axios";
import { clearStorage, getJWTFromStorage } from "./localStorage.Container";

const instance = axios.create({
  baseURL: process.env.REACT_APP_HOST_API,
  headers: {
    Accept: "application/json, application/ld+json",
  },
});

instance.interceptors.request.use(
  (config) => {
    if (config.url === "/auth") {
      config.headers = {
        ...config.headers,
        "Content-Type": "application/json",
      };
    } else {
      const tokenJWT = getJWTFromStorage();
      if (tokenJWT) {
        config.headers = {
          ...config.headers,
          "Content-Type":
            config.method === "patch"
              ? "application/merge-patch+json"
              : "application/json",
          Authorization: `Bearer ${tokenJWT}`,
        };
      } else {
        config.headers = {
          ...config.headers,
          "Content-Type":
            config.method === "pacth"
              ? "application/merge-patch+json"
              : "application/json",
        };
      }
    }

    config.params = {
      ...config.params,
    };
    return { ...config };
  },
  (error) => {
    return Promise.reject(error);
  }
);

const intercepResponseError = async (error) => {
  if (error.config.url !== "/auth") {
    if (error.response?.status === 401) {
      console.log("No se ha recuperado la session. Usuario inactivo.", error);
      clearStorage();
      window.location.href = "/login";
      return Promise.reject(error);
    } else if (error.response?.status === 403) {
      console.log("Sin permisos para realizar la peticion.", error);
      clearStorage();
      window.location.href = "/login";
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
};

instance.interceptors.response.use((response) => {
  return Promise.resolve(response);
}, intercepResponseError);

const instanceLogin = axios.create({
  baseURL: process.env.REACT_APP_HOST_API_SECURITY,
});

instanceLogin.interceptors.request.use(
  (config) => {
    config.params = {
      ...config.params,
    };
    return { ...config };
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const serviContainersApiCXN = instance;
