// import { ROUTES_APP } from "../utils/Routes";
// import { clearStorage, getDataJWTFromStorage } from "./localStorage.Container";
// import { redirect } from "react-router";

// export const ROLES = {
//   admin: { tag: "ROLE_ADMIN", label: "ADMINISTRADOR SISTEMA", parents: [] },
//   operario: { tag: "ROLE_OPERARIO", label: "OPERARIO", parents: [] },
//   vendedor: { tag: "ROLE_VENDEDOR", label: "VENDEDOR", parents: [] },
//   manager: { tag: "ROLE_MANAGER", label: "GESTOR", parents: ["ROLE_ADMIN"] },
// };

// const getSubRoles = (rolesFather) => {
//   if (rolesFather.includes(ROLES.admin.tag)) {
//     return [ROLES.manager.tag];
//   } else {
//     return [];
//   }
// };

// export function isAccess(roleAcces) {
//   if (!getDataJWTFromStorage()) {
//     return false;
//   }
//   const rolesUser = getDataJWTFromStorage().roles;
//   if (rolesUser.includes(roleAcces)) {
//     return true;
//   } else {
//     return (
//       getSubRoles(rolesUser).length !== 0 &&
//       getSubRoles(rolesUser).includes(roleAcces)
//     );
//   }
// }

// export function isAccessPermission(permission) {
//   if (!getDataJWTFromStorage()) {
//     return false;
//   }
//   return getDataJWTFromStorage().permission === permission;
// }

// export const Logout = () => {
//   clearStorage();
//   redirect(ROUTES_APP.login);
// };

import { ROUTES_APP } from "../utils/Routes";
import { clearStorage, getDataJWTFromStorage } from "./localStorage.Container";
import { redirect } from "react-router";

export const ROLES = {
  admin: { tag: "ROLE_ADMIN", label: "ADMINISTRADOR SISTEMA", parents: [] },
  operario: { tag: "ROLE_OPERARIO", label: "OPERARIO", parents: [] },
  vendedor: { tag: "ROLE_VENDEDOR", label: "VENDEDOR", parents: [] },
  manager: { tag: "ROLE_MANAGER", label: "GESTOR", parents: ["ROLE_ADMIN"] },
};

const getSubRoles = (rolesFather) => {
  if (rolesFather.includes(ROLES.admin.tag)) {
    return [ROLES.manager.tag];
  } else {
    return [];
  }
};

export function isAccess(roleAcces) {
  if (!getDataJWTFromStorage()) {
    return null; 
  }

  const rolesUser = getDataJWTFromStorage().roles;

  if (rolesUser.includes(roleAcces)) {
    return roleAcces; 
  } else {
    const subRoles = getSubRoles(rolesUser);
    if (subRoles.length !== 0 && subRoles.includes(roleAcces)) {
      return roleAcces; 
    }
  }

  return null; 
}

export function isAccessPermission(permission) {
  if (!getDataJWTFromStorage()) {
    return null; 
  }

  const userPermission = getDataJWTFromStorage().permission;

  if (userPermission === permission) {
    return userPermission; 
  }

  return null; 
}

export function getUserId() {

  if (!getDataJWTFromStorage()) {
    return null; 
  }

  const userId = getDataJWTFromStorage().userId;

  console.log ( "este es mi console log", userId );
 
  return userId;
}

export const Logout = () => {
  clearStorage();
  redirect(ROUTES_APP.login);
};
