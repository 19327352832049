import { Snackbar, Alert } from "@mui/material";
import { useState } from "react";
import { createContext } from "react";
import { useNavigate } from "react-router-dom";
import { clearStorage, getDataJWTFromStorage } from "../auth/localStorage.Container";
import { Slide } from "@mui/material";
import { ROUTES_APP } from "../utils/Routes";

export const AppContext = createContext();

const initialNotificationApp = {
  open: false,
  msj: "",
  severity: "info",
};

export default function AppProvider({ children }) {
  const [notificationApp, setNotificationApp] = useState(
    initialNotificationApp
  );
  const [_, setSession] = useState(getDataJWTFromStorage());

  const navigate = useNavigate();

  const showNotificationApp = (msj, severity) => {
    setNotificationApp({
      open: true,
      msj: msj,
      severity: severity,
    });
  };

  function closeNotificationApp(e, reason) {
    if (reason === "clickaway") {
      return;
    }
    setNotificationApp({...notificationApp, open: false});
  }

  // const onSession = (redirectFail = "/login", granted) => {
  //   if (getDataJWTFromStorage() ) {

  //   } else {
  //     clearStorage();
  //     navigate(redirectFail);
  //   }
  //   return getDataJWTFromStorage();
  // };

  const onLogin = () => {
    setSession(getDataJWTFromStorage());
    navigate(ROUTES_APP.home);
  };

  const onLogout = () => {
    setSession();
    clearStorage();
    navigate(ROUTES_APP.login);
  };

  return (
    <AppContext.Provider value={{ onLogin, onLogout, showNotificationApp }}>
      {children}
      <Snackbar
        open={notificationApp.open}
        onClose={closeNotificationApp}
        autoHideDuration={4000}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={notificationApp.severity} sx={{ width: "100%" }}>
          {notificationApp.msj}
        </Alert>
      </Snackbar>
    </AppContext.Provider>
  );
}
