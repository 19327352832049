import React from "react";
import HomePicture from "./HomePicture";
import { useContext } from "react";
import { AppContext } from "../../Providers/AppProvider";
import { ROLES, isAccess } from "../../auth/security.ServiContainers";


function HomeHome() {
  const { onLogout } = useContext(AppContext);

  if (!isAccess(ROLES.manager.tag)) {
    onLogout();
    return;
  }


  return (
    <div className="homehome">
      
      <HomePicture />
    </div>
  );
}

export default HomeHome;
