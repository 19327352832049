import isJWT from 'validator/es/lib/isJWT';

const SERVICONTAINERS_STORAGE_KEY = "servicontainers";
const SERVICONTAINERS_STORAGE_DATA = {
    accessAuth: ""
};

export const clearStorage = () => {
    localStorage.setItem(SERVICONTAINERS_STORAGE_KEY, JSON.stringify(SERVICONTAINERS_STORAGE_DATA));
};

const getDataJWT = token => {
    return JSON.parse(window.atob(token.split('.')[1].replace('-', '+').replace('_', '/')));
};

const isValidJWT = token => {
    return (
        isJWT(token)
        && getDataJWT(token).username !== undefined
        && getDataJWT(token).roles !== undefined
    );
};

const isValidStorage = (stg, includeJWT = true) => {
    return includeJWT ? (
        stg !== undefined
        && stg.accessAuth !== undefined
        && isValidJWT(stg.accessAuth)
    ) : (
        stg !== null
        && stg?.accessAuth !== undefined
    );
};

export const getDataJWTFromStorage = () => {
    const stg = JSON.parse(localStorage.getItem(SERVICONTAINERS_STORAGE_KEY));
    if (stg === null) {
        return false;
    }
    return isValidStorage(stg) && getDataJWT(stg.accessAuth);
};

export const getJWTFromStorage = () => {
    const stg = JSON.parse(localStorage.getItem(SERVICONTAINERS_STORAGE_KEY));
    if (stg === null) return false;
    return isValidStorage(stg) && stg.accessAuth;
};

export const setJWTFromStorage = token => {
    const stg = JSON.parse(localStorage.getItem(SERVICONTAINERS_STORAGE_KEY));
    console.log(stg);

    if (isValidStorage(stg, false) && isValidJWT(token)) {
        localStorage.setItem(SERVICONTAINERS_STORAGE_KEY, JSON.stringify({ ...stg, accessAuth: token }));
        return true;
    } else {
        return false;
    }
};

export const getCurrentPathFromStorage = () => {
    const stg = JSON.parse(localStorage.getItem(SERVICONTAINERS_STORAGE_KEY));
    return isValidStorage(stg) && stg.currentPath;
};

export const setCurrentPathFromStorage = path => {
    let stg = JSON.parse(localStorage.getItem(SERVICONTAINERS_STORAGE_KEY));
    if (stg === null) {
        clearStorage();
        stg = JSON.parse(localStorage.getItem(SERVICONTAINERS_STORAGE_KEY));
    }
    if (isValidStorage(stg)) {
        localStorage.setItem(SERVICONTAINERS_STORAGE_KEY, JSON.stringify({ ...stg, currentPath: path }));
        return true;
    } else {
        return false;
    }
};
