import * as React from "react";
import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { QRCodeCanvas } from "qrcode.react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import dayjs from "dayjs";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import ViewPDFInvoiceM from "./ViewPDFInvoiceM";
import ViewPDFDiM from "./ViewPDFDiM";
import ViewPhotosContainerM from "./viewPhotosContainerM";
import { getUserRoleData } from './../../../utils/Roles';

const iniData = {
  codeModule: "",
  stateName: "",
  ownerName: "",
  model: "",
  priceList:"",
  totalCosts: "",
  place_countries: "",
  profitAsoc: "",
  weight: "",
  createAt: dayjs().add(1, "d").toDate(),
  di: "",
  invoice: "",
  factoryYear: "",
  description: "",
  invoicePdfIn: "",
  diPdfIn: "",
  photo1: "",
  photo2: "",
  photo3: "",
  photo4: "",
  photo5: "",
  priceSaleEsp: "",
  saleEspAt: dayjs().add(1, "d").toDate(),
  outputAt: dayjs().add(1, "d").toDate(),
};

export default function ViewModule({ open, onClose, viewDataModule }) {
  const [dataSt, setDataSt] = React.useState(iniData);
  console.log(viewDataModule)
  const [loading, setLoading] = React.useState(false);
  const [openViewInvoicePdf, setOpenViewInvoicePdf] = React.useState(false);
  const [openViewDiPdf, setOpenViewDiPdf] = React.useState(false);
  const [openViewPhotoContainer, setOpenViewPhotoContainer] =
    React.useState(false);
  const [objectImg, setObjectImg] = React.useState({
    id: "",
    photo: "",
  });
  const [selectedPhoto, setSelectedPhoto] = React.useState(null);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [rolesAppValue, setRolesAppValue] = React.useState(null);

  const handleClose = () => {
    setDataSt(iniData);
    setLoading(false);
    onClose();
  };
  const handleViewPhotoContainer = (photoName) => {
    if (!openViewPhotoContainer) {
      setOpenViewPhotoContainer(true);
    }
    setObjectImg({
      id: viewDataModule.obj?.container.id,
      photo: photoName,
    });
  };

  const handleCloseViewPhotoContainer = () => {
    setOpenViewPhotoContainer(false);
  };

  const handleViewInvoicePdf = () => {
    if (!openViewInvoicePdf) {
      setOpenViewInvoicePdf(true);
    }
  };

  const handleCloseViewInvoicePdf = () => {
    setOpenViewInvoicePdf(false);
  };

  const handleViewDiPdf = () => {
    if (!openViewDiPdf) {
      setOpenViewDiPdf(true);
    }
  };

  const handleCloseViewDiPdf = () => {
    setOpenViewDiPdf(false);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const { rolesApp, country } = await getUserRoleData();
        console.log(rolesApp, country);
        setRolesAppValue(rolesApp); // Actualizar rolesAppValue con el valor obtenido
        setDataLoaded(true); // Indicamos que los datos se han cargado
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }
    const newPhotos = [];
    for (
      let i = 0;
      i < viewDataModule.obj?.container.photos.length && i < 5;
      i++
    ) {
      newPhotos.push(viewDataModule.obj?.container.photos[i]);
    }

    console.log(viewDataModule);
    setDataSt({
      codeModule: viewDataModule.codeModule,
      stateName: viewDataModule.stateName,
      ownerName: viewDataModule.ownerName,
      model: viewDataModule.model,
      priceList: viewDataModule.obj?.container?.model.priceList,
      totalCosts: viewDataModule.totalCosts,
      place_countries: viewDataModule.place_countries,
      weight: viewDataModule.weight,
      profitAsoc: viewDataModule.profitAsoc,
      createAt: viewDataModule.createAt,
      factoryYear: viewDataModule.obj?.container.factoryYear,
      invoice: viewDataModule.invoice,
      di: viewDataModule.di,
      description: viewDataModule.description,
      priceSaleEsp: viewDataModule.priceSaleEsp,
      saleEspAt: new Date(viewDataModule.saleEspAt),

      outputAt: new Date(viewDataModule.outputAt),
      // id: viewDataModule.obj?.container.id,
      pdfInvoice: viewDataModule.obj?.container.invoicePdf,
      pdfDi: viewDataModule.obj?.container.diPdf,
      ...newPhotos.reduce((acc, photos, index) => {
        acc[`photo${index + 1}`] = photos;
        return acc;
      }, {}),
    });
  }, [open, dataLoaded]);

  if (!open) {
    return undefined;
  }

  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL);
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = `QR_Code${viewDataModule.id}.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  return (
    <React.Fragment>
 <Dialog open={open} fullWidth maxWidth="sm" scroll="paper" color="primary">
    <DialogTitle className="dialog-title" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {`Vista del Módulo `}
          <div style={{ marginLeft: 'auto', fontSize: '1rem', fontWeight: 'bold' }}>
          <Typography>
                <strong>Precio de lista: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.priceList}
                </Box>
              </Typography>
      </div>
          <Tooltip title="Cerrar" placement="top">
            <IconButton
              aria-label="cerrar-form-data-client-sale"
              disabled={loading}
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers>
          <>
            <Stack
              direction="column"
              spacing={2}
              alignItems="initial"
              sx={{ m: 3 }}
            >
              <Typography>
                <strong>Código: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.codeModule}
                </Box>
              </Typography>

              <Typography>
                <strong>Estado: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.stateName}
                </Box>
              </Typography>
              <Typography>
                <strong>DI: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.di}
                </Box>
              </Typography>
              <Typography>
                <strong>Factura: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.invoice}
                </Box>
                {dataSt.pdfInvoice ? (
                  <IconButton
                    variant="text"
                    color="primary"
                    onClick={() => handleViewInvoicePdf()}
                  >
                    <Tooltip title="Ver" placement="top">
                      <VisibilitySharpIcon />
                    </Tooltip>
                  </IconButton>
                ) : (
                  ": No hay pdf asociado a esta Factura"
                )}
              </Typography>
              <Typography>
                <strong>DI: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.di}
                </Box>
                {dataSt.pdfDi ? (
                  <IconButton
                    variant="text"
                    color="primary"
                    onClick={() => handleViewDiPdf()}
                  >
                    <Tooltip title="Ver" placement="top">
                      <VisibilitySharpIcon />
                    </Tooltip>
                  </IconButton>
                ) : (
                  ": No hay pdf asociado a este DI"
                )}
              </Typography>
              <Typography>
                <strong>Propietarios: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.ownerName}
                </Box>
              </Typography>

              <Typography>
                <strong>Modelo: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.model}
                </Box>
              </Typography>
              {rolesAppValue!== 2 && (
                <>
              <Typography>
                <strong>Total Gastos: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.totalCosts}
                </Box>
              </Typography>
              </>
            )}

              <Typography>
                <strong>Lugar: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.place_countries}
                </Box>
              </Typography>
            </Stack>

            <Stack
              direction="column"
              spacing={2}
              alignItems="initial"
              sx={{ m: 3 }}
            >
              <Typography>
                <strong>Margen Ganancial: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.profitAsoc}
                </Box>
              </Typography>

              <Typography>
                <strong>Peso: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.weight}
                </Box>
              </Typography>
              <Typography>
                <strong>Precio de Venta: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.priceSaleEsp}
                </Box>
              </Typography>
              <Typography>
                <strong>Fecha Internación: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.createAt.toString()}
                </Box>
              </Typography>
              <Typography>
                <strong>Año de fabricación: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.factoryYear}
                </Box>
              </Typography>
              <Typography>
                <strong>Fecha de Venta: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.saleEspAt.toDateString()}
                </Box>
              </Typography>

              <Typography>
                <strong>Fecha de Egreso: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.outputAt.toDateString()}
                </Box>
              </Typography>
              <Typography>
                <strong>Observaciones: </strong>

                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    paddingX: "10px",
                    paddingY: "8px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    marginRight: "2px",
                    display: "inline-block",
                  }}
                >
                  {dataSt.description === ""
                    ? "No hay Observaciones"
                    : dataSt.description}
                </Box>
              </Typography>
              <Stack
                direction="column"
                spacing={2}
                alignItems="initial"
                sx={{ m: 3 }}
              >
                {viewDataModule.obj?.container.photos &&
                  viewDataModule.obj?.container.photos.map((photo, index) => (
                    <div key={index}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Typography>
                          <strong>Foto {index + 1}: </strong>
                        </Typography>
                        <Box
                          component={"span"}
                          sx={{
                            backgroundColor: "#ebebeb",
                            borderRadius: "12px",
                            textAlign: "center",
                            padding: "8px",
                          }}
                        >
                          <IconButton
                            variant="text"
                            aria-label={`view-photo`}
                            color="primary"
                            onClick={() => handleViewPhotoContainer(photo)}
                          >
                            <Tooltip title="Ver" placement="top">
                              <VisibilitySharpIcon />
                            </Tooltip>
                          </IconButton>
                        </Box>
                      </Box>
                    </div>
                  ))}
              </Stack>
            </Stack>

            <Stack
              direction="row"
              spacing={3}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ my: "2vh" }}
            >
              <QRCodeCanvas
                value={`${process.env.REACT_APP_HOST_FRONT}/modulo/view/${viewDataModule.id}`}
                id="qrCodeEl"
              />
            </Stack>

            <Stack
              direction="row"
              spacing={3}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                variant="outlined"
                startIcon={<CloudDownloadIcon />}
                sx={{
                  color: "#fff",
                  backgroundColor: "#004590",
                }}
                onClick={downloadQRCode}
              >
                Descargar Código QR
              </Button>
            </Stack>
          </>
        </DialogContent>
      </Dialog>
      {dataSt.pdfInvoice && (
        <ViewPDFInvoiceM
          open={openViewInvoicePdf}
          onClose={handleCloseViewInvoicePdf}
          viewDataModule={viewDataModule}
        />
      )}
      {dataSt.pdfDi && (
        <ViewPDFDiM
          open={openViewDiPdf}
          onClose={handleCloseViewDiPdf}
          viewDataModule={viewDataModule}
        />
      )}

      {objectImg.id !== "" ? (
        <ViewPhotosContainerM
          open={openViewPhotoContainer}
          onClose={handleCloseViewPhotoContainer}
          viewDataModule={objectImg}
        />
      ) : null}
    </React.Fragment>
  );
}
