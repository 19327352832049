// import * as React from "react";
// import { Box, Typography, Backdrop, Tab, Paper, Stack } from "@mui/material";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
// import CircularProgress from "@mui/material/CircularProgress";
// import Container from "./Container/Container";
// import SaleContainer from "./SaleContainer/SaleContainer";
// import RentContainer from "./RentContainer/RentContainer";
// import {getUserRoleData} from "./../../utils/Roles";

// export default function BasicTabs() {
//   const [valueTab, setValueTab] = React.useState("tab1");

//   const handleChangeTab = (event, newValue) => {
//     setValueTab(newValue);
//   };

//   return (
//     <React.Fragment>
//       <TabContext value={valueTab}>
//         <Box sx={{ width: "100%" }}>
//           <TabList
//             onChange={handleChangeTab}
//             textColor="primary"
//             indicatorColor="primary"
//             variant="scrollable"
//             allowScrollButtonsMobile
//             scrollButtons="auto"
//             aria-label="scrollable auto tabs example"
//           >
//             <Tab
//               value="tab1"
//               label="CONTENEDORES"
//               sx={{ marginLeft: 2, color: "primary" }}
//             />
            
//             <Tab
//               value="tab2"
//               label="VENTA DE CONTENDORES"
//               sx={{ marginLeft: 2, color: "primary" }}
//             />
//             <Tab
//               value="tab3"
//               label="RENTA DE CONTENDORES"
//               sx={{ marginLeft: 2, color: "primary" }}
//             />
//           </TabList>
//         </Box>
//         <Paper>
//           <Box>
//             <TabPanel value="tab1" sx={{ height: "auto" }}>
//               <Container />
//             </TabPanel>
//             <TabPanel value="tab2" sx={{ height: "auto" }}>
//               <SaleContainer />
//             </TabPanel>
//             <TabPanel value="tab3" sx={{ height: "auto" }}>
//               <RentContainer />
//             </TabPanel>
//           </Box>
//         </Paper>
//       </TabContext>
//     </React.Fragment>
//   );
// }
import * as React from "react";
import { Box, Tab, Paper,Backdrop, } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "./Container/Container";
import SaleContainer from "./SaleContainer/SaleContainer";
import RentContainer from "./RentContainer/RentContainer";
import { getUserRoleData } from "./../../utils/Roles";

export default function BasicTabs() {
  const [valueTab, setValueTab] = React.useState("tab1");
  const [rolesApp, setRolesApp] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchRoles = async () => {
      try {
        const userData = await getUserRoleData();
        setRolesApp(userData.rolesApp);
      } catch (error) {
        console.error("Error fetching user roles", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRoles();
  }, []);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  // if (loading) {
  //   return (
  //     <Backdrop open={true}>
  //       <CircularProgress color="inherit" />
  //     </Backdrop>
  //   );
  // }

  return (
    <React.Fragment>
      <TabContext value={valueTab}>
        <Box sx={{ width: "100%" }}>
          <TabList
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              value="tab1"
              label="CONTENEDORES"
              sx={{ marginLeft: 2, color: "primary" }}
            />
               {/* {( rolesApp === 0  || rolesApp === 1) && (
          <> */}
                <Tab
                  value="tab2"
                  label="VENTA DE CONTENDORES"
                  sx={{ marginLeft: 2, color: "primary" }}
                  disabled={rolesApp == 2}
                />
                <Tab
                  value="tab3"
                  label="RENTA DE CONTENDORES"
                  sx={{ marginLeft: 2, color: "primary" }}
                  disabled={rolesApp == 2}
                />
              {/* </>
            )} */}
          </TabList>
        </Box>
        <Paper>
          <Box>
            <TabPanel value="tab1" sx={{ height: "auto" }}>
              <Container />
            </TabPanel>
         
                <TabPanel value="tab2" sx={{ height: "auto" }}>
                  <SaleContainer />
                </TabPanel>
                <TabPanel value="tab3" sx={{ height: "auto" }}>
                  <RentContainer />
                </TabPanel>
           
          </Box>
        </Paper>
      </TabContext>
    </React.Fragment>
  );
}

