import * as React from "react";
import { Box, Typography, Backdrop, Tab, Paper, Stack } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabOperationsContainers from "./TabOperationsContainers/TabOperationsContainers";
import TabXContainer from "./TabXContainer/TabXContainer";
import TabXModule from "./TabXModule/TabXModule";
import TabContability from "./TabContability/TabContability";
import TabOperationsOwner from "./TabOperationOwner/TabOperationsOwner";

export default function BasicTabs() {
  const [valueTab, setValueTab] = React.useState("tab1");

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <React.Fragment>
      <TabContext value={valueTab}>
        <Box sx={{ width: "100%" }}>
          <TabList
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              value="tab1"
              label="HISTORIAL CONTENEDOR"
              sx={{ marginLeft: 2, color: "primary" }}
            />
            <Tab
              value="tab2"
              label="POR CONTENEDOR"
              sx={{ marginLeft: 2, color: "primary" }}
            />
            <Tab
              value="tab3"
              label="POR MÓDULO"
              sx={{ marginLeft: 2, color: "primary" }}
            />
            <Tab
              value="tab4"
              label="Contabilidad"
              sx={{ marginLeft: 2, color: "primary" }}
            />
            <Tab
              value="tab5"
              label="POR POPIETARIO"
              sx={{ marginLeft: 2, color: "primary" }}
            />
          </TabList>
        </Box>
        <Paper>
          <Box>
            <TabPanel value="tab1" sx={{ height: "auto" }}>
              <TabOperationsContainers />
            </TabPanel>
            <TabPanel value="tab2" sx={{ height: "auto" }}>
              <TabXContainer />
            </TabPanel>
            <TabPanel value="tab3" sx={{ height: "auto" }}>
              <TabXModule />
            </TabPanel>
            <TabPanel value="tab4" sx={{ height: "auto" }}>
              <TabContability />
            </TabPanel>
            <TabPanel value="tab5" sx={{ height: "auto" }}>
              <TabOperationsOwner />
            </TabPanel>
          </Box>
        </Paper>
      </TabContext>
    </React.Fragment>
  );
}
