import * as React from "react";
import {
  // Button,
  Stack,
  // Dialog,
  // DialogTitle,
  // DialogContent,
  IconButton,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { serviContainersApiCXN } from "../../../auth/axios.Container";
import dayjs from "dayjs";
import Skeleton from "@mui/material/Skeleton";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import ViewPDFInvoice from "./viewPDFInvoice";
import ViewPDFDi from "./ViewPDFDi";
import ViewPhotosContainer from "./viewPhotosContainer";
import { getUserRoleData } from './../../../utils/Roles';

const iniData = {
  id: "",
  codeExt: "",
  codeInt: "",
  model: "",
  provider: "",
  placeCountries: "",
  profitAsoc: "",
  state: "",
  owner: "",
  weight: 0,
  propertyContainerAsocs: [],
  factoryYear: dayjs().add(1, "d").toDate(),
  description: "",
  tacCode: "",
  typeCostAsocContainers: [],
  invoice: "",
  di: "",
  totalCosts: "",
  invoicePdfIn: "",
  diPdfIn: "",
  photo1: "",
  photo2: "",
  photo3: "",
  photo4: "",
  photo5: "",
  priceSaleEsp: "",
  saleEspAt: dayjs().add(1, "d").toDate(),
  outputAt: dayjs().add(1, "d").toDate(),
  priceList:"",
};

export default function ViewDirectContainer() {
  const [loading, setLoading] = React.useState(true);
  const [dataSt, setDataSt] = React.useState(iniData);
  const [openViewInvoicePdf, setOpenViewInvoicePdf] = React.useState(false);
  const [openViewDiPdf, setOpenViewDiPdf] = React.useState(false);
  const [openViewPhotoContainer, setOpenViewPhotoContainer] =
    React.useState(false);
  const [objectImg, setObjectImg] = React.useState({
    id: "",
    photo: "",
  });
  // const [selectedPhoto, setSelectedPhoto] = React.useState(null);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [rolesAppValue, setRolesAppValue] = React.useState(null);

  const { id } = useParams();

  const handleViewPhotoContainer = (photoName) => {
    if (!openViewPhotoContainer) {
      setOpenViewPhotoContainer(true);
    }
    setObjectImg({
      id: dataSt.id,
      photo: photoName,
    });
  };

  const handleCloseViewPhotoContainer = () => {
    setOpenViewPhotoContainer(false);
  };

  const handleViewInvoicePdf = () => {
    if (!openViewInvoicePdf) {
      setOpenViewInvoicePdf(true);
    }
  };

  const handleCloseViewInvoicePdf = () => {
    setOpenViewInvoicePdf(false);
  };

  const handleViewDiPdf = () => {
    if (!openViewDiPdf) {
      setOpenViewDiPdf(true);
    }
  };

  const handleCloseViewDiPdf = () => {
    setOpenViewDiPdf(false);
  };
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const { rolesApp, country } = await getUserRoleData();
        console.log(rolesApp, country);
        setRolesAppValue(rolesApp); // Actualizar rolesAppValue con el valor obtenido
        setDataLoaded(true); // Indicamos que los datos se han cargado
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    setLoading(true);
    serviContainersApiCXN
      .get(`${process.env.REACT_APP_HOST_API}/containers/${id}`)
      .then((response) => {
        console.log(response.data);
        const newPhotos = [];
        for (let i = 0; i < response.data.photos.length && i < 5; i++) {
          newPhotos.push(response.data.photos[i]);
        }
        setDataSt({
          codeExt: response.data.codeExt,
          codeInt: response.data.codeInt,
          profitAsoc: response.data.profitAsoc,
          provider: response.data.provider.name,
          placeCountries: response.data.placeCountry.name,
          state: response.data.state.name,
          model: response.data.model.name,
          priceList: response.data.model?.priceList,
          owner: response.data.owner.name,
          factoryYear: dayjs().year(response.data.factoryYear),
          propertyContainerAsocs: response.data.propertyContainerAsocs,
          weight: response.data.weight,
          tacCode: response.data.tacCode,
          description: response.data.description,
          createAt: response.data.createAt,
          typeCostAsocContainers: response.data.typeCostAsocContainers,
          invoice: response.data.invoice,
          di: response.data.di,
          id: response.data.id,
          pdfInvoice: response.data.invoicePdf,
          pdfDi: response.data.diPdf,
          priceSaleEsp: response.data.priceSaleEsp,
          photosN: response.data.photos,
          saleEspAt: new Date(response.data.saleEspAt),
          outputAt: new Date(response.data.outputAt),
          ...newPhotos.reduce((acc, photos, index) => {
            acc[`photo${index + 1}`] = photos;
            return acc;
          }, {}),
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoading(false);
      });
  }, [dataLoaded]);
  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          maxWidth: 900,
          background: "#fbfbfb",
          transform: "translateY(-60px)",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            color: "#fff",
            backgroundColor: "#004590",
            textAlign: "center",
          }}
        >
          Vista del contenedor
        </Typography>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack
              direction="column"
              spacing={2}
              alignItems="initial"
              sx={{ m: 3 }}
            >
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
            </Stack>

            <Stack
              direction="column"
              spacing={2}
              alignItems="initial"
              sx={{ m: 3 }}
            >
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
              <Skeleton variant="rectangular" width={320} height={25} />
            </Stack>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack
              direction="column"
              spacing={2}
              alignItems="initial"
              sx={{ m: 3 }}
            >
              <Typography>
                <strong>Código Externo: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.codeExt}
                </Box>
              </Typography>

              <Typography>
                <strong>Código Interno: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.codeInt}
                </Box>
              </Typography>

              <Typography>
                <strong>Factura: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.invoice}
                </Box>
                {dataSt.pdfInvoice ? (
                  <IconButton
                    variant="text"
                    color="primary"
                    onClick={() => handleViewInvoicePdf()}
                  >
                    <Tooltip title="Ver" placement="top">
                      <VisibilitySharpIcon />
                    </Tooltip>
                  </IconButton>
                ) : (
                  ": No hay pdf asociado a esta Factura"
                )}
              </Typography>
              <Typography>
                <strong>DI: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.di}
                </Box>
                {dataSt.pdfDi ? (
                  <IconButton
                    variant="text"
                    color="primary"
                    onClick={() => handleViewDiPdf()}
                  >
                    <Tooltip title="Ver" placement="top">
                      <VisibilitySharpIcon />
                    </Tooltip>
                  </IconButton>
                ) : (
                  ": No hay pdf asociado a este DI"
                )}
              </Typography>
              <Typography>
                <strong>Ganacia Asociada: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.profitAsoc}
                </Box>
              </Typography>

              <Typography>
                <strong>Proveedor: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.provider}
                </Box>
              </Typography>

              <Typography>
                <strong>Lugar: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.placeCountries}
                </Box>
              </Typography>

              <Typography>
                <strong>Estado del Contenedor: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.state}
                </Box>
              </Typography>

              <Typography>
                <strong>Tipo de Contenedor: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.model}
                </Box>
              </Typography>
              <Typography>
                <strong>Propietario: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.owner}
                </Box>
              </Typography>

              <Typography>
                <strong>Peso: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.weight}
                </Box>
              </Typography>
              <Typography>
                <strong>Precio de Venta: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.priceSaleEsp}
                </Box>
              </Typography>
            </Stack>

            <Stack
              direction="column"
              spacing={2}
              alignItems="initial"
              sx={{ m: 3 }}
            >
              <Typography>
                <strong>Precio de lista: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.priceList}
                </Box>
              </Typography>

              <Typography>
                <strong>TATC: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.tacCode}
                </Box>
              </Typography>
              <Typography>
                <strong>Año de Fabricación: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.factoryYear["$y"]}
                </Box>
              </Typography>

              <Typography>
                <strong>Fecha de creado: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dayjs(dataSt.createAt).format("YYYY-MM-DD")}
                </Box>
              </Typography>
              <Typography>
                <strong>Fecha de Venta: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.saleEspAt.toDateString()}
                </Box>
              </Typography>

              <Typography>
                <strong>Fecha de Egreso: </strong>
                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  {dataSt.outputAt.toDateString()}
                </Box>
              </Typography>

              <Typography>
                <strong>Observaciones: </strong>

                <Box
                  component={"span"}
                  sx={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "12px",
                    textAlign: "center",
                    paddingX: "10px",
                    paddingY: "8px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    marginRight: "2px",
                    display: "inline-block",
                  }}
                >
                  {dataSt.description === ""
                    ? "No hay Observaciones"
                    : dataSt.description}
                </Box>
              </Typography>
              <Typography>
                <strong>Características Contenedor: </strong>
                {dataSt.propertyContainerAsocs.length === 0 ? (
                  <Box
                    component={"span"}
                    sx={{
                      backgroundColor: "#ebebeb",
                      borderRadius: "12px",
                      textAlign: "center",
                      padding: "8px",
                    }}
                  >
                    No hay Características asociadas
                  </Box>
                ) : (
                  dataSt.propertyContainerAsocs.map((item, index) => (
                    <Box
                      key={index}
                      component={"span"}
                      sx={{
                        backgroundColor: "#ebebeb",
                        borderRadius: "12px",
                        textAlign: "center",
                        padding: "8px",
                      }}
                    >
                      {item.propertyContainer.name}
                    </Box>
                  ))
                )}
              </Typography>
              {rolesAppValue!== 2 && (
                <>
              <Typography>
                <strong>Total de Costos Asociados: </strong>
                {dataSt.typeCostAsocContainers.length === 0 ? (
                  <Box
                    component={"span"}
                    sx={{
                      backgroundColor: "#ebebeb",
                      borderRadius: "12px",
                      textAlign: "center",
                      padding: "8px",
                    }}
                  >
                    0
                  </Box>
                ) : (
                  dataSt.typeCostAsocContainers.map((item, index) => (
                    <Box
                      key={index}
                      component={"span"}
                      sx={{
                        backgroundColor: "#ebebeb",
                        borderRadius: "12px",
                        textAlign: "center",
                        padding: "8px",
                      }}
                    >
                      {item.valueCost}
                    </Box>
                  ))
                )}
              </Typography>
              <Typography>
                <strong>Costos Asociados: </strong>
                {dataSt.typeCostAsocContainers.length === 0 ? (
                  <Box
                    component={"span"}
                    sx={{
                      backgroundColor: "#ebebeb",
                      borderRadius: "12px",
                      textAlign: "center",
                      padding: "8px",
                    }}
                  >
                    No hay costos asociados
                  </Box>
                ) : (
                  dataSt.typeCostAsocContainers.map((item, index) => (
                    <Box
                      key={index}
                      component={"span"}
                      sx={{
                        backgroundColor: "#ebebeb",
                        borderRadius: "12px",
                        textAlign: "center",
                        padding: "8px",
                      }}
                    >
                      {item.typeCost.name}
                    </Box>
                  ))
                )}
              </Typography>
              </>
              )}
              <Stack
                direction="column"
                spacing={2}
                alignItems="initial"
                sx={{ m: 3 }}
              >
                {dataSt.photosN &&
                  dataSt.photosN.map((photo, index) => (
                    <div key={index}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Typography>
                          <strong>Foto {index + 1}: </strong>
                        </Typography>
                        <Box
                          component={"span"}
                          sx={{
                            backgroundColor: "#ebebeb",
                            borderRadius: "12px",
                            textAlign: "center",
                            padding: "8px",
                          }}
                        >
                          <IconButton
                            variant="text"
                            aria-label={`view-photo`}
                            color="primary"
                            onClick={() => handleViewPhotoContainer(photo)}
                          >
                            <Tooltip title="Ver" placement="top">
                              <VisibilitySharpIcon />
                            </Tooltip>
                          </IconButton>
                        </Box>
                      </Box>
                    </div>
                  ))}
              </Stack>
            </Stack>
          </Box>
        )}
      </Box>
      {dataSt.pdfInvoice && (
        <ViewPDFInvoice
          open={openViewInvoicePdf}
          onClose={handleCloseViewInvoicePdf}
          viewDataContainer={dataSt}
        />
      )}
      {dataSt.pdfDi && (
        <ViewPDFDi
          open={openViewDiPdf}
          onClose={handleCloseViewDiPdf}
          viewDataContainer={dataSt}
        />
      )}
      {objectImg.id !== "" ? (
        <ViewPhotosContainer
          open={openViewPhotoContainer}
          onClose={handleCloseViewPhotoContainer}
          viewDataContainer={objectImg}
        />
      ) : null}
    </React.Fragment>
  );
}
