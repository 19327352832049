import * as React from "react";
import {
  Box,
  Stack,
  Divider,
  IconButton,
  Skeleton,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  esES,
} from "@mui/x-data-grid";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import FormTypeContainer from "./FormTypeContainer";
import { useContext } from "react";
import { AppContext } from "../../../Providers/AppProvider";
import { serviContainersApiCXN } from "../../../auth/axios.Container";

const iniData = (data) => {
  let ret = [];
  
  data.forEach((element) => {
    console.log(element)
    ret.push({
      id: element.id,
      description: element.description,
      name: element.name,
      priceList: element.priceList,
    });
  });
  return ret;
};

export default function DataGridDemo() {
  //const
  const [loading, setLoading] = React.useState(true);
  const [refreshTable, setRefreshTable] = React.useState(0);
  const [openFormData, setOpenFormData] = React.useState(false);
  const [formCreate, setFormCreate] = React.useState(true);

  const [selectionTab, setSelectionTab] = React.useState([]);
  const [openViewData, setOpenViewData] = React.useState(false);

  const [items, setItems] = React.useState([]);

  const { showNotificationApp } = useContext(AppContext);

  //for delete an element
  const handleDeleteData = async () => {
    return await Promise.all(
      selectionTab.map((element) => {
        return serviContainersApiCXN.delete(
          `${process.env.REACT_APP_HOST_API}/type_containers/${element}`
        );
      })
    )
      .then((ver) => {
        showNotificationApp("Se ha eliminado correctamente", "success");
        handleRefreshT();
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 500") {
          showNotificationApp(
            "Ha ocurrido un error durante la eliminación, esta propiedad esta siendo utilizada en un contendor",
            "error"
          );
        } else {
          showNotificationApp(
            "Ha ocurrido un error durante la eliminación",
            "error"
          );
        }

        console.log(err);
      });
  };

  const handleCloseFormData = () => {
    setOpenFormData(false);
    handleRefreshT();
  };

  const handleViewData = () => {
    if (selectionTab.length === 1) {
      setOpenViewData(true);
    }
  };

  const handleCreateData = () => {
    if (!formCreate) {
      setFormCreate(true);
    }
    setOpenFormData(true);
  };

  const handleUpdateData = () => {
    if (selectionTab.length === 1) {
      if (formCreate) {
        setFormCreate(false);
      }
      setOpenFormData(true);
    }
  };
  React.useEffect(() => {
    setLoading(true);
    serviContainersApiCXN
      .get(`${process.env.REACT_APP_HOST_API}/type_containers?pagination=false`)
      .then((response) => {
        setItems(iniData(response.data["hydra:member"]));
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        showNotificationApp("Error cargando datos del sistema", "error");
      })
      .finally(() => setLoading(false));
  }, [refreshTable]);

  const handleRefreshT = () => {
    setLoading(true);
    setRefreshTable(refreshTable === 0 ? 1 : 0);
    setSelectionTab([]);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const MenuToolBar = () => {
    return (
      <React.Fragment>
        <Stack
          direction="row"
          justifyContent="flex-end"
          divider={<Divider orientation="vertical" />}
        >
          <IconButton
            variant="text"
            aria-label="refresh"
            color="primary"
            onClick={handleRefreshT}
          >
            <Tooltip title="Actualizar Tabla" placement="top">
              <RefreshSharpIcon />
            </Tooltip>
          </IconButton>
          <Divider orientation="vertical" flexItem />
          <IconButton
            variant="text"
            aria-label="crear"
            color="primary"
            onClick={handleCreateData}
          >
            <Tooltip title="Nueva" placement="top">
              <AddSharpIcon />
            </Tooltip>
          </IconButton>
          <IconButton
            variant="text"
            aria-label="editar"
            color="primary"
            disabled={selectionTab.length !== 1}
            onClick={handleUpdateData}
          >
            <Tooltip title="Editar" placement="top">
              <EditSharpIcon />
            </Tooltip>
          </IconButton>

          <IconButton
            variant="text"
            color="error"
            disabled={selectionTab.length < 1}
            onClick={handleDeleteData}
          >
            <Tooltip title="Eliminar" placement="top">
              <DeleteSharpIcon />
            </Tooltip>
          </IconButton>
        </Stack>
      </React.Fragment>
    );
  };

  //menubar
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true }}
        />
        <MenuToolBar />
      </GridToolbarContainer>
    );
  };

  return (
    <React.Fragment>
      {loading ? (
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"400px"}
          animation="wave"
        />
      ) : (
        <React.Fragment>
          <Box sx={{ height: "60vh", width: "100%" }}>
            <DataGrid
              checkboxSelection
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              rows={items}
              columns={[
                { field: "name", headerName: "Nombre", width: 150 },
                { field: "priceList", headerName: "Precio de lista", width: 150 },
                { field: "description", headerName: "Descripción", flex: 1 },
              ]}
              initialState={{
                sorting: {
                  sortModel: [{ field: "name", sort: "desc" }],
                },
              }}
              components={{
                Toolbar: CustomToolbar,
              }}
              onRowSelectionModelChange={(newSelectionModel) => {
                setSelectionTab(newSelectionModel);
              }}
              rowSelectionModel={selectionTab}
            />
          </Box>
          <FormTypeContainer
            open={openFormData}
            onClose={handleCloseFormData}
            create={formCreate}
            editData={
              !formCreate && selectionTab.length === 1
                ? items.find((elem) => elem.id === selectionTab[0])
                : null
            }
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
