import * as React from "react";
import {
  Box,
  Stack,
  Divider,
  IconButton,
  Skeleton,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  esES,
} from "@mui/x-data-grid";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import NightShelterIcon from "@mui/icons-material/NightShelter";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import TodayIcon from "@mui/icons-material/Today";
import dayjs from "dayjs";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import FormContainer from "./FormContainer";
import ViewContainer from "./ViewContainer";
import ConverterModule from "./ConverterModule";
import DividerContainer from "./DividerContainer";
import FormEditCostContainer from "./FormEditCostContainer";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import Sale from "./Sale";
import Rent from "./Rent";
import { useContext } from "react";
import { serviContainersApiCXN } from "../../../auth/axios.Container";
import { AppContext } from "../../../Providers/AppProvider";
import FormOwners from "./FormOwners";
import { getUserId, isAccessPermission } from "../../../auth/security.ServiContainers";
import { ROLES, isAccess } from "../../../auth/security.ServiContainers";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import "primereact/resources/themes/md-light-indigo/theme.css";
import "./container.css";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getUserRoleData } from './../../../utils/Roles';

export function getTotalCosts(typeCostAsocContainers) {
  let sum = 0;
  typeCostAsocContainers.forEach((ele) => {
    sum += ele.valueCost;
  });
  return sum;
}


// 
const iniData1 = (data) => {
  console.log( 'Data completa', data);
    console.log('RolesApp', data.rolesApp);


  const ret = { obj: data };   

  return {
    rolesApp: ret.obj.rolesApp,
    country: ret.obj.areaCountry.name
  };  
};

function filterStateSeller ( containers ){
   
  const filterArray = containers.filter(elemento => 
    elemento.state == 'DISPONIBLE PARA VENTA' ||
    elemento.state == 'DISPONIBLE PARA ARRIENDO' ||
    elemento.state == 'DISPONIBLE PARA VENTA Y ARRIENDO'
  );
  
  return filterArray;
}

function filterCountryOperario ( containers, country ){
  console.log ( 'filter operario', {containers, country} );
  const filterArray = containers.filter(elemento => 
    elemento.obj.placeCountry.areaCountry.name == country
  );
  return filterArray;
}

const iniData = (data) => {
  let ret = [];
  data.forEach((element) => {
    console.log(element);
    ret.push({
      id: element.id,
      codeExt: element.codeExt,
      codeInt: element.codeInt,
      model: element.model?.name,
      provider: element.provider?.name,
      place_countries: element.placeCountry?.name,
      state: element.state?.name,
      totalCosts: getTotalCosts(element.typeCostAsocContainers),
      profitAsoc: `${element.profitAsoc * 100}%`,
      createAt: dayjs(element.createAt).format("DD/MM/YYYY"),
      saleEspAt: dayjs(element.saleEspAt).format("DD/MM/YYYY"),
      outputAt: dayjs(element.outputAt).format("DD/MM/YYYY"),
      // createAt: element.createAt,
      parentContainer: element.parentContainer,
      typeCostAsocContainers: element.typeCostAsocContainers,
      profitAsocContainer: element.profitAsoc * 100,
      ownerName: element.owner.name,
      owner: element.owner["@id"],
      factoryYear: element.factoryYear,
      propertyContainerAsocs: element.propertyContainerAsocs,
      weight: element.weight,
      description: element.description,
      tacCode: element.tacCode,
      invoice: element.invoice,
      saleEspAt: dayjs(element.saleEspAt).format("DD/MM/YYYY"),
      outputAt: dayjs(element.outputAt).format("DD/MM/YYYY"),
      // priceSaleEsp: element.parentContainer.priceSaleEsp,
      priceSaleEsp: element.priceSaleEsp,
      di: element.di,
      obj: element,
    });
  });

  return ret;
};

const filtersDef = {
  codeInt: { value: null, matchMode: FilterMatchMode.CONTAINS },
  model: { value: null, matchMode: FilterMatchMode.CONTAINS },
  state: { value: null, matchMode: FilterMatchMode.CONTAINS },
  ownerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  codeExt: { value: null, matchMode: FilterMatchMode.CONTAINS },
  totalCosts: { value: null, matchMode: FilterMatchMode.CONTAINS },
  provider: { value: null, matchMode: FilterMatchMode.CONTAINS },
  place_countries: { value: null, matchMode: FilterMatchMode.CONTAINS },
  profitAsoc: { value: null, matchMode: FilterMatchMode.CONTAINS },
  weight: { value: null, matchMode: FilterMatchMode.CONTAINS },
  tacCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
  invoice: { value: null, matchMode: FilterMatchMode.CONTAINS },
  di: { value: null, matchMode: FilterMatchMode.CONTAINS },
  priceSaleEsp: { value: null, matchMode: FilterMatchMode.CONTAINS },
  description: { value: null, matchMode: FilterMatchMode.CONTAINS },
  outputAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
  saleEspAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
  factoryYear: { value: null, matchMode: FilterMatchMode.CONTAINS },
};

export default function DataGridDemo() {
  const [loading, setLoading] = React.useState(true);
  const [refreshTable, setRefreshTable] = React.useState(0);
  const [openFormData, setOpenFormData] = React.useState(false);
  const [formCreate, setFormCreate] = React.useState(true);
  const [selectionTab, setSelectionTab] = React.useState(null);
  const [openViewData, setOpenViewData] = React.useState(false);
  const [filters, setFilters] = React.useState(filtersDef);
  const [items, setItems] = React.useState([]);
  const [items1, setItems1] = React.useState([]);
  const [userId, setUserId] = React.useState([]);
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const [addCost, setAddCost] = React.useState(false);
  const [module, setModule] = React.useState(false);
  const [dividerContainer, setDividerContainer] = React.useState(false);
  const [saleContainer, setSaleContainer] = React.useState(false);
  const [rentContainer, setRentContainer] = React.useState(false);
  const [owners, setOwners] = React.useState(false);
  const { showNotificationApp } = useContext(AppContext);
  const dt = React.useRef(null);
  const [metaKey, setMetaKey] = React.useState(false);
  
  const [rolesAppValue, setRolesAppValue] = React.useState(null); // Estado para rolesApp
  const [userDataTCountry, setUserDataTCountry] = React.useState(''); 
  const cols = [
    { header: "Cod. Interno", field: "codeInt" },
    { header: "Tipo", field: "model" },
    { header: "Estado", field: "state" },
    { header: "Propietario", field: "ownerName" },
    { header: "Cod. Externo", field: "codeExt" },
    { header: "Total Gastos", field: "totalCosts" },
    { header: "Proveedor", field: "provider" },
    { header: "Lugar", field: "place_countries" },
    {
      header: "Ganancia Asociada",
      field: "profitAsoc",
    },
    { header: "Peso", field: "weight" },
    { header: "TATC", field: "tacCode" },
    { header: "Factura", field: "invoice" },
    { header: "DI", field: "di" },
    {
      header: "Precio de Venta",
      field: "priceSaleEsp",
    },
    {
      header: "Observaciones",
      field: "description",
    },

    {
      header: "Año fabricación",
      field: "factoryYear",
    },
    {
      header: "Fecha Internación",
      field: "createAt",
    },
    {
      header: "Fecha de Venta",
      field: "saleEspAt",
    },
    {
      header: "Fecha de Egreso",
      field: "outputAt",
    },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportCSV = (selectionOnly) => {
    console.log(dt);
    dt.current.exportCSV({ selectionOnly });
  };

  const handleOpenConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
    handleRefreshT();
  };

  const handleDeleteData = () => {
    return new Promise((resolve, reject) => {
      serviContainersApiCXN
        .delete(
          `${process.env.REACT_APP_HOST_API}/containers/${selectionTab.id}`
        )
        .then(() => {
          showNotificationApp("Se ha eliminado correctamente", "success");
          resolve();
        })
        .catch((error) => {
          console.log(error);
          showNotificationApp("Se ha eliminado correctamente", "error");
          reject();
        });
    });
  };

  const handleCloseFormData = () => {
    setOpenFormData(false);
    handleRefreshT();
  };
  const handleCloseFormOwners = () => {
    setOwners(false);
    handleRefreshT();
  };

  const handleViewData = () => {
    if (selectionTab) {
      setOpenViewData(true);
    }
  };
  const handleCloseViewData = () => {
    setOpenViewData(false);
    handleRefreshT();
  };

  const handleAddCost = () => {
    if (selectionTab) {
      setAddCost(true);
    }
  };
  const handleCloseAddCost = () => {
    setAddCost(false);
    handleRefreshT();
  };

  const handleModule = () => {
    if (selectionTab) {
      setModule(true);
    }
  };
  const handleCloseModule = () => {
    setModule(false);
    handleRefreshT();
  };

  const handleDividerContainer = () => {
    if (selectionTab) {
      setDividerContainer(true);
    }
  };
  const handleCloseDividerContainer = () => {
    setDividerContainer(false);
    handleRefreshT();
  };

  const handleSaleContainer = () => {
    if (selectionTab) {
      setSaleContainer(true);
    }
  };
  const handleCloseSaleContainer = () => {
    setSaleContainer(false);
    handleRefreshT();
  };

  const handleRentContainer = () => {
    if (selectionTab) {
      setRentContainer(true);
    }
  };
  const handleOwners = () => {
    if (selectionTab) {
      setOwners(true);
    }
  };
  const handleCloseRentContainer = () => {
    setRentContainer(false);
    handleRefreshT();
  };

  const handleCreateData = () => {
    if (!formCreate) {
      setFormCreate(true);
    }
    setOpenFormData(true);
  };

  const handleUpdateData = () => {
    if (selectionTab) {
      if (formCreate) {
        setFormCreate(false);
      }
      setOpenFormData(true);
    }
  };

  const handleRefreshT = () => {
    setRefreshTable(refreshTable === 0 ? 1 : 0);
    setSelectionTab([]);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // Primero, obtén los datos del rol del usuario usando getUserRoleData
      
        const { rolesApp, country } = await getUserRoleData();
        console.log(rolesApp , country);
        setRolesAppValue(rolesApp); // Actualiza rolesAppValue con el valor obtenido
        setUserDataTCountry(country); // Actualiza userDataTCountry con el valor obtenido

        // Ahora, realiza la petición a /modules/
        setLoading(true);
        const response = await serviContainersApiCXN.get(`${process.env.REACT_APP_HOST_API}/containers/?pagination=false`);
        let itemsToSet;
console.log(rolesApp);
        if (rolesApp === 2) {
          itemsToSet = filterStateSeller(iniData(response.data["hydra:member"]));
        } else if (rolesApp === 1) {
          itemsToSet = filterCountryOperario(iniData(response.data["hydra:member"]), country);
        } else if (rolesApp === 0) {
          itemsToSet = iniData(response.data["hydra:member"]);
        }

        setItems(itemsToSet);
      } catch (error) {
        
        console.error("Error al cargar datos:", error);
        showNotificationApp("Error cargando datos del sistema", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Llama a la función fetchData
  }, [refreshTable]);

  
 

  const MenuToolBar = () => {
    return (
      <React.Fragment>
        <Stack
          direction="row"
          justifyContent="flex-end"
          divider={<Divider orientation="vertical" />}
        >
         
        </Stack>
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex align-items-center justify-content-start">
      <Stack
        direction="row"
        justifyContent="flex-start"
        divider={<Divider orientation="vertical" />}
      >
        <IconButton
          variant="text"
          color="primary"
          onClick={() => exportCSV(false)}
        >
          <Tooltip title="Exportar" placement="top">
            <FileDownloadIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          variant="text"
          aria-label="refresh"
          color="primary"
          onClick={handleRefreshT}
        >
          <Tooltip title="Actualizar Tabla" placement="top">
            <RefreshSharpIcon />
          </Tooltip>
        </IconButton>
        <Divider orientation="vertical" flexItem />
        {( rolesAppValue === 0  || rolesAppValue === 1) && (
          <>
            <IconButton
              variant="text"
              aria-label="crear"
              color="primary"
              onClick={handleCreateData}
            >
              <Tooltip title="Nueva" placement="top">
                <AddSharpIcon />
              </Tooltip>
            </IconButton>
            </>
          )}
                  {(rolesAppValue == 0 || rolesAppValue == 2 || rolesAppValue == 1) && (
                    <>
            <IconButton
              variant="text"
              aria-label="editar"
              color="primary"
              disabled={!selectionTab}
              onClick={handleUpdateData}
            >
              <Tooltip title="Editar" placement="top">
                <EditSharpIcon />
              </Tooltip>
            </IconButton>
          
            </>
          )}
        <IconButton
          variant="text"
          aria-label="view"
          color="primary"
          onClick={handleViewData}
          disabled={!selectionTab}
        >
          <Tooltip title="Ver" placement="top">
            <VisibilitySharpIcon />
          </Tooltip>
        </IconButton>
        <Divider orientation="vertical" flexItem />
        {(rolesAppValue == 0 ||rolesAppValue == 1) && (
          <>
            <IconButton
              variant="text"
              aria-label="costos"
              color="primary"
              onClick={handleAddCost}
              disabled={!selectionTab}
            >
              <Tooltip title="Gestionar Costos" placement="top">
                <PriceChangeIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              variant="text"
              aria-label="modulo"
              color="primary"
              onClick={handleModule}
              disabled={!selectionTab}
            >
              <Tooltip title="Convertir a módulo" placement="top">
                <NightShelterIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              variant="text"
              aria-label="divider"
              color="primary"
              onClick={handleDividerContainer}
              disabled={
                !selectionTab ||
                (selectionTab.length === 1 &&
                  items.find((ele) => ele.id === selectionTab[0])
                    .parentContainer !== undefined)
              }
            >
              <Tooltip title="Dividir Contendor" placement="top">
                <SplitscreenIcon />
              </Tooltip>
            </IconButton>
       
       
            <IconButton
              variant="text"
              aria-label="divider"
              color="primary"
              onClick={handleSaleContainer}
              disabled={!selectionTab}
            >
              <Tooltip title="Vender Contendor" placement="top">
                <RequestPageIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              variant="text"
              aria-label="divider"
              color="primary"
              onClick={handleRentContainer}
              disabled={!selectionTab}
            >
              <Tooltip title="Rentar Contendor" placement="top">
                <TodayIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              variant="text"
              aria-label="divider"
              color="primary"
              onClick={handleOwners}
              disabled={!selectionTab}
            >
              <Tooltip title="Traspaso de Propietarios" placement="top">
                <PeopleAltRoundedIcon />
              </Tooltip>
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              variant="text"
              aria-label="view"
              color="error"
              disabled={!selectionTab}
              onClick={handleOpenConfirmDelete}
            >
              <Tooltip title="Eliminar" placement="top">
                <DeleteSharpIcon />
              </Tooltip>
            </IconButton>
          </>
        )}
      </Stack>
    </div>
  );

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true }}
        />
        <MenuToolBar />
      </GridToolbarContainer>
    );
  };
  const filteredCols = cols.filter(col => {
    // Si rolesAppValue es 2, incluye la columna de Total de Costo
    if (rolesAppValue === 2) {
      return col.field!== "totalCosts";
    }
    // Para otros valores de rolesAppValue, excluye la columna de Total de Costo
    return true;
  });

  return (
    <React.Fragment>
      {loading ? (
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"400px"}
          animation="wave"
        />
      ) : (
        <React.Fragment>
          {/* <Box sx={{ height: "60vh", width: "100%" }}> */}
          <div className="card">
            <DataTable
              // stripedRows
              // showGridlines
              ref={dt}
              header={header}
              value={items}
              scrollable
              scrollHeight="500px"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25, 50]}
              removableSort
              dataKey="id"
              selectionMode="single"
              selection={selectionTab}
              onSelectionChange={(e) => setSelectionTab(e.value)}
              tableStyle={
                {
                  // minWidth: "50rem",
                  // color: "red",
                }
              }
              filters={filters}
              filterDisplay="row"
              size="normal"
              metaKeySelection={metaKey}
              columns={filteredCols}
            >
              {/* <Column
                  field="name"
                  header="Nombre"
                  sortable
                  filter
                  filterPlaceholder="Search by Nombre"
                ></Column>
                <Column
                  field="description"
                  header="Descripción"
                  sortable
                  filter
                  filterPlaceholder="Search by Descripción"
                ></Column> */}
              {filteredCols.map((col, index) => (
                <Column
                  sortable
                  filter
                  filterPlaceholder="Search"
                  key={index}
                  field={col.field}
                  header={col.header}
                  style={{ height: "50px", minWidth: "200px" }}
                />
              ))}
            </DataTable>
          </div>
          {/* </Box> */}
          <FormContainer
            open={openFormData}
            onClose={handleCloseFormData}
            create={formCreate}
            editData={
              !formCreate && selectionTab
              // ? items.find((elem) => elem.id === selectionTab[0])
              // : null
            }
          />
          <FormOwners
            open={owners}
            onClose={handleCloseFormOwners}
            editData={
              selectionTab
              // ? items.find((elem) => elem.id === selectionTab[0])
              // : null
            }
          />
          <ViewContainer
            open={openViewData}
            onClose={handleCloseViewData}
            viewDataContainer={
              selectionTab
              // ? items.find((elem) => elem.id === selectionTab[0])
              // : null
            }
          />
          <FormEditCostContainer
            open={addCost}
            viewData={
              selectionTab
              // ? items.find((elem) => elem.id === selectionTab[0])
              // : null
            }
            onClose={handleCloseAddCost}
          />
          <ConverterModule
            open={module}
            onClose={handleCloseModule}
            create={true}
            editData={
              selectionTab
              // ? items.find((elem) => elem.id === selectionTab[0])
              // : null
            }
          />
          <Sale
            open={saleContainer}
            onClose={handleCloseSaleContainer}
            viewData={
              selectionTab
              // ? items.find((elem) => elem.id === selectionTab[0])
              // : null
            }
          />
          <Rent
            open={rentContainer}
            onClose={handleCloseRentContainer}
            viewData={
              selectionTab
              // ? items.find((elem) => elem.id === selectionTab[0])
              // : null
            }
          />
          <DividerContainer
            open={dividerContainer}
            onClose={handleCloseDividerContainer}
            viewData={
              selectionTab
              // ? items.find((elem) => elem.id === selectionTab[0])
              // : null
            }
          />
          <ConfirmDialog
            open={openConfirmDelete}
            onClose={handleCloseConfirmDelete}
            onConfirm={handleDeleteData}
            onError={() => {
              showNotificationApp(
                "Hubo un problema durante la eliminación del contenedor.",
                "error"
              );
            }}
            title="Confirmar eliminación"
            text="¿Está seguro que desea eliminar el contenedor seleccionado?"
            textConfirm="Eliminar"
            colorConfirm="error"
            textCancel="Cancelar"
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
