// import * as React from "react";
// import {
//   Box,
//   Stack,
//   Divider,
//   IconButton,
//   Skeleton,
//   Tooltip,
// } from "@mui/material";
// import {
//   DataGrid,
//   GridToolbarContainer,
//   GridToolbarExport,
//   GridToolbarFilterButton,
// } from "@mui/x-data-grid";
// import AddSharpIcon from "@mui/icons-material/AddSharp";
// import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
// import EditSharpIcon from "@mui/icons-material/EditSharp";
// import PriceChangeIcon from "@mui/icons-material/PriceChange";
// import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
// import ConverterModule from "../../AddContainer/Container/ConverterModule";
// import SaleM from "./SaleM";
// import RentM from "./RentM";
// import RequestPageIcon from "@mui/icons-material/RequestPage";
// import TodayIcon from "@mui/icons-material/Today";
// import FormEditCostModule from "./FromEditCostModule";
// import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
// import { getTotalCosts } from "../../AddContainer/Container/Container";
// import { serviContainersApiCXN } from "../../../auth/axios.Container";
// import { useContext } from "react";
// import { AppContext } from "../../../Providers/AppProvider";
// import FormOwnersM from "./FormOwnersM";
// import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
// import ViewModule from "./ViewModule";
// import FormCreateModule from "./FomCreateModule";
// import dayjs from "dayjs";
// import { isAccessPermission } from "../../../auth/security.ServiContainers";

// const iniData = (data) => {
//   let ret = [];
//   data.forEach((element) => {
//     console.log(element);
//     ret.push({
//       id: element.id,
//       codeModule: element.codeModule,
//       stateName: element.state?.name,
//       // state: element.state["@id"],
//       state: element.state.name,
//       model: element.container?.model?.name,
//       place_countries: element.container?.placeCountry?.name,
//       totalCosts: getTotalCosts(element.container?.typeCostAsocContainers),
//       profitAsoc: `${element.profitAsoc * 100}%`,
//       // createAt: new Date(element.createAt),
//       createAt: dayjs(element.createAt).format("DD/MM/YYYY"),
//       factoryYear: `${element.container?.factoryYear}`,
//       owner: element.container.owner,
//       ownerName: element.container.owner.name,
//       description: element.container.description,
//       weight: element.container.weight,
//       priceSaleEsp: element.container.priceSaleEsp,
//       saleEspAt: dayjs(element.container.saleEspAt).format("DD/MM/YYYY"),
//       outputAt: dayjs(element.container.outputAt).format("DD/MM/YYYY"),
//       obj: element,
//       invoice:
//         element.container.invoice !== null || element.container.invoice !== ""
//           ? element.container.invoice
//           : "No asociado",
//       di:
//         element.container.di !== null || element.container.di !== ""
//           ? element.container.di
//           : "No asociado",
//     });
//   });
//   return ret;
// };

// export default function DataGridDemo() {
//   //const
//   const [loading, setLoading] = React.useState(true);
//   const [refreshTable, setRefreshTable] = React.useState(0);
//   const [selectionTab, setSelectionTab] = React.useState([]);
//   const [items, setItems] = React.useState([]);
//   const [module, setModule] = React.useState(false);
//   const [saleModule, setSaleModule] = React.useState(false);
//   const [rentModule, setRentModule] = React.useState(false);
//   const [addCost, setAddCost] = React.useState(false);
//   const [ownersM, setOwnersM] = React.useState(false);
//   const { showNotificationApp } = useContext(AppContext);
//   const [openViewData, setOpenViewData] = React.useState(false);
//   const [formCreate, setFormCreate] = React.useState(true);
//   const [openFormData, setOpenFormData] = React.useState(false);

//   const handleDeleteData = () => {
//     return Promise.all(
//       selectionTab.map((element) => {
//         return serviContainersApiCXN.delete(
//           `${process.env.REACT_APP_HOST_API}/modules/${element}`
//         );
//       })
//     )
//       .then(() => {
//         handleRefreshT();
//         console.log("operacion correcta");
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   const handleAddCost = () => {
//     if (selectionTab.length === 1) {
//       setAddCost(true);
//     }
//   };
//   const handleCloseAddCost = () => {
//     setAddCost(false);
//     handleRefreshT();
//   };

//   // const handleSaleModule = () => {
//   //   if (selectionTab.length === 1) {
//   //     setSaleModule(true);
//   //   }
//   // };

//   const handleSaleModule = () => {
//     if (selectionTab.length === 1) {
//       setSaleModule(true);
//     }
//   };
//   const handleCloseSaleModule = () => {
//     setSaleModule(false);
//     handleRefreshT();
//   };

//   const handleRentModule = () => {
//     if (selectionTab.length === 1) {
//       setRentModule(true);
//     }
//   };
//   const handleCloseRentModule = () => {
//     setRentModule(false);
//     handleRefreshT();
//   };
//   // const handleModule = () => {
//   //   if (selectionTab.length === 1) {
//   //     setModule(true);
//   //   }
//   // };
//   const handleCloseModule = () => {
//     setModule(false);
//     handleRefreshT();
//   };

//   const handleOwnersM = () => {
//     if (selectionTab.length === 1) {
//       setOwnersM(true);
//     }
//   };

//   const handleCloseFormOwnersM = () => {
//     setOwnersM(false);
//     handleRefreshT();
//   };

//   const handleViewData = () => {
//     if (selectionTab.length === 1) {
//       setOpenViewData(true);
//     }
//   };
//   const handleCloseViewData = () => {
//     setOpenViewData(false);
//     handleRefreshT();
//   };
//   const handleCloseCreateData = () => {
//     setOpenFormData(false);
//     handleRefreshT();
//   };
//   // const handleCreateData = () => {
//   //   if (!formCreate) {
//   //     setFormCreate(true);
//   //   }
//   //   setOpenFormData(true);
//   // };
//   const handleCreateData = () => {
//     if (!formCreate) {
//       setFormCreate(true);
//     }
//     setOpenFormData(true);
//   };

//   const handleUpdateData = () => {
//     if (selectionTab.length === 1) {
//       if (formCreate) {
//         setFormCreate(false);
//       }
//       setOpenFormData(true);
//     }
//   };
//   const handleCloseFormData = () => {
//     setOpenFormData(false);
//     handleRefreshT();
//   };
//   React.useEffect(() => {
//     setLoading(true);
//     serviContainersApiCXN
//       .get(`${process.env.REACT_APP_HOST_API}/modules?pagination=false`)
//       .then((response) => {
//         setItems(iniData(response.data["hydra:member"]));
//       })
//       .catch(function (error) {
//         // handle error
//         showNotificationApp("Error cargando datos del sistema", "error");

//         console.log(error);
//       })
//       .finally(function () {
//         // always executed
//         setLoading(false);
//       });
//   }, [refreshTable]);

//   const handleRefreshT = () => {
//     setRefreshTable(refreshTable === 0 ? 1 : 0);
//     setSelectionTab([]);
//   };

//   const MenuToolBar = () => {
//     return (
//       <React.Fragment>
//         <Stack
//           direction="row"
//           justifyContent="flex-end"
//           divider={<Divider orientation="vertical" />}
//         >
//           <IconButton
//             variant="text"
//             aria-label="refresh"
//             color="primary"
//             onClick={handleRefreshT}
//           >
//             <Tooltip title="Actualizar Tabla" placement="top">
//               <RefreshSharpIcon />
//             </Tooltip>
//           </IconButton>

//           <Divider orientation="vertical" flexItem />
//           {isAccessPermission(0) && (
//             <>
//               <IconButton
//                 variant="text"
//                 aria-label="crear"
//                 color="primary"
//                 onClick={handleCreateData}
//               >
//                 <Tooltip title="Nueva" placement="top">
//                   <AddSharpIcon />
//                 </Tooltip>
//               </IconButton>
//               <IconButton
//                 variant="text"
//                 aria-label="editar"
//                 color="primary"
//                 disabled={selectionTab.length !== 1}
//                 onClick={handleUpdateData}
//               >
//                 <Tooltip title="Editar" placement="top">
//                   <EditSharpIcon />
//                 </Tooltip>
//               </IconButton>
//             </>
//           )}
//           <IconButton
//             variant="text"
//             aria-label="view"
//             color="primary"
//             onClick={handleViewData}
//             disabled={selectionTab.length !== 1}
//           >
//             <Tooltip title="Ver" placement="top">
//               <VisibilitySharpIcon />
//             </Tooltip>
//           </IconButton>
//           <Divider orientation="vertical" flexItem />
//           {(isAccessPermission(0) || isAccessPermission(2)) && (
//             <>
//               <IconButton
//                 variant="text"
//                 aria-label="costos"
//                 color="primary"
//                 onClick={handleAddCost}
//                 disabled={selectionTab.length !== 1}
//               >
//                 <Tooltip title="Gestionar Costos" placement="top">
//                   <PriceChangeIcon />
//                 </Tooltip>
//               </IconButton>
//               <IconButton
//                 variant="text"
//                 aria-label="divider"
//                 color="primary"
//                 onClick={handleSaleModule}
//                 disabled={selectionTab.length !== 1}
//               >
//                 <Tooltip title="Vender Módulo" placement="top">
//                   <RequestPageIcon />
//                 </Tooltip>
//               </IconButton>
//               <IconButton
//                 variant="text"
//                 aria-label="divider"
//                 color="primary"
//                 onClick={handleRentModule}
//                 disabled={selectionTab.length !== 1}
//               >
//                 <Tooltip title="Rentar Módulo" placement="top">
//                   <TodayIcon />
//                 </Tooltip>
//               </IconButton>
//               <IconButton
//                 variant="text"
//                 aria-label="divider"
//                 color="primary"
//                 onClick={handleOwnersM}
//                 disabled={selectionTab.length !== 1}
//               >
//                 <Tooltip title="Traspaso de Propietarios" placement="top">
//                   <PeopleAltRoundedIcon />
//                 </Tooltip>
//               </IconButton>
//             </>
//           )}
//           {isAccessPermission(0) && (
//             <>
//               <Divider orientation="vertical" flexItem />
//               <IconButton
//                 variant="text"
//                 aria-label="view"
//                 color="error"
//                 disabled={selectionTab.length !== 1}
//                 onClick={handleDeleteData}
//               >
//                 <Tooltip title="Eliminar" placement="top">
//                   <DeleteSharpIcon />
//                 </Tooltip>
//               </IconButton>
//             </>
//           )}
//         </Stack>
//       </React.Fragment>
//     );
//   };

//   //menubar
//   const CustomToolbar = () => {
//     return (
//       <GridToolbarContainer>
//         <GridToolbarFilterButton />
//         <GridToolbarExport
//           printOptions={{ disableToolbarButton: true }}
//           csvOptions={{ allColumns: true }}
//         />
//         <MenuToolBar />
//       </GridToolbarContainer>
//     );
//   };

//   return (
//     <React.Fragment>
//       {loading ? (
//         <Skeleton
//           variant="rectangular"
//           width={"100%"}
//           height={"400px"}
//           animation="wave"
//         />
//       ) : (
//         <React.Fragment>
//           <Box sx={{ height: "60vh", width: "100%" }}>
//             <DataGrid
//               rows={items}
//               columns={[
//                 { headerName: "Código", field: "codeModule", width: 160 },
//                 // { headerName: "DI", field: "codeModule", width: 200 },
//                 {
//                   headerName: "Estado",
//                   field: "state",
//                   width: 160,
//                 },
//                 { headerName: "Factura", field: "invoice", width: 160 },
//                 { headerName: "DI", field: "di", width: 160 },
//                 { headerName: "Propietarios", field: "ownerName", width: 160 },
//                 { headerName: "Tipo", field: "model", width: 160 },
//                 { headerName: "Total Gastos", field: "totalCosts", width: 160 },
//                 {
//                   headerName: "Lugar",
//                   field: "place_countries",
//                   width: 160,
//                 },
//                 {
//                   headerName: "Margen Ganancial",
//                   field: "profitAsoc",
//                   width: 160,
//                 },
//                 {
//                   headerName: "Peso módulo",
//                   field: "weight",
//                   width: 160,
//                 },
//                 {
//                   headerName: "Precio de Venta",
//                   field: "priceSaleEsp",
//                   width: 160,
//                 },
//                 {
//                   headerName: "Fecha Internación",
//                   field: "createAt",
//                   width: 160,
//                   // type: "dateTime",
//                 },
//                 {
//                   headerName: "Año fabricación",
//                   field: "factoryYear",
//                   width: 160,
//                 },
//                 {
//                   headerName: "Fecha de Venta",
//                   field: "saleEspAt",
//                   width: 160,
//                   // type: "dateTime",
//                 },
//                 {
//                   headerName: "Fecha de Egreso",
//                   field: "outputAt",
//                   width: 160,
//                 },
//                 {
//                   headerName: "Descripción",
//                   field: "description",
//                   width: 160,
//                 },
//               ]}
//               initialState={{
//                 sorting: {
//                   sortModel: [{ field: "createAt", sort: "desc" }],
//                 },
//               }}
//               components={{
//                 Toolbar: CustomToolbar,
//               }}
//               onRowSelectionModelChange={(newSelectionModel) => {
//                 setSelectionTab(newSelectionModel);
//               }}
//               rowSelectionModel={selectionTab}
//               checkboxSelection
//             />
//           </Box>
//           {console.log(items.find((elem) => elem.id === selectionTab[0]))}
//           <FormCreateModule
//             open={openFormData}
//             onClose={handleCloseFormData}
//             create={formCreate}
//             editData={
//               !formCreate && selectionTab.length === 1
//                 ? items.find((elem) => elem.id === selectionTab[0])
//                 : null
//             }
//           />
//           <SaleM
//             open={saleModule}
//             onClose={handleCloseSaleModule}
//             viewData={
//               selectionTab.length === 1
//                 ? items.find((elem) => elem.id === selectionTab[0])
//                 : null
//             }
//           />
//           <RentM
//             open={rentModule}
//             onClose={handleCloseRentModule}
//             viewData={
//               selectionTab.length === 1
//                 ? items.find((elem) => elem.id === selectionTab[0])
//                 : null
//             }
//           />
//           {/* <ConverterModule
//             open={module}
//             onClose={handleCloseModule}
//             create={true}
//             // editData={
//             //   selectionTab.length === 1
//             //     ? items.find((elem) => elem.id === selectionTab[0])
//             //     : null
//             // }
//           /> */}
//           <FormEditCostModule
//             open={addCost}
//             viewData={
//               selectionTab.length === 1
//                 ? items.find((elem) => elem.id === selectionTab[0])
//                 : null
//             }
//             onClose={handleCloseAddCost}
//           />
//           <FormOwnersM
//             open={ownersM}
//             onClose={handleCloseFormOwnersM}
//             editData={
//               selectionTab.length === 1
//                 ? items.find((elem) => elem.id === selectionTab[0])
//                 : null
//             }
//           />
//           <ViewModule
//             open={openViewData}
//             onClose={handleCloseViewData}
//             viewDataModule={
//               selectionTab.length === 1
//                 ? items.find((elem) => elem.id === selectionTab[0])
//                 : null
//             }
//           />
//         </React.Fragment>
//       )}
//     </React.Fragment>
//   );
// }

import * as React from "react";
import {
  Box,
  Stack,
  Divider,
  IconButton,
  Skeleton,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import ConverterModule from "../../AddContainer/Container/ConverterModule";
import SaleM from "./SaleM";
import RentM from "./RentM";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import TodayIcon from "@mui/icons-material/Today";
import FormEditCostModule from "./FromEditCostModule";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import { getTotalCosts } from "../../AddContainer/Container/Container";
import { serviContainersApiCXN } from "../../../auth/axios.Container";
import { useContext } from "react";
import { AppContext } from "../../../Providers/AppProvider";
import FormOwnersM from "./FormOwnersM";
import { getUserId, isAccessPermission } from "../../../auth/security.ServiContainers";
import { ROLES, isAccess } from "./../../../auth/security.ServiContainers";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import ViewModule from "./ViewModule";
import FormCreateModule from "./FomCreateModule";
import dayjs from "dayjs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import "primereact/resources/themes/md-light-indigo/theme.css";
import "./container.css";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import { getUserRoleData } from './../../../utils/Roles';

const iniData1 = (data) => {
  console.log( 'Data completa', data);
    console.log('RolesApp', data.rolesApp);


  const ret = { obj: data };   

  return {
    rolesApp: ret.obj.rolesApp,
    country: ret.obj.areaCountry.name
  };  
};

function filterStateSeller ( containers ){
   
  const filterArray = containers.filter(elemento => 
    elemento.state == 'DISPONIBLE PARA VENTA' ||
    elemento.state == 'DISPONIBLE PARA ARRIENDO' ||
    elemento.state == 'DISPONIBLE PARA VENTA Y ARRIENDO'
  );
  
  return filterArray;
}

// AQUI ACCEDO A AREA COUNTRY
function filterCountryOperario ( containers, country ){
  console.log ( 'filter operario', {containers, country} );
  const filterArray = containers.filter(elemento => 
    elemento.obj.container?.placeCountry.areaCountry.name == country
  );
  return filterArray;
}

const iniData = (data) => {
  let ret = [];
  data.forEach((element) => {
    console.log(element);
    ret.push({
      id: element.id,
      codeModule: element.codeModule,
      stateName: element.state?.name,
      // state: element.state["@id"],
      state: element.state.name,
      model: element.container?.model?.name,
      place_countries: element.container?.placeCountry?.name,
      totalCosts: getTotalCosts(element.container?.typeCostAsocContainers),
      profitAsoc: `${element.profitAsoc * 100}%`,
      // createAt: new Date(element.createAt),
      createAt: dayjs(element.createAt).format("DD/MM/YYYY"),
      factoryYear: `${element.container?.factoryYear}`,
      owner: element.container.owner,
      ownerName: element.container.owner.name,
      description: element.container.description,
      weight: element.container.weight,
      priceSaleEsp: element.container.priceSaleEsp,
      saleEspAt: dayjs(element.container.saleEspAt).format("DD/MM/YYYY"),
      outputAt: dayjs(element.container.outputAt).format("DD/MM/YYYY"),
      obj: element,
      invoice:
        element.container.invoice !== null || element.container.invoice !== ""
          ? element.container.invoice
          : "No asociado",
      di:
        element.container.di !== null || element.container.di !== ""
          ? element.container.di
          : "No asociado",
    });
  });
  return ret;
};

const filtersDef = {
  codeModule: { value: null, matchMode: FilterMatchMode.CONTAINS },
  state: { value: null, matchMode: FilterMatchMode.CONTAINS },
  invoice: { value: null, matchMode: FilterMatchMode.CONTAINS },
  di: { value: null, matchMode: FilterMatchMode.CONTAINS },
  ownerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  model: { value: null, matchMode: FilterMatchMode.CONTAINS },
  totalCosts: { value: null, matchMode: FilterMatchMode.CONTAINS },
  place_countries: { value: null, matchMode: FilterMatchMode.CONTAINS },
  profitAsoc: { value: null, matchMode: FilterMatchMode.CONTAINS },
  weight: { value: null, matchMode: FilterMatchMode.CONTAINS },
  priceSaleEsp: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
  factoryYear: { value: null, matchMode: FilterMatchMode.CONTAINS },
  saleEspAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
  outputAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
  description: { value: null, matchMode: FilterMatchMode.CONTAINS },
};

export default function DataGridDemo() {
  //const
  const [loading, setLoading] = React.useState(true);
  const [refreshTable, setRefreshTable] = React.useState(0);
  const [selectionTab, setSelectionTab] = React.useState(null);
  const [items, setItems] = React.useState([]);
  const [filters, setFilters] = React.useState(filtersDef);
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const [module, setModule] = React.useState(false);
  const [saleModule, setSaleModule] = React.useState(false);
  const [rentModule, setRentModule] = React.useState(false);
  const [addCost, setAddCost] = React.useState(false);
  const [ownersM, setOwnersM] = React.useState(false);
  const { showNotificationApp } = useContext(AppContext);
  const [openViewData, setOpenViewData] = React.useState(false);
  const [formCreate, setFormCreate] = React.useState(true);
  const [openFormData, setOpenFormData] = React.useState(false);
  const dt = React.useRef(null);
  const [metaKey, setMetaKey] = React.useState(false);
  const [items1, setItems1] = React.useState([]);
  const [userId, setUserId] = React.useState([]);
  const [rolesAppValue, setRolesAppValue] = React.useState(null); // Estado para rolesApp
  const [userDataTCountry, setUserDataTCountry] = React.useState(''); 


  const cols = [
    { headerName: "Código", field: "codeModule", width: 160 },
    // { headerName: "DI", field: "codeModule", width: 200 },
    {
      header: "Estado",
      field: "state",
    },
    { header: "Factura", field: "invoice" },
    { header: "DI", field: "di" },
    { header: "Propietarios", field: "ownerName" },
    { header: "Tipo", field: "model" },
    { header: "Total Gastos", field: "totalCosts" },
    {
      header: "Lugar",
      field: "place_countries",
    },
    {
      header: "Margen Ganancial",
      field: "profitAsoc",
    },
    {
      header: "Peso módulo",
      field: "weight",
    },
    {
      header: "Precio de Venta",
      field: "priceSaleEsp",
    },
    {
      header: "Fecha Internación",
      field: "createAt",

      // type: "dateTime",
    },
    {
      header: "Año fabricación",
      field: "factoryYear",
    },
    {
      header: "Fecha de Venta",
      field: "saleEspAt",

      // type: "dateTime",
    },
    {
      header: "Fecha de Egreso",
      field: "outputAt",
    },
    {
      header: "Descripción",
      field: "description",
    },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportCSV = (selectionOnly) => {
    console.log(dt);
    dt.current.exportCSV({ selectionOnly });
  };

  // const handleDeleteData = () => {
  //   return Promise.all(
  //     selectionTab.map((element) => {
  //       return serviContainersApiCXN.delete(
  //         `${process.env.REACT_APP_HOST_API}/modules/${element}`
  //       );
  //     })
  //   )
  //     .then(() => {
  //       handleRefreshT();
  //       console.log("operacion correcta");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const handleOpenConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
    handleRefreshT();
  };

  const handleDeleteData = () => {
    return new Promise((resolve, reject) => {
      serviContainersApiCXN
        .delete(`${process.env.REACT_APP_HOST_API}/modules/${selectionTab.id}`)
        .then(() => {
          showNotificationApp("Se ha eliminado correctamente", "success");
          resolve();
        })
        .catch((error) => {
          console.log(error);
          showNotificationApp("Se ha eliminado correctamente", "error");
          reject();
        });
    });
  };

  const handleAddCost = () => {
    if (selectionTab) {
      setAddCost(true);
    }
  };
  const handleCloseAddCost = () => {
    setAddCost(false);
    handleRefreshT();
  };

  // const handleSaleModule = () => {
  //   if (selectionTab.length === 1) {
  //     setSaleModule(true);
  //   }
  // };

  const handleSaleModule = () => {
    if (selectionTab) {
      setSaleModule(true);
    }
  };
  const handleCloseSaleModule = () => {
    setSaleModule(false);
    handleRefreshT();
  };

  const handleRentModule = () => {
    if (selectionTab) {
      setRentModule(true);
    }
  };
  const handleCloseRentModule = () => {
    setRentModule(false);
    handleRefreshT();
  };
  // const handleModule = () => {
  //   if (selectionTab.length === 1) {
  //     setModule(true);
  //   }
  // };
  const handleCloseModule = () => {
    setModule(false);
    handleRefreshT();
  };

  const handleOwnersM = () => {
    if (selectionTab) {
      setOwnersM(true);
    }
  };

  const handleCloseFormOwnersM = () => {
    setOwnersM(false);
    handleRefreshT();
  };

  const handleViewData = () => {
    if (selectionTab) {
      setOpenViewData(true);
    }
  };
  const handleCloseViewData = () => {
    setOpenViewData(false);
    handleRefreshT();
  };
  const handleCloseCreateData = () => {
    setOpenFormData(false);
    handleRefreshT();
  };
  // const handleCreateData = () => {
  //   if (!formCreate) {
  //     setFormCreate(true);
  //   }
  //   setOpenFormData(true);
  // };
  const handleCreateData = () => {
    if (!formCreate) {
      setFormCreate(true);
    }
    setOpenFormData(true);
  };

  const handleUpdateData = () => {
    if (selectionTab) {
      if (formCreate) {
        setFormCreate(false);
      }
      setOpenFormData(true);
    }
  };
  const handleCloseFormData = () => {
    setOpenFormData(false);
    handleRefreshT();
  };
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // Primero, obtén los datos del rol del usuario usando getUserRoleData
        const { rolesApp, country } = await getUserRoleData();
        setRolesAppValue(rolesApp); // Actualiza rolesAppValue con el valor obtenido
        setUserDataTCountry(country); // Actualiza userDataTCountry con el valor obtenido

        // Ahora, realiza la petición a /modules/
        setLoading(true);
        const response = await serviContainersApiCXN.get(`${process.env.REACT_APP_HOST_API}/modules/?pagination=false`);
        let itemsToSet;

        if (rolesApp === 2) {
          itemsToSet = filterStateSeller(iniData(response.data["hydra:member"]));
        } else if (rolesApp === 1) {
          itemsToSet = filterCountryOperario(iniData(response.data["hydra:member"]), country);
        } else if (rolesApp === 0) {
          itemsToSet = iniData(response.data["hydra:member"]);
        }

        setItems(itemsToSet);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        showNotificationApp("Error cargando datos del sistema", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Llama a la función fetchData
  }, [refreshTable]);

  // React.useEffect(() => {
  //   const userIdt = getUserId();
  //   setUserId (userIdt);

  //   let userDataT;

  //   console.log ( "UserIdt", userIdt, "UserId", userId );
  //   console.log(userId);
  
  //   // Definir la ruta de contenedores basada en los roles del usuario
  //   const getUserContainerRoute = () => {
  //     const possibleRoles = [ROLES.admin.tag, ROLES.operario.tag, ROLES.vendedor.tag, ROLES.manager.tag];
  
  //     for (const role of possibleRoles) {
  //       const userRole = isAccess(role);
  //       if (userRole) {
  //         const containerRoute = `modules/?pagination=false`;
  //         return containerRoute;
  //       }
  //     }
  //     return null;
  //   };
  
  //   const containerRoute = getUserContainerRoute();
  
  //   // Realizar el primer get de usuario
  //   setLoading(true);
  //   serviContainersApiCXN.get(`${process.env.REACT_APP_HOST_API}/users/${userIdt}`)
  //    .then((response) => {
  //       console.log("Response Jose", response);
  //       userDataT = iniData1(response.data);
  //       setItems1(userDataT);
  //       console.log("response Data", (items1));
  
  //       // Realizar el segundo get de containers después de obtener el userId
  //       console.log ( 'Checking If' );
  //       if (containerRoute) { // Solo proceder si se encontró una ruta válida
  //         console.log ( 'get containers' );
  //         return serviContainersApiCXN.get(`${process.env.REACT_APP_HOST_API}/${containerRoute}`).then((response) => {
  //           console.log ( 'Roles APP:', userDataT.rolesApp );
  //           if ( userDataT.rolesApp == 2 ){
  //             console.log ( 'Roles APP:');
  //             setItems( filterStateSeller (iniData(response.data["hydra:member"])));
  //             console.log ( filterStateSeller (iniData(response.data["hydra:member"])));
  //           }
  //           else 
  //             if ( userDataT.rolesApp == 1 ){
  //               console.log ( 'Role Operario' );
  //               console.log ( 'Countrye User', userDataT, userDataT.country );
  //               setItems( filterCountryOperario(iniData(response.data["hydra:member"]), userDataT.country));
  //             }
  //             else 
  //             if (userDataT.rolesApp == 0){
  //               console.log ( 'Role Admin' );
  //               console.log ( 'containers Response', iniData(response.data["hydra:member"]) )
  //               setItems((iniData(response.data["hydra:member"])))};
  //           }
  //         );
  //       }
  //     })
     
  //    .catch(function (error) {
  //       showNotificationApp("Error cargando datos del sistema", "error");
  //       console.log(error);
  //     })
  //    .finally(function () {
  //       setLoading(false);
        
  //     });
  // }, [refreshTable]);

  const handleRefreshT = () => {
    setRefreshTable(refreshTable === 0 ? 1 : 0);
    setSelectionTab([]);
  };

  const MenuToolBar = () => {
    return (
      <React.Fragment>
        <Stack
          direction="row"
          justifyContent="flex-end"
          divider={<Divider orientation="vertical" />}
        >
          {/* <IconButton
            variant="text"
            aria-label="refresh"
            color="primary"
            onClick={handleRefreshT}
          >
            <Tooltip title="Actualizar Tabla" placement="top">
              <RefreshSharpIcon />
            </Tooltip>
          </IconButton>

          <Divider orientation="vertical" flexItem />
          {isAccessPermission(0) && (
            <>
              <IconButton
                variant="text"
                aria-label="crear"
                color="primary"
                onClick={handleCreateData}
              >
                <Tooltip title="Nueva" placement="top">
                  <AddSharpIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                variant="text"
                aria-label="editar"
                color="primary"
                disabled={selectionTab.length !== 1}
                onClick={handleUpdateData}
              >
                <Tooltip title="Editar" placement="top">
                  <EditSharpIcon />
                </Tooltip>
              </IconButton>
            </>
          )}
          <IconButton
            variant="text"
            aria-label="view"
            color="primary"
            onClick={handleViewData}
            disabled={selectionTab.length !== 1}
          >
            <Tooltip title="Ver" placement="top">
              <VisibilitySharpIcon />
            </Tooltip>
          </IconButton>
          <Divider orientation="vertical" flexItem />
          {(isAccessPermission(0) || isAccessPermission(2)) && (
            <>
              <IconButton
                variant="text"
                aria-label="costos"
                color="primary"
                onClick={handleAddCost}
                disabled={selectionTab.length !== 1}
              >
                <Tooltip title="Gestionar Costos" placement="top">
                  <PriceChangeIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                variant="text"
                aria-label="divider"
                color="primary"
                onClick={handleSaleModule}
                disabled={selectionTab.length !== 1}
              >
                <Tooltip title="Vender Módulo" placement="top">
                  <RequestPageIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                variant="text"
                aria-label="divider"
                color="primary"
                onClick={handleRentModule}
                disabled={selectionTab.length !== 1}
              >
                <Tooltip title="Rentar Módulo" placement="top">
                  <TodayIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                variant="text"
                aria-label="divider"
                color="primary"
                onClick={handleOwnersM}
                disabled={selectionTab.length !== 1}
              >
                <Tooltip title="Traspaso de Propietarios" placement="top">
                  <PeopleAltRoundedIcon />
                </Tooltip>
              </IconButton>
            </>
          )}
          {isAccessPermission(0) && (
            <>
              <Divider orientation="vertical" flexItem />
              <IconButton
                variant="text"
                aria-label="view"
                color="error"
                disabled={selectionTab.length !== 1}
                onClick={handleDeleteData}
              >
                <Tooltip title="Eliminar" placement="top">
                  <DeleteSharpIcon />
                </Tooltip>
              </IconButton>
            </>
          )} */}
        </Stack>
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex align-items-center justify-content-start">
      {/* <Button
        type="button"
        icon="pi-download"
        onClick={() => exportCSV(false)}
        data-pr-tooltip="CSV"
      /> */}
      <Stack
        direction="row"
        justifyContent="flex-start"
        divider={<Divider orientation="vertical" />}
      >
        <IconButton
          variant="text"
          color="primary"
          onClick={() => exportCSV(false)}
        >
          <Tooltip title="Exportar" placement="top">
            <FileDownloadIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          variant="text"
          aria-label="refresh"
          color="primary"
          onClick={handleRefreshT}
        >
          <Tooltip title="Actualizar Tabla" placement="top">
            <RefreshSharpIcon />
          </Tooltip>
        </IconButton>

        <Divider orientation="vertical" flexItem />
        {( rolesAppValue === 0  || rolesAppValue === 1) && (
          <>
            <IconButton
              variant="text"
              aria-label="crear"
              color="primary"
              onClick={handleCreateData}
            >
              <Tooltip title="Nueva" placement="top">
                <AddSharpIcon />
              </Tooltip>
            </IconButton>
            </>
          )}
                  {(rolesAppValue == 0 || rolesAppValue == 2 || rolesAppValue == 1) && (
                    <>
            <IconButton
              variant="text"
              aria-label="editar"
              color="primary"
              disabled={!selectionTab}
              onClick={handleUpdateData}
            >
              <Tooltip title="Editar" placement="top">
                <EditSharpIcon />
              </Tooltip>
            </IconButton>
            </>
          )}
        <IconButton
          variant="text"
          aria-label="view"
          color="primary"
          onClick={handleViewData}
          disabled={!selectionTab}
        >
          <Tooltip title="Ver" placement="top">
            <VisibilitySharpIcon />
          </Tooltip>
        </IconButton>
        <Divider orientation="vertical" flexItem />
        {(rolesAppValue == 0 ||rolesAppValue == 1) && (
          <>
            <IconButton
              variant="text"
              aria-label="costos"
              color="primary"
              onClick={handleAddCost}
              disabled={!selectionTab}
            >
              <Tooltip title="Gestionar Costos" placement="top">
                <PriceChangeIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              variant="text"
              aria-label="divider"
              color="primary"
              onClick={handleSaleModule}
              disabled={!selectionTab}
            >
              <Tooltip title="Vender Módulo" placement="top">
                <RequestPageIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              variant="text"
              aria-label="divider"
              color="primary"
              onClick={handleRentModule}
              disabled={!selectionTab}
            >
              <Tooltip title="Rentar Módulo" placement="top">
                <TodayIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              variant="text"
              aria-label="divider"
              color="primary"
              onClick={handleOwnersM}
              disabled={!selectionTab}
            >
              <Tooltip title="Traspaso de Propietarios" placement="top">
                <PeopleAltRoundedIcon />
              </Tooltip>
            </IconButton>
       
            <Divider orientation="vertical" flexItem />
            <IconButton
              variant="text"
              aria-label="view"
              color="error"
              disabled={!selectionTab}
              onClick={handleOpenConfirmDelete}
            >
              <Tooltip title="Eliminar" placement="top">
                <DeleteSharpIcon />
              </Tooltip>
            </IconButton>
         </>
        )}
      </Stack>
    </div>
  );

  //menubar
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true }}
        />
        <MenuToolBar />
      </GridToolbarContainer>
    );
  };

  const filteredCols = cols.filter(col => {
    // Si rolesAppValue es 2, incluye la columna de Total de Costo
    if (rolesAppValue === 2) {
      return col.field!== "totalCosts";
    }
    // Para otros valores de rolesAppValue, excluye la columna de Total de Costo
    return true;
  });


  return (
    <React.Fragment>
      {loading ? (
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"400px"}
          animation="wave"
        />
      ) : (
        <React.Fragment>
          {/* <Box sx={{ height: "60vh", width: "100%" }}> */}
          <div className="card">
            <DataTable
              // stripedRows
              // showGridlines
              ref={dt}
              header={header}
              value={items}
              scrollable
              scrollHeight="500px"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25, 50]}
              removableSort
              dataKey="id"
              selectionMode="single"
              selection={selectionTab}
              onSelectionChange={(e) => setSelectionTab(e.value)}
              tableStyle={
                {
                  // minWidth: "50rem",
                  // color: "red",
                }
              }
              filters={filters}
              filterDisplay="row"
              size="normal"
              metaKeySelection={metaKey}
              columns={filteredCols}
            >
              {/* <Column
                  field="name"
                  header="Nombre"
                  sortable
                  filter
                  filterPlaceholder="Search by Nombre"
                ></Column>
                <Column
                  field="description"
                  header="Descripción"
                  sortable
                  filter
                  filterPlaceholder="Search by Descripción"
                ></Column> */}
              {filteredCols.map((col, index) => (
                <Column
                  sortable
                  filter
                  filterPlaceholder="Search"
                  key={index}
                  field={col.field}
                  header={col.header}
                  style={{ height: "50px", minWidth: "200px" }}
                />
              ))}
            </DataTable>
          </div>
          {/* </Box> */}
          {/* {console.log(items.find((elem) => elem.id === selectionTab[0]))} */}
          <FormCreateModule
            open={openFormData}
            onClose={handleCloseFormData}
            create={formCreate}
            editData={
              !formCreate && selectionTab
              // ? items.find((elem) => elem.id === selectionTab[0])
              // : null
            }
          />
          <SaleM
            open={saleModule}
            onClose={handleCloseSaleModule}
            viewData={
              selectionTab
              // ? items.find((elem) => elem.id === selectionTab[0])
              // : null
            }
          />
          <RentM
            open={rentModule}
            onClose={handleCloseRentModule}
            viewData={
              selectionTab
              // ? items.find((elem) => elem.id === selectionTab[0])
              // : null
            }
          />
          {/* <ConverterModule
            open={module}
            onClose={handleCloseModule}
            create={true}
            // editData={
            //   selectionTab.length === 1
            //     ? items.find((elem) => elem.id === selectionTab[0])
            //     : null
            // }
          /> */}
          <FormEditCostModule
            open={addCost}
            viewData={
              selectionTab
              // ? items.find((elem) => elem.id === selectionTab[0])
              // : null
            }
            onClose={handleCloseAddCost}
          />
          <FormOwnersM
            open={ownersM}
            onClose={handleCloseFormOwnersM}
            editData={
              selectionTab
              // ? items.find((elem) => elem.id === selectionTab[0])
              // : null
            }
          />
          <ViewModule
            open={openViewData}
            onClose={handleCloseViewData}
            viewDataModule={
              selectionTab
              // ? items.find((elem) => elem.id === selectionTab[0])
              // : null
            }
          />
          <ConfirmDialog
            open={openConfirmDelete}
            onClose={handleCloseConfirmDelete}
            onConfirm={handleDeleteData}
            onError={() => {
              showNotificationApp(
                "Hubo un problema durante la eliminación del contenedor.",
                "error"
              );
            }}
            title="Confirmar eliminación"
            text="¿Está seguro que desea eliminar el contenedor seleccionado?"
            textConfirm="Eliminar"
            colorConfirm="error"
            textCancel="Cancelar"
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
