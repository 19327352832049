import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useContext } from "react";
import { serviContainersApiCXN } from "../../../auth/axios.Container";
import { AppContext } from "../../../Providers/AppProvider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const iniData = {
  container: "",
  profitAsoc: "",
  createAt: dayjs().add(1, "d"),
  state: "",
  lastUpdateStateAt: "",
  codeModule: "",
  propertyContainerAsocs: [],
  invoice: "",
  di: "",
  tacCode: "",
  factoryYear: "",
};
const iniDataValid = {
  container: { error: false, errorText: "" },
  profitAsoc: { error: false, errorText: "" },
  createAt: { error: false, errorText: "" },
  factoryYear: { error: false, errorText: "" },
  state: { error: false, errorText: "" },
  lastUpdateStateAt: { error: false, errorText: "" },
  codeModule: { error: false, errorText: "" },
};

export default function FormElements({ open, onClose, create, editData }) {
  const [dataSt, setDataSt] = React.useState(iniData);
  const [dataValid, setDataValid] = React.useState(iniDataValid);
  const [loading, setLoading] = React.useState(false);
  const [stateContainer, setStateContainer] = React.useState([]);
  const { showNotificationApp } = useContext(AppContext);

  const handleClose = () => {
    setDataSt(iniData);
    setDataValid(iniDataValid);
    setLoading(false);
    onClose();
  };

  const handleProfitAsoc = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        profitAsoc: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        profitAsoc: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      profitAsoc: event.target.value,
    });
  };

  const handleState = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        state: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        state: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      state: event.target.value,
    });
  };

  const handleCodeModule = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        codeModule: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        codeModule: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      codeModule: event.target.value,
    });
  };

  // const handleCreateAt = (event) => {
  //   if (event.target.value === "") {
  //     setDataValid({
  //       ...dataValid,
  //       createAt: { error: true, errorText: "Este campo es obligatorio." },
  //     });
  //   } else {
  //     setDataValid({
  //       ...dataValid,
  //       createAt: { error: false, errorText: "" },
  //     });
  //   }
  //   setDataSt({
  //     ...dataSt,
  //     createAt: event.target.value,
  //   });
  // };
  const handleSubmit = () => {
    let valid = { ...dataValid };
    let fail = false;
    setLoading(true);

    if (dataValid.profitAsoc.error) fail = true;
    if (dataValid.state.error) fail = true;
    if (dataValid.codeModule.error) fail = true;
    if (dataValid.createAt.error) fail = true;

    if (dataSt.profitAsoc === "") {
      fail = true;
      valid = {
        ...valid,
        profitAsoc: { error: true, errorText: "Este campo es obligatorio." },
      };
    }
    if (dataSt.createAt === "") {
      fail = true;
      valid = {
        ...valid,
        createAt: { error: true, errorText: "Este campo es obligatorio." },
      };
    }
    if (dataSt.state === "") {
      fail = true;
      valid = {
        ...valid,
        state: { error: true, errorText: "Este campo es obligatorio." },
      };
    }

    if (dataSt.codeModule === "") {
      fail = true;
      valid = {
        ...valid,
        codeModule: { error: true, errorText: "Este campo es obligatorio." },
      };
    }

    if (!fail) {
      if (create) {
        let newData = new Object();
        newData.container = `/api/containers/${editData.id}`;
        newData.profitAsoc = parseFloat(dataSt.profitAsoc) / 100;
        newData.state = dataSt.state;
        newData.codeModule = dataSt.codeModule;
        newData.createAt = dataSt.createAt;
        serviContainersApiCXN
          .post(`${process.env.REACT_APP_HOST_API}/modules`, newData)
          .then((response) => {
            showNotificationApp("Se ha creado correctamente", "success");
            onClose();
          })
          .catch((error) => {
            showNotificationApp(
              "Ha ocurrido un error mientras se guardan los datos",
              "error"
            );
            console.log(error);
            setDataSt(iniData);
            setDataValid(iniDataValid);
          })
          .finally(() => setLoading(false));
      } else {
        let updateData = new Object();
        updateData.profitAsoc = parseFloat(dataSt.profitAsoc) / 100;
        updateData.state = dataSt.state;
        updateData.lastUpdateStateAt = dataSt.lastUpdateStateAt;
        updateData.createAt = dataSt.createAt;
        serviContainersApiCXN
          .patch(
            `${process.env.REACT_APP_HOST_API}/modules/${editData.id}`,
            updateData,
            {
              headers: {
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then((response) => {
            showNotificationApp("Se ha actualizado correctamente", "success");
            setLoading(false);
            onClose();
          })
          .catch((error) => {
            showNotificationApp(
              "Ha ocurrido un error mientras se guardan los datos",
              "error"
            );
            console.log(error);
            onClose();
          });
      }
    } else {
      setDataValid({ ...valid });
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }
    if (create) {
      serviContainersApiCXN
        .get(
          `${process.env.REACT_APP_HOST_API}/state_containers?pagination=false`
        )
        .then((state) => {
          setStateContainer(state.data["hydra:member"]);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoading(false);
        });
    } else {
      serviContainersApiCXN
        .get(
          `${process.env.REACT_APP_HOST_API}/state_containers?pagination=false`
        )
        .then((values) => {
          console.log(values.data["hydra:member"]);
          setStateContainer(values.data["hydra:member"]);
          setDataSt({
            profitAsoc: parseFloat(editData.profitAsoc),
            state: editData.state,
            codeModule: editData.codeModule,
            createAt: editData.createAt,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        scroll="paper"
        color="primary"
      >
        <DialogTitle className="dialog-title">
          {`${create ? `Convertir a Módulo` : `Editar Módulo`} `}
          <Tooltip title="Cerrar" placement="top">
            <IconButton
              aria-label="cerrar-form-data-client-sale"
              disabled={loading}
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers sx={{ maxHeight: "305h", minHeight: "35vh" }}>
          <Stack
            direction="column"
            spacing={2}
            alignItems="initial"
            alignContent="center"
            sx={{ mb: "1vh" }}
          >
            <TextField
              variant="standard"
              label={`Ganancia Asociada`}
              fullWidth={true}
              required
              type="number"
              disabled={loading}
              value={dataSt.profitAsoc}
              onChange={handleProfitAsoc}
              autoComplete="false"
              error={dataValid.profitAsoc.error}
              helperText={dataValid.profitAsoc.errorText}
            />
            <FormControl variant="standard" fullWidth={true} disabled={loading}>
              <InputLabel id="country">Estado Módulo</InputLabel>
              <Select
                labelId="state-ct-select-label-equipo"
                id="state-ct-select-equipo"
                value={dataSt.state}
                disabled={loading}
                onChange={handleState}
              >
                {stateContainer.map((ele) => {
                  console.log(ele);
                  return (
                    <MenuItem key={ele.id} value={ele["@id"]}>
                      {ele.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              variant="standard"
              fullWidth={true}
              label={`Modulo`}
              required
              type="text"
              disabled={loading || !create}
              value={dataSt.codeModule}
              onChange={handleCodeModule}
              autoComplete="false"
              error={dataValid.codeModule.error}
              helperText={dataValid.codeModule.errorText}
            />
          </Stack>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DatePicker
              label="Fecha de internación"
              value={dayjs(dataSt.createAt)}
              maxDate={dayjs("9999")}
              fullWidth={true}
              onChange={(newValue) =>
                setDataSt({ ...dataSt, createAt: newValue })
              }
              autoComplete="false"
              slotProps={{
                textField: {
                  variant: "standard",
                  fullWidth: true,
                },
              }}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ borderRadius: 0 }}
            color="inherit"
            disabled={loading}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{
              borderRadius: 0,
              background: "#212544",
            }}
            loading={loading}
            onClick={handleSubmit}
          >
            Aceptar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
