import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../../Providers/AppProvider";
import { useContext } from "react";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { serviContainersApiCXN } from "../../../auth/axios.Container";

const iniData = {
  id: "",
  username: "",
  email: "",
  active: true,
  plainPassword: "",
  area_countries: "",
  // permission: 0,
  rolesApp: 0,
};

const iniDataValid = {
  username: { error: false, errorText: "" },
  email: { error: false, errorText: "" },
};

export default function FormElements({ open, onClose, create, editData }) {
  const [dataSt, setDataSt] = React.useState(iniData);
  const [dataValid, setDataValid] = React.useState(iniDataValid);
  const [loading, setLoading] = React.useState(false);
  const [Area_countries, setArea_countries] = React.useState([]);

  const { showNotificationApp } = useContext(AppContext);

  const handleClose = () => {
    setDataSt(iniData);
    setDataValid(iniDataValid);
    setLoading(false);
    onClose();
  };

  const handleUsername = (event) => {
    const username = event.target.value;
    const usernameRegex = /^[A-Za-z0-9]+$/;

    if (username === "") {
      setDataValid({
        ...dataValid,
        username: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else if (!usernameRegex.test(username)) {
      setDataValid({
        ...dataValid,
        username: {
          error: true,
          errorText:
            "El nombre de usuario no debe contener espacios ni caracteres especiales.",
        },
      });
    } else {
      setDataValid({
        ...dataValid,
        username: { error: false, errorText: "" },
      });
    }

    setDataSt({
      ...dataSt,
      username: username,
    });
  };

  const handleEmail = (event) => {
    if (event.target.value === "") {
      setDataValid({
        ...dataValid,
        email: { error: true, errorText: "Este campo es obligatorio." },
      });
    } else {
      setDataValid({
        ...dataValid,
        email: { error: false, errorText: "" },
      });
    }
    setDataSt({
      ...dataSt,
      email: event.target.value,
    });
  };
  const handlePlainPassword = (event) => {
    setDataSt({
      ...dataSt,
      plainPassword: event.target.value,
    });
  };
  const handleActive = (event) => {
    setDataSt({
      ...dataSt,
      active: event.target.checked,
    });
  };

  const handleAreaCountries = (e) => {
    setDataSt({
      ...dataSt,
      area_countries: e.target.value,
    });
  };

  const handlePermission = (e) => {
    setDataSt({
      ...dataSt,
      permission: e.target.value,
    });
  };

  const handleRoles = (e) => {
    setDataSt({
      ...dataSt,
      rolesApp: e.target.value,
    });
  };

  const handleSubmit = () => {
    let valid = { ...dataValid };
    let fail = false;
    setLoading(true);

    if (dataValid.username.error) fail = true;
    if (dataValid.email.error) fail = true;

    if (dataSt.username === "") {
      fail = true;
      valid = {
        ...valid,
        username: { error: true, errorText: "Este campo es obligatorio." },
      };
    } else if (dataSt.email === "") {
      fail = true;
      valid = {
        ...valid,
        email: { error: true, errorText: "Este campo es obligatorio." },
      };
    }

    if (!fail) {
      if (create) {
        let newData = new Object();
        newData.username = dataSt.username;
        newData.email = dataSt.email;
        newData.active = dataSt.active;
        newData.plainPassword = dataSt.plainPassword;
        newData.areaCountry = dataSt.area_countries;
        // newData.permission = dataSt.permission;
        newData.rolesApp = dataSt.rolesApp;
        serviContainersApiCXN
          .post(`${process.env.REACT_APP_HOST_API}/users`, newData)
          .then((response) => {
            showNotificationApp("Se ha creado correctamente", "success");
            onClose();
          })
          .catch((error) => {
            showNotificationApp(
              "Ha ocurrido un error mientras se guardan los datos",
              "error"
            );
            console.log(error);
            setDataSt(iniData);
            setDataValid(iniDataValid);
          })
          .finally(() => setLoading(false));
      } else {
        let updateData = new Object();
        updateData.email = dataSt.email;
        updateData.active = dataSt.active;
        updateData.areaCountry = dataSt.area_countries;
        // updateData.permission = dataSt.permission;
        updateData.rolesApp = dataSt.rolesApp;
        if (dataSt.plainPassword !== "") {
          updateData.plainPassword = dataSt.plainPassword;
        }

        serviContainersApiCXN
          .patch(
            `${process.env.REACT_APP_HOST_API}/users/${editData.id}`,
            updateData
          )
          .then((response) => {
            showNotificationApp("Se ha actualizado correctamente", "success");
            onClose();
          })
          .catch((error) => {
            showNotificationApp(
              "Ha ocurrido un error mientras se guardan los datos",
              "error"
            );
            console.log(error);
            onClose();
          });
      }
    } else {
      setDataValid({ ...valid });
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }
    if (create) {
      setLoading(true);
      serviContainersApiCXN
        .get(
          `${process.env.REACT_APP_HOST_API}/area_countries?pagination=false`
        )
        .then((response) => {
          console.log(response.data["hydra:member"]);
          setArea_countries(response.data["hydra:member"]);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
          setLoading(false);
        });
    } else {
      setLoading(true);
      serviContainersApiCXN
        .get(
          `${process.env.REACT_APP_HOST_API}/area_countries?pagination=false`
        )
        .then((response) => {
          console.log(response.data["hydra:member"]);
          setArea_countries(response.data["hydra:member"]);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
          setLoading(false);
          console.log(editData);
          const getPermissionTypeNumber = (permission) => {
            switch (permission) {
              case "escritura":
                return 0;
              case "lectura":
                return 1;
              // case "vendedor":
              //   return 2;
              default:
                return "";
            }
          };
          const getPermissionTypeNumberRol = (rolesApp) => {
            switch (rolesApp) {
              case "Administrador":
                return 0;
              case "Operario":
                return 1;
              case "Vendedor":
                return 2;
              default:
                return "";
            }
          };
          setDataSt({
            id: editData.id,
            username: editData.username,
            email: editData.email,
            active: editData.active === "Activo" ? true : false,
            plainPassword: "",
            // permission: editData.permission,
            permission:
              editData.permission === ""
                ? ""
                : getPermissionTypeNumber(editData.permission),
            rolesApp:
              editData.roles === ""
                ? ""
                : getPermissionTypeNumberRol(editData.rolesApp),
            area_countries:
              editData.area_contries === "" ? "" : editData.area_contries,
          });
          setLoading(false);
        });
    }
  }, [open]);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        scroll="paper"
        color="primary"
      >
        <DialogTitle className="dialog-title">
          {`${create ? `Agregar usuario` : `Editar usuario`} `}
          <Tooltip title="Cerrar" placement="top">
            <IconButton
              aria-label="cerrar-form-data-client-sale"
              disabled={loading}
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers sx={{ maxHeight: "305h", minHeight: "35vh" }}>
          <Stack spacing={2}>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "10rem",
                  background: "#f2f2f2",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Stack
                  direction="column"
                  spacing={2}
                  alignItems="initial"
                  alignContent="center"
                  sx={{ mb: "1vh" }}
                >
                  <FormControl
                    variant="standard"
                    sx={{ minWidth: "100px", width: "400px" }}
                    disabled={loading}
                  >
                    <InputLabel id="tipo de impresora/toner">País</InputLabel>
                    <Select
                      labelId="state-ct-select-label-equipo"
                      id="state-ct-select-equipo"
                      value={dataSt.area_countries}
                      onChange={handleAreaCountries}
                      label="toner"
                    >
                      {Area_countries.map((ele) => {
                        return (
                          <MenuItem key={ele.id} value={ele["@id"]}>
                            {ele.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    variant="standard"
                    label="Email"
                    required
                    type="text"
                    disabled={loading}
                    value={dataSt.email}
                    onChange={handleEmail}
                    autoComplete="false"
                    error={dataValid.email.error}
                    helperText={dataValid.email.errorText}
                    sx={{ minWidth: "120px", width: "400px" }}
                  />
                  <TextField
                    variant="standard"
                    label={`Nombre`}
                    required
                    type="text"
                    disabled={loading || !create}
                    value={dataSt.username}
                    onChange={handleUsername}
                    autoComplete="false"
                    error={dataValid.username.error}
                    helperText={dataValid.username.errorText}
                    sx={{ minWidth: "120px", width: "400px" }}
                  />
                  <TextField
                    variant="standard"
                    label={`Contraseña`}
                    required
                    type="text"
                    disabled={loading}
                    value={dataSt.plainPassword}
                    onChange={handlePlainPassword}
                    autoComplete="false"
                    sx={{ minWidth: "120px", width: "400px" }}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={dataSt.active}
                        onChange={handleActive}
                        inputProps={{ "aria-label": "controlled" }}
                        label="Label"
                      />
                    }
                    label="Estado"
                  />
                  {/* <FormControl
                    variant="standard"
                    sx={{ minWidth: "100px", width: "400px" }}
                    disabled={loading}
                  >
                    <InputLabel id="tipo de impresora/toner">
                      Permisos
                    </InputLabel>
                    <Select
                      labelId="state-ct-select-label-equipo"
                      id="state-ct-select-equipo"
                      value={dataSt.permission}
                      onChange={handlePermission}
                      label="toner"
                    >
                      <MenuItem value={0}>Escritura</MenuItem>
                      <MenuItem value={1}>Lectura</MenuItem>
                    </Select>
                  </FormControl> */}
                  <FormControl
                    variant="standard"
                    sx={{ minWidth: "100px", width: "400px" }}
                    disabled={loading}
                  >
                    <InputLabel id="tipo de impresora/toner">
                     Roles
                    </InputLabel>
                    <Select
                      labelId="state-ct-select-label-equipo"
                      id="state-ct-select-equipo"
                      value={dataSt.rolesApp}
                      onChange={handleRoles}
                      label="toner"
                    >
                      <MenuItem value={0}>Administrador</MenuItem>
                      <MenuItem value={1}>Operario</MenuItem>
                      <MenuItem value={2}>Vendedor</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ borderRadius: 0 }}
            color="inherit"
            disabled={loading}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{
              borderRadius: 0,
              background: "#212544",
            }}
            loading={loading}
            onClick={handleSubmit}
          >
            Aceptar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
