import * as React from "react";
import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  Box,
  Skeleton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../../Providers/AppProvider";
import { useContext } from "react";
import { serviContainersApiCXN } from "../../../auth/axios.Container";

const iniData = {
  pdf: "",
  id: "",
};

export default function ViewContainer({ open, onClose, viewDataSaleModule }) {
  console.log(viewDataSaleModule);
  const [loading, setLoading] = React.useState(false);
  const { showNotificationApp } = useContext(AppContext);
  const [dataSt, setDataSt] = React.useState(iniData);

  const handleClose = () => {
    onClose();
  };

  React.useEffect(() => {
    if (!open) return undefined;
    setLoading(true);

    if (viewDataSaleModule && viewDataSaleModule.id) {
      serviContainersApiCXN
        .get(
          `${process.env.REACT_APP_HOST_API}/sale_containers/${viewDataSaleModule.id}/pdf_bill`
        )
        .then((response) => {
          console.log(response.data);
          setDataSt({
            pdf: response.data.pdf,
            id: viewDataSaleModule.id,
          });
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          showNotificationApp("Error cargando PDF del sistema ", "error");
        });
    } else {
      setLoading(false);
      showNotificationApp("Error cargando PDF del sistema ", "error");
    }
  }, [open]);
  if (!open) {
    return null;
  }

  return (
    <React.Fragment>
      <Dialog open={open} fullWidth scroll="paper" color="primary">
        <DialogTitle className="dialog-title">
          {`Vista de PDF de Factura`}
          <Tooltip title="Cerrar" placement="top">
            <IconButton
              aria-label="cerrar-form-data-client-sale"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers>
          {loading ? (
            <Skeleton variant="rectangular" height={100} />
          ) : (
            <Stack
              direction="column"
              spacing={2}
              alignItems="initial"
              sx={{ m: 3 }}
            >
              <embed
                src={dataSt.pdf}
                type="application/pdf"
                width="100%"
                height="600px"
              />
            </Stack>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
