import * as React from "react";
import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  Box,
  Skeleton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../../Providers/AppProvider";
import { useContext } from "react";
import { serviContainersApiCXN } from "../../../auth/axios.Container";

const iniData = {
  image: "",
  id: "",
};

export default function ViewContainer({ open, onClose, viewDataRentModule }) {
  const [loading, setLoading] = React.useState(true);
  const { showNotificationApp } = useContext(AppContext);
  const [dataSt, setDataSt] = React.useState(iniData);

  const handleClose = () => {
    onClose();
  };

  React.useEffect(() => {
    if (!open) return undefined;
    setLoading(true);
    serviContainersApiCXN
      .get(
        `${process.env.REACT_APP_HOST_API}/rent_containers/${viewDataRentModule.id}/photo/${viewDataRentModule.photo}`
      )
      .then((response) => {
        console.log(response);
        setDataSt({
          ...dataSt,
          image: response.data.img,
          id: viewDataRentModule.id,
        });
        setLoading(false);
      })
      .catch(function (error) {
        showNotificationApp("Error cargando imagen del sistema", "error");
        setLoading(false);
      });
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <React.Fragment>
      <Dialog open={open} fullWidth scroll="paper" color="primary">
        <DialogTitle className="dialog-title">
          {`Vista de Foto`}
          <Tooltip title="Cerrar" placement="top">
            <IconButton
              aria-label="cerrar-form-data-client-sale"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers>
          {loading ? (
            <Skeleton variant="rectangular" height={100} />
          ) : (
            <Stack
              direction="column"
              spacing={2}
              alignItems="initial"
              sx={{ m: 3 }}
            >
              <img src={dataSt.image} alt="Imagen" />
            </Stack>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
