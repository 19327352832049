import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  Skeleton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AppContext } from "../../../Providers/AppProvider";
import { serviContainersApiCXN } from "../../../auth/axios.Container";

function adapterListCost(listTypeCost, listCostAsoc) {
  let data = [];
  listTypeCost.forEach((typeCost) => {
    let valueCost = typeCost.defaultValue;
    if (
      listCostAsoc.some((ele) => {
        if (ele.typeCost.id === typeCost.id) {
          valueCost = ele.valueCost;
          return true;
        } else return false;
      })
    ) {
      data.push({
        disableInput: false,
        checked: true,
        labelCost: typeCost.name,
        valueCost: valueCost,
        defaultValueCost: valueCost,
        validValueCost: { error: false, text: "" },
        id: typeCost.id,
      });
    } else {
      data.push({
        disableInput: true,
        checked: false,
        labelCost: typeCost.name,
        valueCost: valueCost,
        defaultValueCost: valueCost,
        validValueCost: { error: false, text: "" },
        id: typeCost.id,
      });
    }
  });

  return data;
}

export default function FormEditCostContainer({ open, onClose, viewData }) {
  const [loading, setLoading] = React.useState(false);
  const [listCosts, setListCosts] = React.useState();
  const { showNotificationApp } = React.useContext(AppContext);

  const handleClose = () => {
    setLoading(false);
    onClose();
  };
  const handleCheckboxChange = (e, index) => {
    console.log([...listCosts]);
    let newList = [...listCosts];
    newList[index] = {
      ...newList[index],
      checked: e.target.checked,
      disableInput: !e.target.checked,
      valueCost: newList[index].defaultValueCost,
      validValueCost: { error: false, text: "" },
    };
    setListCosts([...newList]);
  };

  const handleValueChange = (e, index) => {
    let newList = [...listCosts];
    let validValueCost = { error: false, text: "" };
    if (e.target.value === "") {
      validValueCost = { error: true, text: "Dato obligatorio." };
    }
    newList[index] = {
      ...newList[index],
      valueCost: e.target.value,
      validValueCost: validValueCost,
    };
    setListCosts([...newList]);
  };

  const handleSubmit = () => {
    setLoading(true);
    if (!listCosts.some((ele) => ele.validValueCost.error)) {
      let listCostUpdate = [];
      listCosts.forEach((el) => {
        if (el.checked)
          listCostUpdate.push({
            idTypeCost: `${el.id}`,
            valueCostAsoc: parseFloat(el.valueCost),
          });
      });
      serviContainersApiCXN
        .post(
          `${process.env.REACT_APP_HOST_API}/containers/${viewData.id}/update/costs`,
          {
            updateListCostAsoc: listCostUpdate,
          }
        )
        .then((response) => {
          showNotificationApp("Se ha actualizado correctamente.", "success");
          setListCosts();
          onClose();
        })
        .catch((error) => {
          console.log(error);
          showNotificationApp("Ha ocurrido un problema.", "error");
          onClose();
        })
        .finally(() => setLoading(false));
    }
  };

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }
    async function fetchData() {
      await serviContainersApiCXN
        .get(`${process.env.REACT_APP_HOST_API}/type_costs?pagination=false`)
        .then((response) => {
          console.log(response);
          setListCosts(
            adapterListCost(
              response.data["hydra:member"],
              viewData.typeCostAsocContainers
            )
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchData();
  }, [open]);

  if (!open) {
    return undefined;
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        scroll="paper"
        color="primary"
      >
        <DialogTitle className="dialog-title">
          Agregar Gastos
          <Tooltip title="Cerrar" placement="top">
            <IconButton
              aria-label="cerrar-form-data-client-sale"
              disabled={loading}
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent dividers sx={{ maxHeight: "60vh", overFlow: "scroll" }}>
          {!listCosts ? (
            <Skeleton variant="rectangular" height={35} />
          ) : (
            <Stack
              paddingBottom={"2rem"}
              paddingTop="16px"
              overflow={"auto"}
              spacing={2}
            >
              {listCosts.map((element, index) => (
                <Stack
                  direction="row"
                  sx={{
                    px: "30px",
                  }}
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  key={element.id}
                >
                  <FormControlLabel
                    onChange={(e) => handleCheckboxChange(e, index)}
                    control={<Checkbox checked={element.checked} />}
                    label={element.labelCost}
                    sx={{
                      felxGrow: "1",
                      minWidth: "150px",
                      marginRight: "1.5rem",
                    }}
                  />
                  <TextField
                    label="Gasto"
                    variant="outlined"
                    value={element.valueCost}
                    onChange={(e) => handleValueChange(e, index)}
                    disabled={element.disableInput}
                    type="number"
                    error={element.validValueCost.error}
                    helperText={element.validValueCost.text}
                  />
                </Stack>
              ))}
            </Stack>
          )}
        </DialogContent>
        {listCosts && (
          <DialogActions>
            <Button
              variant="contained"
              sx={{ borderRadius: 0 }}
              color="inherit"
              disabled={loading}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{
                borderRadius: 0,
                background: "#212544",
              }}
              loading={loading}
              onClick={handleSubmit}
            >
              Aceptar
            </LoadingButton>
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
}
