import React from "react";
import ChartTypeContainers from "./Container/chartTypeContainers";
import ChartTypeModule from "./Module/chartType";
import ChartContainersContry from "./Container/chartContainersContry";
import ChartModuleContry from "./Module/chartContry";
import ChartPlaceContainer from "./Container/chartPlaceContainer";
import ChartModulePlace from "./Module/chartPlace";
import ChartAviableContainer from "./Container/chartAvailableContainer";
import ChartAviableModule from "./Module/chartAvailable";
import ChartProvider from "./Container/chartProvider";
import ChartOwner from "./Container/chartOwner";
import ChartOwnerModule from "./Module/chartOwner";
import ChartFactory from "./Container/chartFactory";
import ChartFactoryModule from "./Module/chartFactory";
import ChartRent from "./Container/chartRent";
import ChartRentModule from "./Module/chartRent";
import { Card } from 'primereact/card';
import "./HomePicture.css";
import { Divider } from 'primereact/divider';

function HomePicture() {
  return (
    <div className="home">
      <div
        className="homepicture"
        style={{fontSize: "2rem", color: "#004590", marginLeft:"40px" }}
      >
        <h2>Estadistícas Servicontainers por contenedores</h2>
      </div>
      <div className="chart-container">
        <div className="chart-row">
          <Card className="chart-card-small">
            <h2>Tipo:</h2>
            <Divider />
            <ChartTypeContainers/>
          </Card>
      
          <Card className="chart-card-small">
            <h2>Estado:</h2>
            <Divider />
            <ChartAviableContainer/>
          </Card>
        </div>
        
        <div className="chart-row">
          <Card className="chart-card-small">
            <h2>Países:</h2>
            <Divider />
            <ChartContainersContry/>
          </Card>
      
          <Card className="chart-card-small">
            <h2>Lugares:</h2>
            <Divider />
            <ChartPlaceContainer/>
          </Card>
        </div>
        
        <div className="chart-row">
          <Card className="chart-card-small">
            <h2>Proveedores:</h2>
            <Divider />
            <ChartProvider/>
          </Card>
      
          <Card className="chart-card-small">
            <h2>Propietarios:</h2>
            <Divider />
            <ChartOwner/>
          </Card>
        </div>
        
        <div className="chart-row">
          <Card className="chart-card-small">
            <h2>Año de fabricación:</h2>
            <Divider />
            <ChartFactory/>
          </Card>
          <Card className="chart-card-small">
            <h2>Arrendados:</h2>
            <Divider />
            <ChartRent/>
          </Card>
        </div>
      
      </div>
      
      <div
        className="homepicture"
        style={{fontSize: "2rem", color: "#004590", marginLeft:"40px" }}
      >
        <h2>Estadistícas Servicontainers por módulos</h2>
      </div>
      <div className="chart-container">
        <div className="chart-row">
          <Card className="chart-card-small">
            <h2>Tipo:</h2>
            <Divider />
            <ChartTypeModule/>
          </Card>
      
          <Card className="chart-card-small">
            <h2>Estado:</h2>
            <Divider />
            <ChartAviableModule/>
          </Card>
        </div>
        
        <div className="chart-row">
          <Card className="chart-card-small">
            <h2>Países:</h2>
            <Divider />
            <ChartModuleContry/>
          </Card>
      
          <Card className="chart-card-small">
            <h2>Lugares:</h2>
            <Divider />
            <ChartModulePlace/>
          </Card>
        </div>
        
        <div className="chart-row">
          <Card className="chart-card-small">
          <h2>Propietarios:</h2>
            <Divider />
            <ChartOwnerModule/>
          </Card>
      
          <Card className="chart-card-small">
          <h2>Año de fabricación:</h2>
            <Divider />
            <ChartFactoryModule/>
          </Card>
        </div>
        
        <div className="chart-row">
          <Card className="chart-card-small">
          <h2>Arrendados:</h2>
            <Divider />
            <ChartRentModule/>
          </Card>
        </div>
      
      </div>
    </div>
  );
}

export default HomePicture;
