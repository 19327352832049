import * as React from "react";
import { Box, Typography, Backdrop, Tab, Paper, Stack } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CircularProgress from "@mui/material/CircularProgress";
import Module from "./Module/Module";
import RentModule from "./RentModule/RentModule";
import SaleModule from "./SaleModule/SaleModule";
import { getUserRoleData } from "./../../utils/Roles";

export default function BasicTabs() {
  const [valueTab, setValueTab] = React.useState("tab1");
  const [backdropComp, setBackdropComp] = React.useState({
    loading: false,
    textInfo: "",
  });
  const [rolesApp, setRolesApp] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchRoles = async () => {
      try {
        const userData = await getUserRoleData();
        setRolesApp(userData.rolesApp);
      } catch (error) {
        console.error("Error fetching user roles", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRoles();
  }, []);


  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <React.Fragment>
      <TabContext value={valueTab}>
        <Box sx={{ width: "100%" }}>
          <TabList
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              value="tab1"
              label="MÓDULO"
              sx={{ marginLeft: 2, color: "primary" }}
            />
            
            <Tab
              value="tab2"
              label="VENTA DE MÓDULO"
              sx={{ marginLeft: 2, color: "primary" }}
              disabled={rolesApp == 2}
            />
            <Tab
              value="tab3"
              label="RENTA DE MÓDULO"
              sx={{ marginLeft: 2, color: "primary" }}
              disabled={rolesApp == 2}
            />
            
          </TabList>
        </Box>
        <Paper>
          <Box>
            <TabPanel value="tab1" sx={{ height: "auto" }}>
              <Module />
            </TabPanel>
            
            <TabPanel value="tab2" sx={{ height: "auto" }}>
              <SaleModule />
            </TabPanel>
            <TabPanel value="tab3" sx={{ height: "auto" }}>
              <RentModule />
            </TabPanel>
           
          </Box>
        </Paper>
      </TabContext>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropComp.loading}
      >
        <Stack alignItems="center">
          <CircularProgress color="primary" />
          <Typography sx={{ mt: 1 }} align="center" variant="subtitle">
            {backdropComp.textInfo}
          </Typography>
        </Stack>
      </Backdrop>
    </React.Fragment>
  );
}
